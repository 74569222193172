/* global HUI_API_ENV */
import React from 'react';
import BaseWrapper from '../BaseWrapper';
import Sidebar from './Sidebar';
import actions from '../../classes/Actions';
import NavbarStore from '../../stores/NavbarStore';
import SidebarStore from '../../stores/SidebarStore';
import ControllerView from '../ControllerView';
import App from 'js/lib/classes/App';

/**
 * Class representing a sidebar.
 */
export default class SidebarWrapper extends BaseWrapper {
	initialProductId;

	/**
	 * @constructor
	 * @param {Object} opts Options of the format:
	 *      {
	 *          container: an HtmlElement or unique CSS selector for the element's root
	 *          initialProductId: a string of the productId to start selected in the sidebar
	 *          selectedDeviceIds: an array of strings of deviceIds to start selected in the sidebar
	 *          selectedGroupIds: an array of strings of groupIds to start selected in the sidebar
	 *      }
	 */
	constructor(opts) {
		super(opts, Sidebar);

		this.container = opts.container;

		// save this for authcheckonce use
		this.initialProductId = opts.initialProductId;

		// Support backwards compatible opts naming
		opts.selectedProductId = opts.initialProductId;
		delete opts.initialProductId;

		// allow overrides for urls.ini, but only in dev/staging
		// if (HUI_API_ENV !== 'production' && (opts.urls || opts.urlsEnv)) {
		if (opts.urls || opts.urlsEnv) {
			actions.setUrlOverrides(opts.urls, opts.urlsEnv);
		}

		actions.initializeSidebar(opts);
	}

	/**
	 * Instantiates the React component.
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		const sidebarTransform = stores => {
			const data = stores.sidebarStore;

			if (stores.navbarStore.selectedTabData) {
				data.navbarSelectedTab = stores.navbarStore.selectedTabData;
			}

			return data;
		};

		App.checkAuthOnce(this.initialProductId);

		return (
			<ControllerView
				component={Sidebar}
				transform={sidebarTransform}
				stores={{ sidebarStore: SidebarStore, navbarStore: NavbarStore }}
			/>
		);
	}
}
