/* global HUI */

import { NotSignedInError } from 'js/lib/exceptions';
import Constants from 'js/lib/constants';

export const BCCAuthDataProvider = new HUI.Providers.DataProvider((tools, resolve, reject) => {
	/**
	 * Returns the service ID for a provided product ID
	 * @param   {string} productId  The string ID that represents and product.
	 * @returns {number} serviceId  The product's service ID.
	 */
	function productToServiceId(productId) {
		// List from BCC's cloud_services.json
		const productServiceMap = {
			bbs: 1,
			ess: 6,
			mas: 10,
			flex: 2,
			vlm: 15,
			mdm: 12,
			bac: 5,
			mps: 3,
			wfs: 4,
			cvs: 7,
			ngf: 8,
			cudadns: 9,
			copy: 13,
			account: 100,
			community: 101,
			support: 102,
			o365: 103,
		};

		return productServiceMap[productId];
	}

	// BCC doesn't always give us an initialProductId, but
	// it fails with an invalid serviceId, so we fall back to homeProductServiceId if unspecified
	const serviceId = productToServiceId(tools.productId) || Constants.Sidebar.homeProductServiceId;

	// the CLOUD_AT cookie is httpOnly, so we can't access it to pass in `auth_token`
	// the falsey value indicates that the SOAP API server should check the cookie itself

	return tools.ajax({
		url: `https://${tools.urls.bcc_soap}/api/soap`,
		headers: {
			'Content-Type': 'text/xml; charset=utf-8',
			'SOAPAction': 'isAuthenticated',
		},
		method: 'POST',
		body: `
			<?xml version="1.0" encoding="utf-8"?>
			<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:BarracudaCloudAPI">
				<x:Header/>
				<x:Body>
					<urn:isAuthenticatedRequest>
						<urn:auth_token></urn:auth_token>
						<urn:service>${serviceId}</urn:service>
					</urn:isAuthenticatedRequest>
				</x:Body>
			</x:Envelope>
		`.trim(),
	}).then(([code, response]) => {
		if (code !== 200) {
			reject(new Error('Received error during ajax request'));
			return;
		}

		let result = tools.parseXMLViaDOMParser(response);

		try {
			result = result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ns1:isAuthenticatedResponse'];

			if (result && result.success && result.success !== 'false') {
				if (result.accounts) {
						// ensure this is an array
					result.accounts = result.accounts.length ? result.accounts : Array(result.accounts);

					if (serviceId === productToServiceId('bbs')) {
							// pretend this came from the API
						if (Number(tools.cookies.get('current_account_all')) === 1) {
							result.all_accounts_selected = true;
						}

							// show Manage All option if user has more than one account OR if it's selected already
						if (result.accounts.length > 1 || result.all_accounts_selected) {
							result.accounts.push({
								id: Constants.Header.manageAllAccountsAccountId,
								name: HUI.translateLid(HUI.Messages.ACCOUNT_DROPDOWN_MANAGE_ALL),
							});
						}
					}
				}
				resolve(result);
			} else {
				reject(new NotSignedInError('User is not authenticated.'));
			}
		} catch (e) {
			reject(e);
			return;
		}

	});
});
