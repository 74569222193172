import { Sidebar as Constants } from '../constants';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import merge from 'lodash/merge';
import getFirstDefined from '../utils/getFirstDefined';
import HuiPath from './HuiPath';

type GroupObject = {
	id: number | string,
	title: string,
	href: ?string,
};

/**
 * Returns a data structure set up for rendering
 * @param  {Object}    args                      An object of arguments
 * @param  {Object[]}  args.products             array of products objects
 * @param  {Object}    args.treeNodesByProvider  treeNodes arrays, partitioned by their dataprovider
 * @param  {string}    args.searchBoxValue       string of the search value
 * @param  {bool}      args.isSearching          boolean of whether searching is active
 * @param  {Object}    args.groupExpansionMap    An object indicating which groups have been
 *                                               manually expanded or contracted by the user
 * @param  {Object}  args.itemCallbacks          An object containing potential callbacks for node items
 * @return {Object[]}  A rendering data structure, top level is an array of product categories
 */
export default function buildProductsTree(args) {
	args.groupExpansionMap = args.groupExpansionMap || {};
	args.itemCallbacks = args.itemCallbacks || {};

	let productList = args.products.map(cloneDeep);
	const treeNodesByProvider = cloneDeep(args.treeNodesByProvider);

	attachDevicesAndGroupsToProductList({
		productList,
		treeNodesByProvider,
		searchBoxValue: args.searchBoxValue,
		isSearching: args.isSearching,
		itemCallbacks: args.itemCallbacks,
	});

	if (args.isSearching) {
		productList = filterOutEmptyProductsWhileSearching(productList);
		setGroupExpansion(productList, true);
	} else {
		setGroupExpansion(productList, args.groupExpansionMap);
	}

	const categoriesList = getProductCategoryTreeFromProductList(productList);

	return categoriesList;
}

/**
 * Attaches references to treeNodes to this.props.products.
 * @param  {Object}    args                      An object of arguments
 * @param  {Object[]}  args.productList          products to operate on
 * @param  {Object}    args.treeNodesByProvider  treeNodes arrays, partitioned by their dataprovider
 * @param  {string}    args.searchBoxValue       the search value
 * @param  {boolean}   args.isSearching          whether searching is active
 * @param  {Object}    args.itemCallbacks        potential callbacks for node items
 */
function attachDevicesAndGroupsToProductList(args) {
	args.itemCallbacks = args.itemCallbacks || {};
	const productsMap = {};

	args.productList.forEach((product) => {
		product.childItems = product.childItems || [];
		product.count = 0;
		productsMap[product.id] = product;
	});

	const treeNodeList = flatten(Object.values(args.treeNodesByProvider));

	treeNodeList.forEach((treeNode) => {
		// eslint-disable-line complexity
		treeNode.itemType = treeNode.itemType || Constants.productChildTypes.DEVICE;
		treeNode.groups = treeNode.groups || [];
		treeNode.tags = treeNode.tags || [];

		if (treeNode.href && args.itemCallbacks.sidebarItemHrefCallback) {
			try {
				treeNode.href = args.itemCallbacks.sidebarItemHrefCallback(treeNode.href, treeNode.path) || treeNode.href;
			} catch (e) {
				// Don't modify the treeNode's href
			}
		}

		if (args.isSearching) {
			if (hasTagMatch(treeNode, args.searchBoxValue)) {
				addTagNodes(treeNode, productsMap, args.searchBoxValue);
			}
			if (hasNameMatch(treeNode, args.searchBoxValue) || hasGroupMatch(treeNode, args.searchBoxValue)) {
				addNormalNode(treeNode, productsMap);
			}
		} else {
			addNormalNode(treeNode, productsMap);
		}
	}, this);

	sortTagGroupsToEnd(productsMap);
}

/**
 * Adds a node to the tree (via productsMap)
 * @param {object} treeNode     The treeNode to add
 * @param {object} productsMap  The productMap object to use to add the treeNode
 */
function addNormalNode(treeNode, productsMap) {
	if (treeNode.groups.length) {
		treeNode.groups.forEach((group) => {
			const childDevice = merge({}, treeNode);
			// skip if products aren't present yet (for async return)
			if (productsMap[childDevice.productId]) {
				const productGroup = getOrCreateGroupOnProduct(group, productsMap[childDevice.productId]);
				childDevice.path = productGroup.path.makeChildPath(childDevice.id);
				productGroup.childItems.push(childDevice);

				// skip incrementing container count if item is not a device
				if (treeNode.itemType === Constants.productChildTypes.DEVICE) {
					productGroup.count++;
					productsMap[treeNode.productId].count++;
				}
			}
		});
	} else {
		treeNode.path = new HuiPath([treeNode.productId, treeNode.id]);

		// skip if products aren't present yet (for async return)
		if (productsMap[treeNode.productId]) {
			productsMap[treeNode.productId].childItems.push(treeNode);

			// skip incrementing container count if item is not a device
			if (treeNode.itemType === Constants.productChildTypes.DEVICE) {
				productsMap[treeNode.productId].count++;
			}
		}
	}
}

/**
 * Adds one ore more nodes to the tree (via productsMap) for a tag-based search match
 * @param {object} treeNode        The treeNode to add
 * @param {object} productsMap     The productMap object to use to add the treeNode
 * @param {string} searchBoxValue  The user's current search
 */
function addTagNodes(treeNode, productsMap, searchBoxValue) {
	const matchingTags = treeNode.tags.filter((tag) => {
		return tag.title.toLowerCase().indexOf(searchBoxValue.toLowerCase()) !== -1;
	});

	matchingTags.forEach((tag) => {
		const childDevice = merge({}, treeNode);
		const tagGroup = getOrCreateTagGroup(tag, productsMap[childDevice.productId]);

		childDevice.path = tagGroup.path.makeChildPath(childDevice.id);
		tagGroup.childItems.push(childDevice);

		if (treeNode.itemType === Constants.productChildTypes.DEVICE) {
			tagGroup.count++;
			productsMap[treeNode.productId].count++;
		}
	});
}

/**
 * Checks the device's name to see if it matches the search query.
 * @param  {Object} device          The device's details.
 * @param  {string} searchBoxValue  The search value
 * @return {bool}                   Whether the device's name matches the search criteria.
 */
function hasNameMatch(device, searchBoxValue) {
	const devicePropertiesToFilterOn = ['id', 'title'];
	const nameMatches = devicePropertiesToFilterOn.some((property) => {
		// If the property exists on the device and the search term fits, return true.
		return device[property] && device[property].toLowerCase().indexOf(searchBoxValue.toLowerCase()) !== -1;
	});

	const isDevice = device.itemType === Constants.productChildTypes.DEVICE;

	return nameMatches && isDevice;
}

/**
 * Checks the device's groups to see if they match the search query.
 * @param  {Object} device          The device's details.
 * @param  {string} searchBoxValue  The search value
 * @return {bool}                   Whether the device's name matches the search criteria.
 */
function hasGroupMatch(device, searchBoxValue) {
	return device.groups.some((group) => {
		return group.title.toLowerCase().indexOf(searchBoxValue.toLowerCase()) !== -1;
	});
}

/**
 * Checks the device's tags to see if they match the search query.
 * @param  {Object} device          The device's details.
 * @param  {string} searchBoxValue  The search value
 * @return {bool}                   Whether the device's name matches the search criteria.
 */
function hasTagMatch(device, searchBoxValue) {
	return device.tags.some((tag) => {
		return tag.title.toLowerCase().indexOf(searchBoxValue.toLowerCase()) !== -1;
	});
}

/**
 * Removes products from the product list if we're searching.
 * @param {Object[]} productList  A list of hardware products that show up in the sidebar.
 * @return {Object[]}  An array of products after we've removed the empty ones for searching.
 */
function filterOutEmptyProductsWhileSearching(productList: Object[]) {
	return productList.filter((product) => {
		return product.childItems.length > 0;
	});
}

/**
 * Assigns isExpanded prop on groups based on product-related calculations
 * @param {object[]} productList           The productList data structure with
 *                                         products, groups, and devices
 * @param {object|boolean} groupExpansion  An object indicating which groups have been
 *                                         manually expanded or contracted by the user,
 *                                         or a boolean indicating to expand all or none
 */
function setGroupExpansion(productList: Object[], groupExpansion: Object | boolean = {}) {
	if (!productList) {
		return;
	}

	productList.forEach((product) => {
		let numChildren = 0;

		product.childItems.forEach((childItem) => {
			numChildren++;

			if (childItem.childItems) {
				childItem.childItems.forEach(() => {
					numChildren++;
				});
			}
		});

		const PRODUCT_CHILDREN_THRESHOLD = 10;
		const startGroupsCollapsed = numChildren > PRODUCT_CHILDREN_THRESHOLD;

		product.childItems.forEach((childItem) => {
			if (childItem.itemType === Constants.productChildTypes.GROUP) {
				let expandGroup = true;

				if (groupExpansion instanceof Object) {
					const groupIsExpanded = groupExpansion[childItem.path.toString()];
					expandGroup = getFirstDefined(groupIsExpanded, !startGroupsCollapsed);
				} else {
					expandGroup = !!groupExpansion;
				}

				childItem.isExpanded = expandGroup;
			}
		});
	});
}

/**
 * Gets a reference to a group childItem off a product, or creates on if it doesn't exist
 * @param  {string} group  The group definition object or simple id of the group to get/create
 * @param  {Object} product  The product object to ProductTreeSearch
 * @return {Object}  A reference to the group object
 */
function getOrCreateGroupOnProduct(group: GroupObject, product = { childItems: [] }) {
	let productGroup;

	product.childItems.forEach((childItem) => {
		if (childItem.itemType === Constants.productChildTypes.GROUP && childItem.id === group.id) {
			productGroup = childItem;
		}
	});

	if (!productGroup) {
		productGroup = {
			path: new HuiPath([product.id, group.id]),
			id: group.id,
			itemType: Constants.productChildTypes.GROUP,
			title: group.title,
			childItems: [],
			count: 0,
			href: group.href || null,
			productId: product.id,
			groupProductId: group.groupProductId,
			rawApiData: group.rawApiData,
		};

		if (group.sortOrder) {
			product.childItems.splice(group.sortOrder, 0, productGroup);
		} else {
			product.childItems.push(productGroup);
		}
	}

	return productGroup;
}

/**
 * Gets a reference to a group childItem off a product, or creates on if it doesn't exist
 * @param  {string} tag  The name of the tag to get/create
 * @param  {Object} product  The product object to ProductTreeSearch
 * @return {Object}  A reference to the tag object
 */
function getOrCreateTagGroup(tag: Object, product = { childItems: [] }) {
	let tagGroup;

	product.childItems.forEach((childItem) => {
		if (childItem.itemType === Constants.productChildTypes.GROUP && childItem.id === tag.id) {
			tagGroup = childItem;
		}
	});

	if (!tagGroup) {
		tagGroup = {
			path: new HuiPath([product.id, tag.id]),
			id: tag.id,
			itemType: Constants.productChildTypes.GROUP,
			title: tag.title,
			childItems: [],
			href: tag.href,
			productId: product.id,
			isTagGroup: true,
			count: 0,
		};

		product.childItems.push(tagGroup);
	}

	return tagGroup;
}

/**
 * Moves groups representing tags (for search results) to the end of their product's list
 * @param {object} productsMap     The productMap object to use to add the treeNode
 */
function sortTagGroupsToEnd(productsMap) {
	Object.values(productsMap).forEach((product) => {
		const nonTagGroups = product.childItems.filter((productChild) => !productChild.isTagGroup);
		const tagGroups = product.childItems.filter((productChild) => productChild.isTagGroup);
		product.childItems = nonTagGroups.concat(...tagGroups);
	});
}

/**
 * Creates product categories and groups the given products under them
 * @param  {Object[]} productList  An array of product data, prepped for rendering
 * @return {Object[]} An array of product category data, prepped for rendering
 */
function getProductCategoryTreeFromProductList(productList: Object[]) {
	const categoriesList = [];
	const categoriesMap = {};

	productList.forEach((product) => {
		if (!categoriesMap[product.category]) {
			const category = {
				title: product.category,
				products: [product],
			};
			categoriesList.push(category);
			categoriesMap[product.category] = category;
		} else {
			categoriesMap[product.category].products.push(product);
		}
	});

	return categoriesList;
}

/**
 * Finds node in a productsTree by the id, type, and productId
 * @param   {string} nodeId        The id of the node to find
 * @param   {string} nodeType      The type of the node to find
 * @param   {string} productId     The productId of the node to find
 * @param   {string} productsTree  The productsTree to find the node in
 * @returns {array}                An array of matching nodes
 */
export const findProductTreeNodes = function findProductTreeNodes(nodeId, nodeType, productId, productsTree) {
	const results = [];

	(productsTree || []).forEach((category) => {
		(category.products || []).forEach((product) => {
			if (product.id !== productId) {
				return;
			}

			(product.childItems || []).forEach((productChild) => {
				if (productChild.id === nodeId && productChild.itemType === nodeType) {
					results.push(productChild);
					return;
				}

				(productChild.childItems || []).forEach((productGrandChild) => {
					if (productGrandChild.id === nodeId && productGrandChild.itemType === nodeType) {
						results.push(productGrandChild);
					}
				});
			});
		});
	});

	return results;
};
