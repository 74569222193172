import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/tooltip';

const Tooltip = (props) => {
	return (
		<div className="hui-tooltip-content">
			{props.children}
		</div>
	);
};

Tooltip.propTypes = {
	children: PropTypes.node,
};

export default Tooltip;
