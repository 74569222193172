const storage = new Map();

let _sessionStorageSupported = null;

const sessionStorageSupported = function () {
	// if we've not calculated this before, try
	if (_sessionStorageSupported === null) {
		try {
			global.sessionStorage.setItem('_testSessionStorageSupported', '1');

			if (global.sessionStorage.getItem('_testSessionStorageSupported') !== '1') {
				throw new Error('Session storage did not return expected value.');
			}

			global.sessionStorage.removeItem('_testSessionStorageSupported');
			_sessionStorageSupported = true;
		} catch (e) {
			_sessionStorageSupported = false;
		}
	}

	return _sessionStorageSupported;
};

const sessionStorageHelper = {
	setItem(key, value) {
		if (sessionStorageSupported()) {
			return global.sessionStorage.setItem(key, value);
		}

		return storage.set(key, value);
	},
	getItem(key) {
		if (sessionStorageSupported()) {
			return global.sessionStorage.getItem(key) || null;
		}

		return storage.has(key) ? storage.get(key) : null;
	},
	removeItem(key) {
		if (sessionStorageSupported()) {
			return global.sessionStorage.removeItem(key);
		}

		return storage.delete(key);
	},
	clear() {
		if (sessionStorageSupported()) {
			return global.sessionStorage.clear();
		}

		return storage.clear();
	},
};

export default sessionStorageHelper;
