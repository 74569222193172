/* global HUI_API_ENV */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import TabData from '../../schemas/TabData';
import Actions from '../../classes/Actions';
import Events from '../../classes/Events';
import Navbar from './Navbar';
import isMobile from '../../utils/isMobile';
import Constants from '../../constants';
import 'scss/components/navbar';

/**
 * Navbar with modifications for BCC
 *
 * NOTE: the global URLs we need to be aware of (like privacy and terms) should be in a main file
 * along with the ones in the Header.
 */
class BccNavbar extends React.Component {

	/**
	 * @constructor
	 */
	constructor() {
		super();

		// so we can throw it around later
		this.onResize = this.onResize.bind(this);
	}

	static propTypes = {
		tabs: PropTypes.arrayOf(PropTypes.object),
		selectedTabData: PropTypes.instanceOf(TabData),
		sidebarExpanded: PropTypes.bool,
		sidebarExpandedMobile: PropTypes.bool,
		navbarTabHrefCallback: PropTypes.func,
		contentArea: PropTypes.string,
		sidebarSelections: PropTypes.object,
		products: PropTypes.array,
		wrapTabs: PropTypes.bool,
		auth: PropTypes.shape({
			account_id: PropTypes.string,
			default_account: PropTypes.string,
			accounts: PropTypes.array,
		}),
		homeHref: PropTypes.string,
	};

	static defaultProps = {
		tabs: [],
		sidebarExpandedMobile: false,
		selectedTabData: null,
		sidebarSelections: {},
		wrapTabs: !isMobile(),
		auth: {},
	};

	/**
	 * React lifecycle hook
	 */
	componentDidMount() {
		this.assertControlOverContentArea();
		Events.addEventListener('globalResize', this.onResize, true);
		Events.addEventListener('pageLayoutReflow', this.onResize);
		this.onResize();
	}

	/**
	 * React lifecycle hook
	 */
	componentWillUnmount() {
		this.accedeControlOverContentArea();
		Events.removeEventListener('globalResize', this.onResize);
		Events.removeEventListener('pageLayoutReflow', this.onResize);
	}

	/**
	 * Adds CSS control classes to the element specified by props.contentArea
	 */
	assertControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.add(Constants.Navbar.contentAreaCssClass);
		}
	}

	/**
	 * Removes CSS control classes from the element specified by props.contentArea
	 */
	accedeControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.remove(Constants.Navbar.contentAreaCssClass);
		}
	}

	/**
	 * React lifecycle hook
	 */
	componentDidUpdate() {
		this.updateContentAreaPosition();
	}

	/**
	 * The default click handler for a navbar tab
	 * @param   {Object} e  The click event
	 */
	defaultTabClickFunction(e) {
		if (!this.href && e) {
			e.preventDefault();
		}

		Actions.selectNavbarTab(new TabData(this), e);
	}

	/**
	 * Event handler for a click on the sidebar expansion "tab"
	 * @param   {bool} expanded  Whether to expand the sidebar
	 */
	handleSidebarExpansionClick(expanded:boolean) {
		if (isMobile()) {
			Actions.changeSidebarVisibilityMobile(expanded);
		} else {
			Actions.changeSidebarVisibility(expanded);
		}
	}

	/**
	 * Puts an href callback on each tab that can return sidebarSelection and tab data
	 * @param {Function} hrefCallback The callback function the data is to be passed to
	 * @param {Object} [sidebarSelections] Current sidebar selection object
	 * @returns {Function} A callback that can be used to change a tab's href
	 */
	makeHrefCallbackForNavbarDataTabs(hrefCallback:Function, sidebarSelections?:Object) {
		return function (href, tabData) {
			return hrefCallback(href, sidebarSelections, tabData);
		};
	}

	/**
	 * Goes through all tabs currently in props and adds necessary data to them
	 * @param {Array} tabs An array of tab objects
	 */
	transformTabs(tabs:Array) {

		tabs.forEach(tab => {

			tab.onClick = this.defaultTabClickFunction;

			if (this.props.navbarTabHrefCallback) {
				tab.hrefCallback = this.makeHrefCallbackForNavbarDataTabs(this.props.navbarTabHrefCallback,
					this.props.sidebarSelections);
			}

			if (Array.isArray(tab.childItems)) {
				this.transformTabs(tab.childItems);
			}
		});
	}
	/**
	 * Creates the data for the product icon tab
	 */
	onResize() {
		// Both statements only run when we enter mobile or enter desktop.
		if (this.props.wrapTabs && isMobile()) {
			Actions.shouldWrapTabs(false);
		} else if (!this.props.wrapTabs && !isMobile()) {
			Actions.shouldWrapTabs(true);
		}

		this.updateContentAreaPosition();
	}

	/**
	 * Updates the content area's offset based on Navbar's position.
	 */
	updateContentAreaPosition() {
		const contentAreaEl = global.document.querySelector(this.props.contentArea);

		if (!contentAreaEl) {
			return;
		}

		const componentEl = ReactDOM.findDOMNode(this);

		if (!componentEl) {
			return;
		}

		const contentAreaOffset = componentEl.offsetTop + componentEl.clientHeight;

		contentAreaEl.style.paddingTop = contentAreaOffset + 'px';
	}

	/**
	 * Gets the warning message for a nondefault account, or null if no warning
	 * @returns {string}  The nondefault account warning message, or null
	 */
	getNondefaultAccountMessage() {
		const isDefaultAccountActive = this.props.auth.account_id === this.props.auth.default_account;
		const isManageAllAccountsAccountActive = (
			this.props.auth.account_id === Constants.Header.manageAllAccountsAccountId
		);
		const activeAccountName = this.getActiveAccountName();
		if (!isDefaultAccountActive && !isManageAllAccountsAccountActive && activeAccountName) {
			return `Warning! You are not accessing your default account!
				Any changes you make are to the active account: ${activeAccountName}.`;
		}
		return null;
	}

	/**
	 * Gets the name of the active account from an auth object
	 * @returns {string}       The name of the active account, or null
	 */
	getActiveAccountName() {
		try {
			return this.props.auth.accounts.filter(account => {
				return account.id === this.props.auth.account_id;
			})[0].name; // Throws TypeError if no auth accounts or no matches
		} catch (e) {
			return null;
		}
	}

	/**
	 * Render method
	 *
	 * @return {jsx} the view
	 */
	render() {
		const tabs = this.props.tabs.slice();

		const leftControls = [];

		/* no product icon needed in HUI 2.0 */
		if (!this.props.hideSidebarToggle) {
			const sidebarExpanded = isMobile()
				? this.props.sidebarExpandedMobile
				: this.props.sidebarExpanded;
			const sidebarIcon = sidebarExpanded ? 'sidebar-close' : 'sidebar-open';
			const sidebarToggleTab = {
				onClick: this.handleSidebarExpansionClick.bind(
					null,
					!sidebarExpanded
				),
				key: 'sidebar-trigger',
				label: 'Sidebar',
				icon: sidebarIcon,
			};

			leftControls.push(sidebarToggleTab);
		}

		const selectedPath = this.props.selectedTabData
			? this.props.selectedTabData.path
			: null;

		this.transformTabs(tabs);

		return (
			<Navbar
				tabs={tabs}
				selectedPath={selectedPath}
				contentArea={this.props.contentArea}
				leftControls={leftControls}
				wrapTabs={this.props.wrapTabs}
				warningMessage={this.getNondefaultAccountMessage()}
			/>
		);
	}
}

export default BccNavbar;
