import CardWrapper from '../CardWrapper';
import HtmlCard from './HtmlCard';

/**
 * Class representing a dashboard card.
 */
export default class HtmlCardWrapper extends CardWrapper {

	/**
	 * Creates a HtmlCard
	 * @param {Object} opts  see CardWrapper opts
	 */
	constructor(opts) {
		super(opts, HtmlCard);
	}
}
