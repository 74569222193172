/* global HUI, HUI_API_ENV */

import Log from 'js/lib/classes/Log';
import { Sidebar as Constants } from 'js/lib/constants';

export const BBSTreeNodesDataProvider = new HUI.Providers.DataProvider((tools, resolve, reject) => {

	if (!tools.urls.bbs) {
		Log.warn(`No BBS API available for environment '${HUI_API_ENV}'.`);
		resolve([]);
		return false;
	}

	return Promise.all([
		tools.auth,
		tools.entitlements,
	]).then(([auth, entitlements]) => {
		const contextQuery = auth.all_accounts_selected ? '' : `filter[account_id]=${auth.account_id}`;

		if (!entitlements.bbs) {
			Log.info('Skipping BBS API call because account ' + auth.account_id + ' does not have an entitlement thereto.');
			// we have to return empty here, or it won't get zeroed out against
			// sessionstorage cache  upon load, or when switching to the account
			resolve([]);
			return;
		}

		return tools.ajax({
			url: `https://${tools.urls.bbs}/v1/devices?include=group&${contextQuery}`,
		}).then(([code, response]) => {
			if (code !== 200) {
				reject(new Error('Could not get BBS device list.'));
				return;
			}

			let output;
			let result;
			try {
				result = JSON.parse(response);
			} catch (e) {
				reject(new Error('Could not decode BBS device list response.'));
				return;
			}

			const groups = {};
			(result.included || []).forEach(resource => {
				if (resource.type === 'groups') {
					groups[resource.id] = resource;
				}
			});
			output = result.data.map(device => {

				let label = null;
				if (device.attributes.trial_status === 'expired') {
					label = {
						text: HUI.translateLid(HUI.Messages.BCC_SIDEBAR_EXPIRED_LABEL),
						color: 'danger',
					};
				} else if (device.attributes.trial_status === 'trial') {
					label = {
						text: HUI.translateLid(HUI.Messages.BCC_SIDEBAR_TRIAL_LABEL),
						color: 'warning',
					};

					const expiryDate = tools.moment(device.attributes.trial_expiration);
					if (expiryDate.isBefore(tools.moment().add(7, 'days'))) {
						label.color = 'danger';
					}
				}

				let processedGroups = [];
				let groupIds = [];
				let deviceGroups = device.relationships.group.data;

				// backwards compatibility for older version of BBS API
				if (deviceGroups != null && deviceGroups.id) {
					groupIds[0] = deviceGroups.id;
				} else if (deviceGroups != null && deviceGroups.length) {
					for (var i = 0; i < deviceGroups.length; i++) {
						groupIds[i] = deviceGroups[i].id;
					}
				} else {
					groupIds[0] = null;
				}

				for (var i = 0; i < groupIds.length; i++) {
					try {
						const group = groups[groupIds[i]];

						// This avoids a null or empty string group ID AND a `null` name attribute.
						if (group == undefined || group.id === null || group.id === '') {
							throw new Error('Invalid Group ID, was null or empty.');
						}

						// Empty string names are allowed
						if (group.attributes.name === null) {
							tools.log.warn("Null device group name found.");
							throw new Error('Invalid Group name, was null.');
						}

						const groupId = `${auth.account_id}-${group.id}`;

						// Add the group to the list.
						processedGroups[i] = {
							id: groupId,
							title: group.attributes.name,
							rawApiData: group,
							href: `https://${tools.urls.bbs_web}/status/groups/${groupId}/`,
						};
					} catch (e) {
						const groupId = `${auth.account_id}-0`;
						processedGroups[i] = {
							id: groupId,
							title: HUI.translateLid(HUI.Messages.BBS_DEFAULT_GROUP_NAME),
							href: `https://${tools.urls.bbs_web}/status/groups/${groupId}/`,
							rawApiData: {},
						};
					}
				}

				return {
					id: device.id,
					itemType: Constants.productChildTypes.DEVICE,
					serial: device.attributes.serial,

					// The trailing slash is important here. BBS looks at this HREF
					// and manipulates it when navigating. /status/ is assumed.
					href: `https://${tools.urls.bbs_web}/bbs_${device.id}/status/`,
					local_ip: device.attributes.local_ip,
					status: device.attributes.overall_status.replace('warning', 'warn'),
					title: device.attributes.name || `BBS ${device.id}`,
					groups: processedGroups,
					productId: 'bbs',
					rawApiData: device,
					label,
				};
			});

			// sort alphabetically by whether a device is in the CCB group, then group title, then device title
			let ccb_id = `${auth.account_id}--1`;

			output = output.sort((a, b) => {
				return ccbGroupCompare(a, b, ccb_id)
					|| a.groups[0].title.toLowerCase().localeCompare(b.groups[0].title.toLowerCase())
					|| a.title.toLowerCase().localeCompare(b.title.toLowerCase());
			});

			resolve(output);
		});
	});
});

/**
 * Compares whether a device's group is the CCB group
 * @param  {object} a item a
 * @param  {object} b item b
 * @param  {int}    ccb_id id for the CCB group
 * @return {number}  -1, 0, or 1
 */
function ccbGroupCompare(a, b, ccb_id) {

	if (a.groups[0].id === ccb_id && b.groups[0].id !== ccb_id) {
		return -1;
	} else if (a.groups[0].id !== ccb_id && b.groups[0].id === ccb_id) {
		return 1;
	}

	return 0;
}
