export const BILLING_ACCOUNT_DROPDOWN_ITEM_TITLE = 555; // Adminstration
export const USER_PROFILE_DROPDOWN_ITEM_TITLE = 15292; // My Profile
export const CHANGE_LANGUAGE_DROPDOWN_ITEM_TITLE = 7518; // Language
export const BCC_HEADER_DOWNLOADS_LINK_TITLE = 46220; // Downloads
export const BCC_HEADER_COMMUNITY_LINK_TITLE = 15411; // Community
export const BCC_HEADER_SUPPORT_LINK_TITLE = 5991; // Support

export const DASHBOARD_TABLE_CARD_EMPTY = 30606; // Empty

export const SIGN_IN_DROPDOWN_ITEM_TITLE = 11295; // Sign In
export const SIGN_OUT_DROPDOWN_ITEM_TITLE = 11294; // Sign Out

// export const BCC_FOOTER_COMPANY_NAME = 13126; // Barracuda Networks
export const BCC_FOOTER_ALL_RIGHTS_RESERVED = 13263; // All rights reserved.
export const BCC_FOOTER_PRIVACY_POLICY_LINK_TITLE = 13264; // Privacy Policy
export const BCC_FOOTER_TERMS_OF_SERVICE_LINK_TITLE = 27081; // Terms of Service

export const BCC_SIDEBAR_SEARCH_PLACEHOLDER_TITLE = 3549; // Search
export const BCC_SIDEBAR_HOME_PRODUCT_TITLE = 6632; // Home

export const BCC_SIDEBAR_TRIAL_LABEL = 22647; // Trial
export const BCC_SIDEBAR_EXPIRED_LABEL = 1179; // Expired

export const BBS_DEFAULT_GROUP_NAME = 39849; // Main

export const FILTER_TIME_24_HOURS = 39817; // Last 24 Hours
export const FILTER_TIME_7_DAYS = 9696; // Last 7 Days
export const FILTER_TIME_30_DAYS = 9697; // Last 30 Days
export const LAYOUT_NO_SCALING = 39818; // No Scaling
export const LAYOUT_SHRINK_TO_FIT = 39819; // Shrink to Fit
export const LAYOUT_GROW_TO_FIT = 39820; // Grow to Fit
export const LAYOUT_ALWAYS_FIT = 39821; // Always Fit
export const DASHBOARD_AUTOMATIC_REFRESH = 280; // Refresh
export const CHANGE_ACCOUNT_DROPDOWN_LABEL_TITLE = 39822; // Change Account

export const ACCOUNT_DROPDOWN_DEFAULT = 1912; // Default
export const ACCOUNT_DROPDOWN_MANAGE_ALL = 38849; // Manage All Accounts

export const DATA_SIZE_B = 35785; // B
export const DATA_SIZE_KB = 3970; // KB
export const DATA_SIZE_MB = 3969; // MB
export const DATA_SIZE_GB = 13782; // GB
export const DATA_SIZE_TB = 13783; // TB
export const DATA_SIZE_PB = 13784; // PB
export const DATA_SIZE_EB = 13785; // EB
export const DATA_SIZE_ZB = 13786; // ZB
export const DATA_SIZE_YB = 13787; // YB
