import Reflux from 'reflux';
import Actions from '../classes/Actions';
import Log from '../classes/Log';
import CardStore from './CardStore';

// Brings in getState() and this._data
import StoreMixins from '../stores/StoreMixins';

const PanelStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	init() {
		this.listenTo(CardStore, this.onCardStoreChange);
	},
	getData() {
		const panels = this.getState();

		Object.values(panels).forEach(panel => {
			if (panel.cards) {
				panel.cards = panel.cards.map(card => CardStore.getCard(card));
			} else if (panel.panels) {
				panel.panels = panel.panels.map(p => this.getPanel(p));
			}
		});

		return panels;
	},
	getPanel(id) {
		const panel = this.getState()[id];

		if (panel) {
			if (panel.cards) {
				panel.cards = panel.cards.map(card => CardStore.getCard(card));
			} else if (panel.panels) {
				panel.panels = panel.panels.map(p => this.getPanel(p));
			}
		}

		return panel;
	},
	onCardStoreChange() {
		this.triggerWithFullData();
	},
	createPanel(opts) {
		if (!opts) throw Error('Panel options required');
		if (!opts.id) throw Error('Panel `id` is required');
		if (opts.cards && !Array.isArray(opts.cards)) throw Error('Panel `cards` must be an array');

		if (!opts.cards) {
			opts.cards = [];
		}

		const newState = this.getState();
		newState[opts.id] = opts;

		this._data = newState;
		this.triggerWithFullData();
	},
	addCardsToPanel(cardIds, panelId) {
		if (cardIds && !Array.isArray(cardIds)) throw Error('`cardIds` must be an array');
		if (cardIds && !cardIds.length) {
			Log.warn(`addCardsToPanel called on ${panelId} with no cardIds`);
		}

		const newState = this.getState();
		const panel = newState[panelId];
		panel.cards = panel.cards.concat(cardIds);

		this._data = newState;
		this.triggerWithFullData();
	},

	createPanelGroup(opts) {
		if (!opts) throw Error('Panel options required');
		if (!opts.id) throw Error('Panel `id` is required');
		if (opts.panels && !Array.isArray(opts.panels)) throw Error('Panel `panels` must be an array');

		if (!opts.panels) {
			opts.panels = [];
		}

		const newState = this.getState();
		newState[opts.id] = opts;

		this._data = newState;
		this.triggerWithFullData();
	},
	addPanelsToPanelGroup(panelIds, panelGroupId) {
		if (panelIds && !Array.isArray(panelIds)) throw Error('`panelIds` msut be an array');
		if (panelIds && !panelIds.length) {
			Log.warn(`addPanelsToPanelGroup called on ${panelGroupId} with no panelIds`);
		}

		const newState = this.getState();
		const panel = newState[panelGroupId];
		panel.panels = panel.panels.concat(panelIds);

		this._data = newState;
		this.triggerWithFullData();
	},

	setPanelActiveCard(panelId, activeIdx) {
		const newState = this.getState();

		if (!newState[panelId]) {
			return;
		}

		newState[panelId].activeCardIndex = activeIdx;

		this._data = newState;
		this.triggerWithFullData();
	},

	// convenient version of setPanelActiveCard that takes a cardId
	makeCardActive(cardId) {
		const newState = this.getState();

		Object.entries(newState).forEach(([panelId, panel]) => {
			if (!panel.cards) {
				return;
			}

			const foundCardIndex = panel.cards.indexOf(cardId);
			if (foundCardIndex !== -1) {
				this.setPanelActiveCard(panelId, foundCardIndex);
			}
		});
	},
});

export default PanelStore;
