import React from 'react';
import ReactDOM from 'react-dom';
import Card from '../Card';
import actions from '../../../classes/Actions';
import Loading from '../../common/Loading';
import ErrorView from '../../common/ErrorView';

/**
 * Class representing the react class for the dashboard.
 */
export default class HtmlCard extends Card {

	/**
	 * Finds any form inputs in the htmlcard
	 * @return {NodeList[]} Every form element in the card
	 */
	getFormElements() {
		const formElementsNodeList =
			ReactDOM.findDOMNode(this).querySelectorAll('input, select, textarea');
		return [].slice.apply(formElementsNodeList);
	}

	/**
	 * Saves the form inputs to the store
	 */
	persistFormInputs() {
		const inputs = {};

		this.getFormElements().forEach(element => {
			inputs[element.name] = element.value;
		});

		actions.persistHtmlCardInputs(this.props.id, inputs);
	}

	/**
	 * Populates form inputs on the card with saved values
	 */
	reapplyUserInput() {
		if (!this.props.formInputs) return;

		this.getFormElements().forEach(element => {
			if (this.props.formInputs[element.name] !== undefined) {

				// Applies our value or the default, if it exists
				element.value = this.props.formInputs[element.name] || element.value;
			}
		});
	}

	/**
	 * React lifecycle hook
	 */
	componentDidMount() {
		this.reapplyUserInput();
	}

	/**
	 * React lifecycle hook
	 */
	componentWillUnmount() {
		this.persistFormInputs();
	}

	/**
	 * React lifecycle hook
	 */
	componentDidUpdate() {
		this.reapplyUserInput();
	}

	/**
	 * Render the core Card React instance.
	 * @return {jsx} the jsx of the card
	 */
	render() {
		if (this.props.error) {
			return (
				<div className="hui-card hui-card-htmlcard">
					<ErrorView onRetry={this.props.refetchData} />
				</div>
			);
		}

		const htmlDiv = this.props.data ?
			<div dangerouslySetInnerHTML={ { __html: this.props.data.html } } /> : null;

		return (
			<div className={`hui-card hui-card-htmlcard ${this.getCssIdClass()}`}>
				<Loading busy={this.props.busy}>
					{ htmlDiv }
				</Loading>
			</div>
		);
	}
}
