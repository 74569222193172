import PropTypes from 'prop-types';
import React from 'react';
import Actions from '../../classes/Actions';
import debounce from 'lodash/debounce';
import 'scss/components/productSearch';
import { InjectLocalizedAttribute } from 'js/lib/i18n';
import { BCC_SIDEBAR_SEARCH_PLACEHOLDER_TITLE } from 'js/lib/lids';

/**
 * Sidebar React component for a search box.
 */
class ProductTreeSearch extends React.Component {
	static propTypes = {
		searchBoxValue: PropTypes.string,
		disabled: PropTypes.bool,
	};

	/**
	 * @constructor
	 */
	constructor() {
		super();

		// Preferred way of setting a debounce on a component's function.
		// Source: http://stackoverflow.com/a/28046731/231730
		this.setProductSearch = debounce(this.setProductSearch, 100);

		this.onSearchInput = this.onSearchInput.bind(this);
	}

	/**
	 * React lifecycle hook
	 */
	componentWillMount() {
		// If a search box on the page has a search value,
		// it should carry over to our newly mounted one too.
		// Grab it from props.
		this.setState({
			searchBoxValue: this.props.searchBoxValue,
		});
	}

	/**
	 * React lifecycle hook
	 * @param {Object} nextProps The component's soon-to-be props
	 */
	componentWillReceiveProps(nextProps) {
		// When one search box makes a search, all other search
		// boxes should grab the new search value and use that.
		this.setState({
			searchBoxValue: nextProps.searchBoxValue,
		});
	}

	/**
	 * Calls to Action to set our global product search value.
	 * @param {string} searchBoxValue The value of the search box input.
	 */
	setProductSearch(searchBoxValue) {
		Actions.setProductSearch(searchBoxValue);
	}

	/**
	 * Product tree search event handler.
	 * @param {Object} keyEvent  A key event.
	 */
	onSearchInput(keyEvent) {
		const searchBoxValue = keyEvent.target.value;

		// The search box uses state to keep track of it's contents
		// because all search inputs use what's in props to stay in sync
		// but the current, typing value needs to be held and stored
		// for fast typing because of our debounce.
		// This allows us to type fast in the input , and when props are
		// received, our state on all search inputs are updated.
		this.setState({
			searchBoxValue,
		});

		// clear search result isolation
		Actions.setShowSelectionOnly(false);

		this.setProductSearch(searchBoxValue);
	}

	/**
	 * Renders the core React component.
	 * @return {jsx} the jsx view
	 */
	render() {
		const classNames = [];

		if (this.props.disabled) {
			classNames.push('hui-state-disabled');
		}

		return (
			<div className={`hui-product-search ${classNames.join(' ')}`}>
				<span className="hui-product-search-icon" />
				<InjectLocalizedAttribute lid={BCC_SIDEBAR_SEARCH_PLACEHOLDER_TITLE} attribute="placeholder">
					<input
						type="text"
						className="hui-product-search-input"
						disabled={this.props.disabled}
						onInput={this.onSearchInput}
						defaultValue={this.state.searchBoxValue}
					/>
				</InjectLocalizedAttribute>
			</div>
		);
	}
}

export default ProductTreeSearch;
