import DataProvider from './DataProvider';

/**
 * AjaxProvider provides sugar on top of a common DataProvider use case
 */
export default class AjaxProvider extends DataProvider {
	/**
	 * Creates a new AjaxProvider.
	 *
	 * @param {string|object} options to pass to nanoajax, or an URL for a GET request.
	 * @param {Function} callback called when the request comes back.
	 * @constructor
	 */
	constructor(options: ?string|?Object|?Function, callback: ?Function) {
		// check required params before calling super
		if (!options) {
			throw new Error('AjaxProviders require an options array or url string.');
		}

		super(callback);

		// assign parameters (callback is handled by superclass)
		Object.assign(this, {
			options: typeof options === 'string' ? { url: options } : options,
		});
	}

	/**
	 * Called when data is requested.
	 *
	 * @param {Function} resolve called when request returns.
	 * @param {Function} reject called when there is an error.
	 * @param {Object} target reference to the calling target
	 */
	getData(resolve, reject, target) {
		this.tools.target = target;
		this.getDataResolveCallback = resolve;
		this.getDataRejectCallback = reject;

		// allow options to be a function (tools is passed to it)
		const options = typeof this.options === 'function' ? this.options(this.tools) : this.options;

		this.tools.ajax(options, (code, response) => {
			// if ajax error short circuit
			// we don't explicitly return because we want to give the callback
			// an opportunity to reject
			if (response === 'Error' || response === 'Abort') {
				reject(new Error('Ajax request failed.'));
				// return;
			} else if (response === 'Timeout') {
				reject(new Error('Ajax request timed out.'));
				// return;
			} else if (code !== 200) {
				reject(new Error(`Ajax request returned with code: ${code}`));
				// return;
			}

			try {
				this.callback(response, this.tools, resolve, reject);
			} catch (e) {
				reject(e);
			}
		});
	}
}
