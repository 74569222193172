import PropTypes from 'prop-types';
import React from 'react';

const Watermark = (props) => {
	return props.message ? (
		<div className="hui-card-watermark">
			{props.message}
		</div>
	) : (
		<div />
	);
};

Watermark.propTypes = {
	message: PropTypes.string,
};

export default Watermark;
