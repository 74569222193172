import PropTypes from 'prop-types';
import React from 'react';
import HuiPath from '../../classes/HuiPath';
import TabData from '../../schemas/TabData';
import { LocalizedString, translateLid } from 'js/lib/i18n';

/**
 * Stateless component for a tab.
 *
 * @param  {props} props should contain:
 *      {
 *      }
 * @return {jsx} the jsx for tab
 */
const Tab = (props) => {
	const classNames = ['hui-tab'];

	if (props.selectedPath) {
		if (props.selectedPath.startsWith(props.path)) {
			classNames.push('hui-state-selected');
		}
	} else if (props.isSelected) {
		classNames.push('hui-state-selected');
	}

	if (props.isDisabled) {
		classNames.push('hui-state-disabled');
	}

	if (props.isLoading) {
		classNames.push('hui-state-loading');
	}

	if (props.icon) {
		classNames.push('hui-has-icon');
	}

	if (props.childItems.length) {
		classNames.push('hui-has-children');
	}

	// Sets the current TabData, which gets passed to the click handler
	const tabData = new TabData(props);

	const href = props.href && !props.isDisabled ?
		{ href: props.hrefCallback(props.href, new TabData(props)) } :
		{};

	const clickHandler = function () {
		if (!props.isDisabled) {
			props.onClick.apply(tabData, arguments);
		}
	};

	// allows non-wrapping tabs, since white-space: nowrap screws up our overflow measuring code
	const nowrap = text => text.replace(/ /g, '\u00a0');

	const textComponent = props.lid ? <LocalizedString lid={props.lid} postFn={nowrap} /> : nowrap(props.label);
	const text = props.lid ? translateLid(props.lid) : props.label;

	return (
		<div className={classNames.join(' ')}>
			<a
				{...href}
				onClick={clickHandler}
				className="hui-tab-label hui-href"
				disabled={props.isDisabled}
			>
				{props.icon
					? <span className={`hui-tab-icon hui-icon-${props.icon}`}>
						<span className="hui-tab-label-inner" data-title={text}>{textComponent}</span>
					</span>
					: <span className="hui-tab-label-inner" data-title={text}>{textComponent}</span>
				}
			</a>
		</div>
	);
};

/**
* Set up validation rules.
*/
Tab.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool,
	isHidden: PropTypes.bool,
	isLoading: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.string.isRequired,
	href: PropTypes.string,
	lid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	icon: PropTypes.string,
	path: PropTypes.instanceOf(HuiPath),
	selectedPath: PropTypes.instanceOf(HuiPath),
	childItems: PropTypes.array,
	onClick: PropTypes.func,
	// Allows overriding the href via a callback
	hrefCallback: PropTypes.func,

};

/**
 * Set up default props.
 */
Tab.defaultProps = {
	isSelected: false,
	isDisabled: false,
	isHidden: false,
	isLoading: false,
	href: null,
	lid: null,
	icon: null,
	path: new HuiPath([]),
	selectedPath: null,
	childItems: [],
	onClick: () => {},
	hrefCallback: href => href,
};

export default Tab;
