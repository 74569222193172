import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/label';

const Label = (props) => {

	const colorClass = props.color ? `hui-label-${props.color}` : '';

	return (
		<span className={`hui-label ${colorClass}`}>
			{props.text}
		</span>
	);
};

Label.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
	]),
	color: PropTypes.oneOf(['warning', 'danger']),
};

export default Label;
