import PropTypes from 'prop-types';
import React from 'react';
import Log from 'js/lib/classes/Log';
import ErrorView from '../common/ErrorView';
import 'scss/components/card';

/**
 * Class representing the react class for the dashboard.
 */
export default class Card extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		id: PropTypes.string,
		title: PropTypes.string,
		titleNote: PropTypes.string,
		filterRange: PropTypes.string,
		selectedDeviceIds: PropTypes.object,
		refetchData: PropTypes.func,
		titleInfoIcon: PropTypes.node,
	};

	static defaultProps = {
		data: {},
		busy: false,
	};

	static _isCardComponent = true;

	/**
	 * Returns a generated css class that reflects props.id
	 * @returns {string} the css class
	 */
	getCssIdClass() {
		return this.props.id ? `hui-card-id-${this.props.id.replace(/\./g, '-')}` : '';
	}

	/**
	 * Render the core Card React instance.
	 * @return {jsx} An ErrorView component
	 */
	render() {
		Log.error('HUI: Instantiating abstract Card directly');
		return (
			<div className="hui-card hui-card-errorcard">
				<ErrorView />;
			</div>
		);
	}
}
