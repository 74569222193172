import Log from 'js/lib/classes/Log';

/**
 * parseXML
 *
 * lazy loads xml2js
 */
export default function parseXML(...args) {
	// lazy load xml parser
	require.ensure([], () => {
		const xml2js = require('xml2js');

		xml2js.parseString(...args);
	});
}

/**
 * parseXMLViaBrowser
 * @param {string} xml the xml to parse
 * @returns {string|null} the result
 */
export function parseXMLViaDOMParser(xml) {
	if (global.DOMParser) {
		const parser = new global.DOMParser();
		try {
			return processXMLNode(parser.parseFromString(xml, 'application/xml'));
		} catch (e) {
			Log.warn(e);
		}
	}

	return null;
}

/**
 * processXMLNode
 * @param {xml node} node the node to process
 * @returns {object} the processed object
 */
function processXMLNode(node) {
	let output = {};

	// shortcut for text nodes
	if (node.firstChild && node.firstChild.nodeName === '#text') {
		output = node.firstChild.nodeValue;

	// empty nodes
	} else if (node.childNodes && !node.childNodes.length) {
		output = '';

	// arrays
	} else if (node.childNodes) {
		Array.from(node.childNodes).forEach((child) => {
			// if key already exists
			if (output[child.nodeName]) {
				// and is not an array
				if (!Array.isArray(output[child.nodeName])) {
					// make it an array
					output[child.nodeName] = [output[child.nodeName]];
				}
				// push to the array
				output[child.nodeName].push(processXMLNode(child));
			} else {
				output[child.nodeName] = processXMLNode(child);
			}
		});
	}

	return output;
}
