import DataProvider from './DataProvider';

/**
 * StaticProvider provides sugar on top of a common DataProvider use case
 */
export default class StaticProvider extends DataProvider {
	/**
	 * Creates a new StaticProvider.
	 *
	 * @param {object} data to pass directly to the DataProvider.
	 * @param {Function} callback called with the data.
	 * @constructor
	 */
	constructor(data, callback: ?Function) {
		// check required params before calling super
		if (!data) {
			throw new Error('StaticProviders require a data object.');
		}

		super(callback);

		// assign parameters (resolve & reject are handled by superclass)
		Object.assign(this, { data });
	}

	/**
	 * Called when data is requested.
	 *
	 * @param {Function} resolve called with static data
	 * @param {Function} reject is never called because this is static
	 * @param {Object} target reference to the calling target
	 */
	getData(resolve, reject, target) {
		this.tools.target = target;
		this.getDataResolveCallback = resolve;
		this.getDataRejectCallback = reject;

		this.callback(this.data, this.tools, resolve, reject);
	}
}
