import React from 'react';
import Card from '../Card';
import Loading from '../../common/Loading';
import ErrorView from '../../common/ErrorView';

/**
 * Class representing the react class for the dashboard.
 */
export default class BadgeCard extends Card {
	/**
	 * BadgeCardDataProvider Interface:
	 * dataProvider.getData() returns
	 *  {
	 *	  icon: a string or null - either 'good', 'warn', 'error', or null
	 *	  message: a string - the display message
	 *  }
	 */
	state = {
		data: {
			icon: null,
			message: 'No Data Provided',
		},
	};

	/**
	 * Render the core Card React instance.
	 *
	 * @return {jsx} A card React element.
	 */
	render() {
		if (this.props.error) {
			return (
				<div className="hui-card hui-card-badgecard">
					<ErrorView onRetry={this.props.refetchData} />
				</div>
			);
		}

		let iconClass;

		if (this.props.data.icon) {
			iconClass = 'hui-icon hui-icon-status-' + this.props.data.icon;
		}

		return (
			<div className={`hui-card hui-card-badgecard ${this.getCssIdClass()}`}>
				<Loading busy={this.props.busy}>
					<span className={iconClass} />
					<span dangerouslySetInnerHTML={{ __html: this.props.data.message }} />
				</Loading>
			</div>
		);
	}
}
