import CardWrapper from '../CardWrapper';
import ReactCard from './ReactCard';

/**
 * Class representing a dashboard card.
 */
export default class ReactCardWrapper extends CardWrapper {

	/**
	 * Creates a ReactCard
	 * @param {Object} opts  see CardWrapper opts
	 */
	constructor(opts) {
		super(opts, ReactCard);
	}
}
