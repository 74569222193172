import React from 'react';
import BaseWrapper from '../BaseWrapper';
import actions from '../../classes/Actions';
import BccNavbar from './BccNavbar';
import ControllerView from '../ControllerView';
import NavbarStore from '../../stores/NavbarStore';
import SidebarStore from '../../stores/SidebarStore';

/**
 * Class representing a dashboard.
 */
export default class NavbarWrapper extends BaseWrapper {
	/**
	 * Create a navbar.
	 *
	 * @param {Object} opts Options of the format:
	 *      {
	 *          id: a unique identifier for the dashboard
	 *          user: a string containing the current user's username, to pass to dataProviders
	 *          container: an HtmlElement or unique CSS selector for the element's root
	 *          options: an object of behavior customization options
	 *      {
	 */
	constructor(opts = {}) {
		super(opts, BccNavbar);

		actions.initializeNavbar(opts);
	}

	/**
	 * Instantiates the React component.
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		const navbarTransform = stores => {
			const data = stores.navbarStore;

			if (stores.navbarStore.selectedTabData) {
				data.selectedPath = stores.navbarStore.selectedTabData.path;
			}

			data.sidebarSelections = stores.sidebarStore.selections;
			data.products = stores.sidebarStore.products;

			return data;
		};

		return (
			<ControllerView
				component={BccNavbar}
				transform={navbarTransform}
				stores={{ sidebarStore: SidebarStore, navbarStore: NavbarStore }}
			/>
		);
	}
}
