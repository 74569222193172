import React from 'react';
import HtmlCard from './HtmlCard';
import Loading from '../../common/Loading';
import ErrorView from '../../common/ErrorView';

/**
 * Class representing the react class for the dashboard.
 */
export default class ReactCard extends HtmlCard {

	/**
	 * Render the core Card React instance.
	 * @return {jsx} the jsx of the card
	 */
	render() {
		if (this.props.error) {
			return (
				<div className="hui-card hui-card-reactcard">
					<ErrorView onRetry={this.props.refetchData} />
				</div>
			);
		}

		const reactDiv = this.props.data ?
			<div>{this.props.data.react}</div> : null;

		return (
			<div className={`hui-card hui-card-reactcard ${this.getCssIdClass()}`}>
				<Loading busy={this.props.busy}>
					{ reactDiv }
				</Loading>
			</div>
		);
	}
}
