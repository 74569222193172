import React from 'react';
import BootstrapOverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

// this is a wrapper component so we can add our HUI css classes as defaults
const OverlayTrigger = props => {
	const newProps = { ...props };
	return (
		<BootstrapOverlayTrigger {...newProps}>
			{props.children}
		</BootstrapOverlayTrigger>
	);
};

export default OverlayTrigger;
export { OverlayTrigger };
