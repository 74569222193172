import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import HuiPath from '../../classes/HuiPath';
import Tab from './Tab';
import Events from '../../classes/Events';
import isEqual from 'lodash/isEqual';

class NavbarRow extends React.Component {
	/**
	 * @constructor
	 */
	constructor() {
		super();

		this.onResize = this.onResize.bind(this);
	}

	static propTypes = {
		tabs: PropTypes.array,
		leftControls: PropTypes.array,
		rightControls: PropTypes.array,
		selectedPath: PropTypes.instanceOf(HuiPath),
		depth: PropTypes.number,
		wrapTabs: PropTypes.bool,
	};

	static defaultProps = {
		tabs: [],
		leftControls: [],
		rightControls: [],
		selectedPath: new HuiPath([]),
		depth: 0,
	};

	state = {
		displayTabs: [],
		dropdownTabs: [],
	};

	/**
	 * React lifecycle hook
	 */
	componentDidMount() {
		Events.addEventListener('globalResize', this.onResize, true);
		Events.addEventListener('pageLayoutReflow', this.onResize);
		setTimeout(() => {
			this.organizeTabs(this.measureTabs());
		}, 100);
	}

	/**
	 * React lifecycle hook
	 * @param {Object} nextProps The component's new properties
	 */
	componentWillReceiveProps(nextProps) {
		const changedTabs = !isEqual(nextProps.tabs, this.props.tabs);
		const changedWrap = nextProps.wrapTabs !== this.props.wrapTabs;

		// Different tabs means we need to redo all the calculations:
		// render with all, measure, render again with filter. So we need to get
		// rid of the cached layout and measurements
		if (changedTabs) {
			this.setState({
				tabMeasurements: {},
				displayTabs: [],
			});
		}

		if (changedWrap && !nextProps.wrapTabs) {
			this.organizeTabs(this.measureTabs());
		}
	}

	/**
	 * React lifecycle hook
	 * @param {Object} prevProps The component's previous properties
	 */
	componentDidUpdate(prevProps) {
		const changedTabs = !isEqual(prevProps.tabs, this.props.tabs);

		if (changedTabs) {
			// If the tabs are different, we need to remeasure and reorganize
			this.organizeTabs(this.measureTabs());
		} else if (prevProps.selectedPath && !prevProps.selectedPath.equals(this.props.selectedPath)) {
			// If the selection is different, we just need to reorganize
			this.organizeTabs();
		}
	}

	/**
	 * React lifecycle hook
	 */
	componentWillUnmount() {
		global.removeEventListener('resize', this.onResize);
		Events.removeEventListener('pageLayoutReflow', this.onResize);
	}

	/**
	 * Window resize event handler
	 */
	onResize() {
		this.organizeTabs();
	}

	/**
	 * Measures the NavbarRow's tabs in the DOM. Returns the measurements,
	 * rather than saving them to state so the requester can choose what to do with them.
	 * @returns {Object}  The child tabs measurements, keyed by label
	 */
	measureTabs() {
		const domNode = ReactDOM.findDOMNode(this);
		const tabNodes = domNode.querySelectorAll('.hui-navbarrow-content .hui-tab');

		const widthsByTabLabel = {};
		let hiddenTabs = 0;

		this.props.tabs.forEach((tab, idx) => {
			if (!tab.isHidden) {
				widthsByTabLabel[tab.label] = tabNodes[idx - hiddenTabs].offsetWidth;
			} else {
				widthsByTabLabel[tab.label] = 0;
				hiddenTabs += 1;
			}
		});

		return widthsByTabLabel;
	}

	/**
	 * Arranges tabs between content area and overflow dropdown
	 * @param   {Object} newTabMeasurements  New tab measurements, if there are any
	 */
	organizeTabs(newTabMeasurements: ?Object) {
		if (this.props.wrapTabs) {
			this.setState({
				tabMeasurements: {},
				displayTabs: this.props.tabs,
				dropdownTabs: [],
			});

			return;
		}

		const tabMeasurements = newTabMeasurements ? newTabMeasurements : this.state.tabMeasurements;
		const displayTabs = [];
		const dropdownTabs = [];

		const navbarNode = ReactDOM.findDOMNode(this);
		const navbarContentNode = navbarNode.querySelector('.hui-navbarrow-content');
		const availableContentWidth = navbarContentNode.clientWidth;

		const selectedTab = this.props.tabs.filter((tab) => {
			return this.props.selectedPath && this.props.selectedPath.startsWith(tab.path);
		})[0];
		const selectedTabWidth = (selectedTab && tabMeasurements[selectedTab.label]) || 0;

		// Assume the selected dropdown
		let currentContentWidth = selectedTabWidth;
		let doneAddingTabs = false;

		this.props.tabs.forEach((tab, idx) => {
			const tabWidth = tabMeasurements[tab.label];

			if (tab === selectedTab) {
				displayTabs[idx] = tab; // make a sparse array to preserve order
				// Don't add the width, it's already accounted for above
			} else if (currentContentWidth + tabWidth < availableContentWidth && !doneAddingTabs) {
				displayTabs[idx] = tab;
				currentContentWidth += tabWidth;
			} else {
				// If we run out of space, stop, otherwise smaller tabs may still be added
				dropdownTabs.push(tab);
				doneAddingTabs = true;
			}
		});

		this.setState({
			tabMeasurements,
			displayTabs: displayTabs.filter(Boolean), // remove any empties
			dropdownTabs,
		});
	}

	/**
	 * React lifecycle method
	 * @returns {jsx} the jsx view
	 */
	render() {
		let displayTabs = this.state.displayTabs;
		if (!displayTabs.length && this.props.tabs.length) {
			displayTabs = this.props.tabs;
		}
		const ifSubnavClass = this.props.depth >= 0 ? 'hui-navbarrow-subnav' : '';
		const renderOverflow = !this.state.tabMeasurements || this.state.dropdownTabs.length;
		const overflowDropdownRendering = renderOverflow ? (
			<DropdownButton
				className="hui-navbarrow-tab-overflow"
				id={'huiNavbarRow' + this.props.depth}
				title=""
				bsSize="large"
				bsStyle="link"
				pullRight
			>
				{this.state.dropdownTabs.map((tab, idx) => (
					<MenuItem key={idx} onSelect={tab.onClick.bind(tab)} href={tab.href}>
						{tab.label}
					</MenuItem>
				))}
			</DropdownButton>
		) : null;

		return (
			<div className={`hui-navbarrow hui-bootstrap-container ${ifSubnavClass}`}>
				<div className="hui-navbarrow-left">
					<div className="hui-navbarrow-left-controls">
						{this.props.leftControls.map((tab, idx) => (
							<Tab {...tab} key={idx} />
						))}
					</div>
					<div className="hui-navbarrow-content">
						{displayTabs.map((tab, idx) => {
							let el;
							if (tab.isHidden) {
								el = false;
							} else {
								el = <Tab {...tab} key={idx} selectedPath={this.props.selectedPath} />;
							}

							return el;
						})}
					</div>
				</div>
				<div className="hui-navbarrow-right">
					{overflowDropdownRendering}
					{this.props.rightControls.map((tab, idx) => (
						<Tab {...tab} key={idx} />
					))}
				</div>
			</div>
		);
	}
}

export default NavbarRow;
