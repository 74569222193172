import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/navbar';

/**
 * A Navbar component
 */
class NavbarWarning extends React.Component {

	static propTypes = {
		message: PropTypes.string,
	};

	/**
	 * Render method
	 *
	 * @return {jsx} the view
	 */
	render() {
		if (!this.props.message) {
			return null;
		}

		return (
			<div className="hui-navbar-warning">
				{ this.props.message }
			</div>
		);
	}
}

export default NavbarWarning;
