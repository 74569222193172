import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/header';
import { Header as Constants } from '../../constants';
import Events from '../../classes/Events';

/**
 * Customizable Header component.
 */
export default class Header extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		contentArea: PropTypes.string,
		title: PropTypes.string,
		children: PropTypes.node,
		href: PropTypes.string,
		className: PropTypes.string,
	};

	/**
	 * React lifecycle hook
	 */
	componentDidMount() {
		this.assertControlOverContentArea();
		Events.trigger('headerMounted');
	}

	/**
	 * React lifecycle hook
	 */
	componentWillUnmount() {
		this.accedeControlOverContentArea();
	}

	/**
	 * Adds CSS control classes to the element specified by props.contentArea
	 */
	assertControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.add(Constants.contentAreaCssClass);
		}
	}

	/**
	 * Removes CSS control classes from the element specified by props.contentArea
	 */
	accedeControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.remove(Constants.contentAreaCssClass);
		}
	}

	/**
	 * Render the header
	 * @return {jsx} the jsx of the header.
	 */
	render() {
		const logoTitleRendering = (
			<div className="hui-logo">
				<div className="hui-logo-image"></div>
				<div className="hui-logo-name">
					<div className="hui-logo-company">Barracuda</div>
					<div className="hui-logo-product">{this.props.title}</div>
				</div>
			</div>
		);

		let title = logoTitleRendering;

		if (this.props.href) {
			title = <a href={this.props.href}>{title}</a>;
		}

		return (
			<div className={`hui-header hui-bootstrap-container ${this.props.className}`}>
				<div className="hui-header-left-contents">{title}</div>
				<div className="hui-header-right-contents">{this.props.children}</div>
			</div>
		);
	}
}
