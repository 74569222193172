import Reflux from 'reflux';
import Actions from '../classes/Actions';
import Events from '../classes/Events';
import AppStore from './AppStore';
import buildProductsTree, { findProductTreeNodes } from '../classes/buildProductsTree';
import Log from '../classes/Log';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import { Sidebar as Constants } from '../constants';
import getFirstDefined from '../utils/getFirstDefined';

// Brings in getState() and _data
import StoreMixins from '../stores/StoreMixins';

const SidebarStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	STORAGE_KEY: 'sidebar',
	init() {
		this.listenTo(AppStore, this.onListenedStoreChange);
	},
	onListenedStoreChange() {
		this.triggerWithFullData();
	},
	getData() {
		const sidebarState = this.getState();
		const appData = AppStore.getData();

		if (appData.urls && appData.urls.bcc) {
			sidebarState.homeHref = 'https://' + appData.urls.bcc;
		}

		return sidebarState;
	},
	initializeSidebar(opts = {}) {
		let newSidebarState = this.getState();
		if (newSidebarState.isInitialized) {
			throw new Error('Cannot initialize the sidebar more than once');
		}

		const persistedData = this.loadFromCookie(this.STORAGE_KEY);
		opts.expanded = getFirstDefined(persistedData.expanded, opts.expanded, true);

		newSidebarState = merge(newSidebarState, opts);
		newSidebarState.selections = {
			showSelectionOnly: opts.showSelectionOnly,
			productId: opts.selectedProductId || Constants.homeProductId,
			nodeDatas: [],
			deviceIds: {},
		};
		newSidebarState.isInitialized = true;
		newSidebarState.isInInitialPosition = true;
		newSidebarState.groupExpansionMap = persistedData.groupExpansionMap || {};

		newSidebarState.deviceIdsByProduct = {};

		newSidebarState.searchBoxValue = newSidebarState.searchBoxValue || '';
		newSidebarState.isSearching = !!(newSidebarState.searchBoxValue && newSidebarState.searchBoxValue.length > 0);
		newSidebarState.selections.isSearching = newSidebarState.isSearching;

		this._processInitialSelection(opts, newSidebarState);
		this._loadProductsAndDevicesFromSessionStorage(newSidebarState);

		this._data = newSidebarState;

		// If selections were passed in on init, we need to set those:
		this._hydrateDeviceSelections();

		this.triggerWithFullData();
	},
	_processInitialSelection(opts, sidebarState) {
		if (opts.selectedDeviceIds) {
			sidebarState.selectedDeviceIds = Array.isArray(opts.selectedDeviceIds)
				? opts.selectedDeviceIds
				: [opts.selectedDeviceIds];
		} else {
			sidebarState.selectedDeviceIds = [];
		}
		if (opts.selectedGroupIds) {
			sidebarState.selectedGroupIds = Array.isArray(opts.selectedGroupIds)
				? opts.selectedGroupIds
				: [opts.selectedGroupIds];
		} else {
			sidebarState.selectedGroupIds = [];
		}
		if (opts.selectedTagIds) {
			sidebarState.selectedTagIds = Array.isArray(opts.selectedTagIds) ? opts.selectedTagIds : [opts.selectedTagIds];
		} else {
			sidebarState.selectedTagIds = [];
		}
	},
	_loadProductsAndDevicesFromSessionStorage(sidebarState) {
		const cachedProducts = this.loadFromSessionStorage('products');
		const cachedTreeNodes = this.loadFromSessionStorage('treeNodesByProvider');
		if (cachedProducts && cachedTreeNodes) {
			sidebarState.products = cachedProducts;
			sidebarState.treeNodesByProvider = cachedTreeNodes;
		}
	},
	clearProducts() {
		if (this._data.isInitialized) {
			this.setProducts([]);
		}
	},
	fetchProducts(fetchSilently: boolean = false) {
		const productsDataProvider = require('plugins/bcc/ProductsProvider').BCCProductsDataProvider;

		try {
			const newSidebarState = this.getState();

			if (!fetchSilently) {
				newSidebarState.isFetchingProducts = true;
			}

			this._data = newSidebarState;
			this.triggerWithFullData();

			productsDataProvider.getData(
				(products) => {
					this.setProducts(products);
					const postFetchSidebarState = this.getState();
					postFetchSidebarState.isFetchingProducts = false;

					this._data = postFetchSidebarState;
					this.triggerWithFullData();
				},
				(e) => {
					Log.info(e, 'Got error from DataProvider in SidebarStore.fetchProducts', productsDataProvider);
					const postFetchSidebarState = this.getState();
					postFetchSidebarState.isFetchingProducts = false;
					postFetchSidebarState.error = true;
					postFetchSidebarState.errorMessage = e.message;

					this._data = postFetchSidebarState;
					this.triggerWithFullData();
				},
				null,
				{ searchBoxValue: newSidebarState.searchBoxValue },
			);
		} catch (e) {
			Log.error(e, 'Got an error from DataProvider in SidebarStore.fetchProducts', productsDataProvider);

			const newSidebarState = this.getState();
			newSidebarState.isFetchingProducts = false;
			newSidebarState.error = true;
			newSidebarState.errorMessage = e.message;

			this._data = newSidebarState;
			this.triggerWithFullData();
		}
	},
	clearDevices() {
		if (this._data.isInitialized) {
			this.setTreeNodes({});
		}
	},
	fetchDevices(fetchSilently: boolean = false, bypassCache: boolean = false) {
		const treeNodesDataProviderProvider = require('plugins/bcc/TreeNodesProvider').BCCTreeNodesDataProviderProvider;
		const { searchBoxValue } = this.getState();

		try {
			treeNodesDataProviderProvider.getData(
				(dataProviderInfos) => {
					dataProviderInfos.forEach((dataProviderInfo) => {
						// mark the affected products busy
						if (!fetchSilently) {
							this.setProductsStatus(dataProviderInfo.productIds, { busy: true });
						}

						try {
							dataProviderInfo.dataProvider.getData(
								(treeNodes) => {
									const treeNodesByProvider = this.getData().treeNodesByProvider || {};
									treeNodesByProvider[dataProviderInfo.id] = treeNodes;

									// mark the products not busy
									this.setProductsStatus(dataProviderInfo.productIds, { busy: false });

									this.setTreeNodes(treeNodesByProvider);
								},
								(e) => {
									// mark the products not busy
									this.setProductsStatus(dataProviderInfo.productIds, {
										error: true,
										errorMessage: e.message,
										busy: false,
									});
								},
								null,
								{ searchBoxValue },
								bypassCache,
							);
						} catch (e) {
							this.setProductsStatus(dataProviderInfo.productIds, {
								error: true,
								errorMessage: e.message,
								busy: false,
							});
						}
					});
				},
				(e) => {
					Log.info(e, 'Got error from DataProvider in SidebarStore.fetchDevices', treeNodesDataProviderProvider);
				},
				null,
				{ searchBoxValue },
			);
		} catch (e) {
			Log.error(e, 'Got error from DataProvider in SidebarStore.fetchDevices', treeNodesDataProviderProvider);
		}
	},
	refreshDevices() {
		this.fetchDevices(false, true);
	},
	/**
	 * Helps keep track of what products are currently being fetched.
	 * @param {string[]} productIds the productIds on which to set busy
	 * @param {Object} status looks like: { busy: ?boolean, error: ?boolean, message: ?string }
	 */
	setProductsStatus(productIds: string[] = [], status = {}) {
		const newSidebarState = this.getState();

		newSidebarState.productStatusById = newSidebarState.productStatusById || {};

		productIds.forEach((id) => {
			newSidebarState.productStatusById[id] = status;
		});

		this._data = newSidebarState;
		this.triggerWithFullData();
	},
	selectSidebarProduct(productId, srcEvent) {
		const oldSidebarState = this.getState();
		const newSidebarState = this.getState();

		newSidebarState.selections.productId = productId;
		newSidebarState.selections.groupProductData = null;
		newSidebarState.selections.nodeDatas = [];

		// User has expressed intent, so remove backing page's selection request
		newSidebarState.selectedDeviceIds = [];
		newSidebarState.selectedGroupIds = [];
		newSidebarState.selectedTagIds = [];

		newSidebarState.selections.deviceIds = this._getSelectedDeviceIds(newSidebarState);

		const willChangeEventResult = this._triggerDeviceSelectionWillChangeEvent(
			oldSidebarState,
			newSidebarState,
			srcEvent,
		);

		if (!willChangeEventResult.defaultPrevented) {
			this._data = newSidebarState;
			this.triggerWithFullData();

			this._triggerDeviceSelectionChangeEvent(oldSidebarState, newSidebarState, srcEvent);
		}
	},
	setTreeNodes(treeNodesByProvider) {
		const newSidebarState = this.getState();

		this.saveToSessionStorage('treeNodesByProvider', treeNodesByProvider);
		newSidebarState.treeNodesByProvider = treeNodesByProvider;
		newSidebarState.deviceIdsByProduct = this._generateAllDeviceIdsObject(newSidebarState);
		newSidebarState.selections.deviceIds = this._getSelectedDeviceIds(newSidebarState);
		newSidebarState.selections.groupProductData = this._getGroupProductData(newSidebarState);

		this._data = newSidebarState;
		this._hydrateDeviceSelections();

		this.triggerWithFullData();
	},
	_hydrateDeviceSelections() {
		const sidebarState = this.getState();

		let productsTree = [];

		try {
			productsTree = buildProductsTree({
				products: sidebarState.products || [],
				treeNodesByProvider: sidebarState.treeNodesByProvider || {},
				isSearching: sidebarState.isSearching || false,
				searchBoxValue: sidebarState.searchBoxValue || '',
			});
		} catch (err) {
			Log.error(err, 'buildProductsTree failed for an unknown reason.');
			return;
		}

		// short circuit if there are no nodes to read yet
		if (!productsTree.length) {
			return;
		}

		const selectedDevices = sidebarState.selectedDeviceIds.reduce(
			(accumulator, deviceId) =>
				accumulator.concat(
					findProductTreeNodes(
						deviceId,
						Constants.productChildTypes.DEVICE,
						sidebarState.selections.productId,
						productsTree,
					),
				),
			[],
		);

		// remove found items from initial selection
		selectedDevices.forEach((device) => {
			const deviceIdx = sidebarState.selectedDeviceIds.indexOf(device.id);
			if (deviceIdx !== -1) {
				sidebarState.selectedDeviceIds.splice(deviceIdx, 1);
			}
		});

		const selectedGroups = sidebarState.selectedGroupIds.reduce(
			(accumulator, groupId) =>
				accumulator.concat(
					findProductTreeNodes(
						groupId,
						Constants.productChildTypes.GROUP,
						sidebarState.selections.productId,
						productsTree,
					),
				),
			[],
		);

		// remove found items from initial selection
		selectedGroups.forEach((group) => {
			const groupIdx = sidebarState.selectedGroupIds.indexOf(group.id);
			if (groupIdx !== -1) {
				sidebarState.selectedGroupIds.splice(groupIdx, 1);
			}
		});

		const selectedTags = sidebarState.selectedTagIds.reduce(
			(accumulator, tagId) =>
				accumulator.concat(
					findProductTreeNodes(
						tagId,
						Constants.productChildTypes.GROUP,
						sidebarState.selections.productId,
						productsTree,
					),
				),
			[],
		);

		// remove found items from initial selection
		selectedTags.forEach((tag) => {
			const tagIdx = sidebarState.selectedTagIds.indexOf(tag.id);
			if (tagIdx !== -1) {
				sidebarState.selectedTagIds.splice(tagIdx, 1);
			}
		});

		const newSelections = selectedDevices.concat(selectedGroups).concat(selectedTags);
		sidebarState.selections.nodeDatas = sidebarState.selections.nodeDatas.concat(newSelections);
		this._data = sidebarState;
		// Don't trigger, let the caller trigger
	},
	_generateAllDeviceIdsObject(sidebarState) {
		const productMap = {};

		Object.values(sidebarState.treeNodesByProvider).forEach((nodeArray) => {
			nodeArray.forEach((node) => {
				if (!node.itemType || node.itemType === Constants.productChildTypes.DEVICE) {
					const operatingProductId = node.groupProductId || node.productId;
					productMap[operatingProductId] = productMap[operatingProductId] || [];
					productMap[operatingProductId].push(node.id);
				}
			});
		});

		Object.keys(productMap).forEach((productId) => {
			productMap[productId] = uniq(productMap[productId]);
		});

		return productMap;
	},
	_getSelectedDeviceIds(sidebarState) {
		let selectedProductId;
		if (sidebarState.selections.groupProductData) {
			selectedProductId = sidebarState.selections.groupProductData.id;
		} else {
			selectedProductId = sidebarState.selections.productId;
		}
		if (!selectedProductId || selectedProductId === Constants.homeProductId) {
			return sidebarState.deviceIdsByProduct;
		}
		const selectedDeviceIds = {};
		if (!sidebarState.selections.nodeDatas.length) {
			// If there is a product, but no devices selected, use all the product's devices
			selectedDeviceIds[selectedProductId] = sidebarState.deviceIdsByProduct[selectedProductId];
		} else {
			// Else use the ids of the devices that are selected
			const explicitlySelectedDeviceIds = [];
			const selectedDevicesByGroupProduct = {};

			sidebarState.selections.nodeDatas.forEach((nodeData) => {
				if (nodeData.itemType === Constants.productChildTypes.DEVICE) {
					explicitlySelectedDeviceIds.push(nodeData.id);
				} else if (nodeData.itemType === Constants.productChildTypes.GROUP) {
					let deviceIdListToUse = explicitlySelectedDeviceIds;

					// If the group is a "group product", then group the devices there instead
					if (nodeData.groupProductId) {
						selectedDevicesByGroupProduct[nodeData.groupProductId] = [];
						deviceIdListToUse = selectedDevicesByGroupProduct[nodeData.groupProductId];
					}

					nodeData.childItems.forEach((groupChildItem) => {
						deviceIdListToUse.push(groupChildItem.id);
					});
				}
			});

			selectedDeviceIds[selectedProductId] = explicitlySelectedDeviceIds;
			Object.keys(selectedDevicesByGroupProduct).forEach((groupProductId) => {
				selectedDeviceIds[groupProductId] = selectedDevicesByGroupProduct[groupProductId];
			});
		}
		return selectedDeviceIds;
	},
	setProducts(products) {
		const newSidebarState = this.getState();

		this.saveToSessionStorage('products', products);
		newSidebarState.products = products;

		this._data = newSidebarState;
		this._hydrateDeviceSelections();

		this.triggerWithFullData();
	},
	selectSidebarNode(nodeData, srcEvent) {
		const oldSidebarState = this.getState();
		const newSidebarState = this.getState();

		// Reset the current selection to just what was provided
		newSidebarState.selections.productId = nodeData.productId;
		newSidebarState.selections.nodeDatas = [nodeData];
		newSidebarState.selections.groupProductData = this._getGroupProductData(newSidebarState);

		// User has expressed intent, so remove backing page's selection request
		newSidebarState.selectedDeviceIds = [];
		newSidebarState.selectedGroupIds = [];

		newSidebarState.selections.deviceIds = this._getSelectedDeviceIds(newSidebarState);

		const willChangeEventResult = this._triggerDeviceSelectionWillChangeEvent(
			oldSidebarState,
			newSidebarState,
			srcEvent,
		);

		if (!willChangeEventResult.defaultPrevented) {
			this._data = newSidebarState;
			this.triggerWithFullData();

			this._triggerDeviceSelectionChangeEvent(oldSidebarState, newSidebarState, srcEvent);
		}
	},
	/**
	 * Looks in the selections to try to find a consistent groupProduct among
	 * selected nodes.
	 * @param {Object} sidebarState  The sidebar state object to look in
	 * @returns {Object}  An object { id, title, node } of the groupProduct agreed
	 *                    on by selected nodes, or null if none is agreed on
	 */
	_getGroupProductData(sidebarState) {
		const selectedNodeDatas = sidebarState.selections.nodeDatas;
		let groupProductId = null;

		for (let i = 0; i < selectedNodeDatas.length; i++) {
			const node = selectedNodeDatas[i];

			if (groupProductId && groupProductId !== node.groupProductId) {
				return null; // Multiple different groupProductIds, so none
			}
			if (node.groupProductId) {
				groupProductId = node.groupProductId;
			}
		}

		if (!groupProductId) {
			return null;
		}

		const groupProductNode = this._getGroupProductNode(groupProductId, sidebarState);

		if (!groupProductNode) {
			Log.error('HUI: SidebarStore failed to find groupProductNode for groupProductId', groupProductId);
		}

		return {
			id: groupProductId,
			title: (groupProductNode && groupProductNode.title) || '',
			node: groupProductNode,
		};
	},
	_getGroupProductNode(groupProductId, sidebarState) {
		let productsTree;
		try {
			productsTree = buildProductsTree({
				products: sidebarState.products,
				treeNodesByProvider: sidebarState.treeNodesByProvider,
			});
		} catch (e) {
			// Missing either products or treeNodes
			return null;
		}

		const foundGroupNodes = findProductTreeNodes(
			groupProductId,
			Constants.productChildTypes.GROUP,
			sidebarState.selections.productId,
			productsTree,
		);
		if (foundGroupNodes.length === 1) {
			return foundGroupNodes[0];
		}

		return null;
	},
	_isNodeToggleable(togglingNodeData, sidebarState) {
		let requestedToggleIsChildOfASelectedGroup = false;

		sidebarState.selections.nodeDatas.forEach((selectedNodeData) => {
			// If the toggled item is the child of a current selection, ignore the toggle
			if (togglingNodeData.path.isDescendantOf(selectedNodeData.path)) {
				requestedToggleIsChildOfASelectedGroup = true;
			}
		});

		if (requestedToggleIsChildOfASelectedGroup) {
			return false;
		}

		// We need to block multi-select across group products.
		// Both group products and group product children have groupProductId
		if (!!togglingNodeData.groupProductId && sidebarState.selections.nodeDatas) {
			// We only need to pick the first one since in a group product,
			// the groupProductIds will all be the same.
			const selectedGroupProduct = sidebarState.selections.nodeDatas[0].groupProductId;
			const differentGroupProducts = selectedGroupProduct !== togglingNodeData.groupProductId;

			if (differentGroupProducts) {
				return false;
			}
		}

		return true;
	},
	toggleSidebarNodeSelection(togglingNodeData, srcEvent) {
		const oldSidebarState = this.getState();
		const newSidebarState = this.getState();
		const postRemovalNodes = [];

		if (!this._isNodeToggleable(togglingNodeData, oldSidebarState)) return;

		newSidebarState.selections.nodeDatas.forEach((selectedNodeData) => {
			// Try to remove the toggled item from the selection without altering others
			if (
				!selectedNodeData.path.equals(togglingNodeData.path) ||
				selectedNodeData.itemType !== togglingNodeData.itemType
			) {
				postRemovalNodes.push(selectedNodeData);
			}
		});

		// If we didn't remove the node, then add it
		if (postRemovalNodes.length === newSidebarState.selections.nodeDatas.length) {
			newSidebarState.selections.nodeDatas.push(togglingNodeData);
			newSidebarState.selections.productId = togglingNodeData.productId;
		} else {
			// If we did, set the removed set as the new selection datas
			newSidebarState.selections.nodeDatas = postRemovalNodes;
		}

		// Regardless of add or remove, if a group was toggled, deselect all children
		if (togglingNodeData.itemType === Constants.productChildTypes.GROUP) {
			newSidebarState.selections.nodeDatas = newSidebarState.selections.nodeDatas.filter((nodeData) => {
				return !nodeData.path.isDescendantOf(togglingNodeData.path);
			});
		}

		// User has expressed intent, so remove backing page's selection request
		newSidebarState.selectedDeviceIds = [];
		newSidebarState.selectedGroupIds = [];

		newSidebarState.selections.deviceIds = this._getSelectedDeviceIds(newSidebarState);
		newSidebarState.selections.groupProductData = this._getGroupProductData(newSidebarState);

		const willChangeEventResult = this._triggerDeviceSelectionWillChangeEvent(
			oldSidebarState,
			newSidebarState,
			srcEvent,
		);

		if (!willChangeEventResult.defaultPrevented) {
			this._data = newSidebarState;
			this.triggerWithFullData();

			this._triggerDeviceSelectionChangeEvent(oldSidebarState, newSidebarState, srcEvent);
		}
	},
	toggleSidebarGroupNodeExpansion(groupPath, isExpanded) {
		const newSidebarState = this.getState();

		newSidebarState.groupExpansionMap[groupPath.toString()] = isExpanded;
		const localData = this.loadFromCookie(this.STORAGE_KEY);
		localData.groupExpansionMap = newSidebarState.groupExpansionMap;
		this.saveToCookie(this.STORAGE_KEY, localData);

		this._data = newSidebarState;
		this.triggerWithFullData();
	},
	changeSidebarVisibility(expandSidebar) {
		const newSidebarState = this.getState();

		newSidebarState.expanded = expandSidebar;
		const localData = this.loadFromCookie(this.STORAGE_KEY);
		localData.expanded = expandSidebar;
		this.saveToCookie(this.STORAGE_KEY, localData);
		newSidebarState.isInInitialPosition = false;

		this._data = newSidebarState;
		this.triggerWithFullData();

		Events.trigger('sidebarVisibilityChange', {
			expanded: newSidebarState.expanded,
		});
	},
	changeSidebarVisibilityMobile(expandSidebar) {
		const newSidebarState = this.getState();

		newSidebarState.expandedMobile = expandSidebar;
		newSidebarState.isInInitialPosition = false;

		this._data = newSidebarState;
		this.triggerWithFullData();

		Events.trigger('sidebarVisibilityChange', {
			expandedMobile: newSidebarState.expandedMobile,
		});
	},
	setProductSearch(searchBoxValue) {
		const newSidebarState = this.getState();

		newSidebarState.searchBoxValue = searchBoxValue;
		newSidebarState.isSearching = searchBoxValue.length > 0;
		newSidebarState.selections.isSearching = newSidebarState.isSearching;

		this._data = newSidebarState;
		this.triggerWithFullData();

		// this forces a refresh of device and product urls, which may reference tools.searchBoxValue
		// functional opposite of refreshDevices(), in that we are silent and prefer the cache
		this.fetchDevices(true);
		this.fetchProducts(true);
	},
	setShowSelectionOnly(showSelectionOnly) {
		const newSidebarState = this.getState();

		newSidebarState.showSelectionOnly = showSelectionOnly;
		newSidebarState.selections.showSelectionOnly = showSelectionOnly;

		this._data = newSidebarState;
		this.triggerWithFullData();
	},
	_mapInternalSelectionToRawSelection(sidebarState) {
		const rawDeviceDataForSelection = [];
		const rawGroupDataForSelection = [];

		sidebarState.selections.nodeDatas.forEach((nodeData) => {
			if (nodeData.itemType === Constants.productChildTypes.DEVICE) {
				rawDeviceDataForSelection.push(nodeData.rawApiData);
			}
			if (nodeData.itemType === Constants.productChildTypes.GROUP) {
				rawGroupDataForSelection.push(nodeData.rawApiData);

				nodeData.childItems.forEach((groupChildItem) => {
					if (groupChildItem.itemType === Constants.productChildTypes.DEVICE) {
						rawDeviceDataForSelection.push(groupChildItem.rawApiData);
					}
				});
			}
		});

		return { devices: rawDeviceDataForSelection, groups: rawGroupDataForSelection };
	},
	_triggerDeviceSelectionWillChangeEvent(oldState, newState, srcEvent) {
		const oldRawSelection = this._mapInternalSelectionToRawSelection(oldState);
		const newRawSelection = this._mapInternalSelectionToRawSelection(newState);

		// return so we can tell if it was cancelled
		return Events.trigger(
			'deviceSelectionWillChange',
			{
				prevSelections: oldRawSelection.devices,
				prevGroupSelections: oldRawSelection.groups,
				prevProductId: oldState.selections.productId,
				nextSelections: newRawSelection.devices,
				nextGroupSelections: newRawSelection.groups,
				nextProductId: newState.selections.productId,
				srcEvent: srcEvent ? srcEvent.nativeEvent : srcEvent,
			},
			true,
		);
	},
	_triggerDeviceSelectionChangeEvent(oldState, newState, srcEvent) {
		const oldRawSelection = this._mapInternalSelectionToRawSelection(oldState);
		const newRawSelection = this._mapInternalSelectionToRawSelection(newState);
		Events.trigger('deviceSelectionChange', {
			prevSelections: oldRawSelection.devices,
			prevGroupSelections: oldRawSelection.groups,
			prevProductId: oldState.selections.productId,
			nextSelections: newRawSelection.devices,
			nextGroupSelections: newRawSelection.groups,
			nextProductId: newState.selections.productId,
			srcEvent: srcEvent ? srcEvent.nativeEvent : srcEvent,
		});
	},
});

export default SidebarStore;
