import CardWrapper from '../CardWrapper';
import GraphCard from './GraphCard';

/**
 * Class representing a dashboard card.
 */
export default class GraphCardWrapper extends CardWrapper {

	/**
	 * Creates a GraphCard
	 * @param {Object} opts  see CardWrapper opts
	 */
	constructor(opts) {
		super(opts, GraphCard);
	}
}
