import Reflux from 'reflux';
import Actions from '../classes/Actions';
import AppStore from './AppStore';
import merge from 'lodash/merge';

// Brings in getState() and _data
import StoreMixins from '../stores/StoreMixins';

const HeaderStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	init() {
		this.listenTo(AppStore, this.onListenedStoreChange);
	},
	getData() {
		const headerData = this.getState();
		const appData = AppStore.getData();

		headerData.auth = appData.auth;
		headerData.urls = appData.urls;

		return headerData;
	},
	onListenedStoreChange() {
		this.triggerWithFullData();
	},
	initializeHeader(opts: ?Object) {
		let newHeaderState = this.getState();
		if (newHeaderState.isInitialized) {
			throw new Error('Cannot initialize the header more than once');
		}

		newHeaderState = merge(newHeaderState, opts);
		newHeaderState.isInitialized = true;

		this._data = newHeaderState;
		this.triggerWithFullData();
	},
});

export default HeaderStore;
