import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Popover } from './Popover';
import { Overlay } from './Overlay';

/**
 * OverlayToggle
 *
 * A component similar to the OverlayTrigger that wraps (child) elements and makes an Overlay of a certain sort
 * show/hide when they are clicked. Unlike the OverlayTrigger, this passes a "closeParentOverlay" prop to any
 * elements in the overlay, a function which can be run to close the overlay from within it.
 */
class OverlayToggle extends Component {
	state = { show: false }

	/**
	 * Constructor.
	 * @param {object} props   Initial props.
	 * @param {object} context Initial context.
	 */
	constructor(props, context) {
		super(props, context);

		this.onChildClick = this.onChildClick.bind(this);
		this.setOverlayVisibility = this.setOverlayVisibility.bind(this);
		this.hideOverlay = this.hideOverlay.bind(this);
		this.findTarget = this.findTarget.bind(this);
	}

	componentWillMount() {
		this.randomID = Math.random().toString(32).slice(2);
	}

	/**
	 * Hide or show the overlay, depending on the parameter.
	 * @param {boolean} show Whether to show or hide the overlay.
	 */
	setOverlayVisibility(show) {
		this.setState(() => ({ ...this.state, show }));
	}

	/**
	 * Hide the overlay.
	 */
	hideOverlay() {
		this.setOverlayVisibility(false);
		if (typeof this.props.onHide === 'function') {
			this.props.onHide();
		}
	}

	/**
	 * Click handler for child elements (the "toggle" elements).
	 */
	onChildClick(e) {
		e.preventDefault();
		this.setOverlayVisibility(this.props.showOnly ? true : !this.state.show);
	}

	/**
	 * Get the current DOM node of the OverlayToggle. Used by the Overlay component to position the overlay.
	 * @returns {HTMLElement} DOM node of the OverlayToggle "wrapper" element.
	 */
	findTarget() {
		return ReactDOM.findDOMNode(this.refs.root);
	}

	/**
	 * React rendering function.
	 * @returns {object} JSX object representing the toggle "wrapper".
	 */
	render() {
		const OverlayComponent = this.props.overlayComponent;
		const overlayComponentProps = this.props.overlayComponentProps;
		const WrapComponent = this.props.wrapComponent;
		const wrapComponentProps = this.props.wrapComponentProps;
		const toggles = React.Children.map(this.props.children, (c, idx) =>
			React.cloneElement(c, {
				onClick: this.onChildClick,
				key: `overlay-toggle-child-${idx}`,
			})
		);

		const canSetOverlayContentProps = this.props.overlayContents.type && (typeof this.props.overlayContents.type !== 'string');
		const overlayContents = React.cloneElement(
			this.props.overlayContents,
			canSetOverlayContentProps ?
			{
				closeParentOverlay: this.hideOverlay,
			} :
			{}
		);


		return (
			<WrapComponent
				className="hui-overlay-toggle"
				ref="root"
				{...wrapComponentProps}
			>
				{toggles}
				<Overlay
					onHide={this.hideOverlay}
					show={this.state.show}
					target={this.findTarget}
					rootClose={this.props.rootClose}
					placement={this.props.placement}
				>
					<OverlayComponent id={`overlayToggleComponent-${this.randomID}`} {...overlayComponentProps}>
						{overlayContents}
					</OverlayComponent>
				</Overlay>
			</WrapComponent>
		);
	}
}

OverlayToggle.propTypes = {
	children: PropTypes.node.isRequired,

	// Component and props for the overlay itself.
	overlayComponent: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.instanceOf(Component),
	]),
	overlayComponentProps: PropTypes.object,

	// Component and props for the wrapper of the trigger component.
	wrapComponent: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.instanceOf(Component),
	]),
	wrapComponentProps: PropTypes.object,

	// Contents of the overlay.
	overlayContents: PropTypes.element.isRequired,

	// Whether or not clicking off the overlay will close it.
	rootClose: PropTypes.bool,

	// If true, clicking the element will not hide a shown overlay.
	// This is effectively overridden if "rootClose" is set, since the element is "off the overlay".
	showOnly: PropTypes.bool,

	// Placement of the tooltip relative to the wrapper object.
	placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

	// Optional onHide handler run when the overlay hides.
	onHide: PropTypes.func,
};

OverlayToggle.defaultProps = {
	overlayComponent: Popover,
	overlayComponentProps: {},
	wrapComponent: 'span',
	wrapComponentProps: {},
};

export default OverlayToggle;
export { OverlayToggle };
