import PropTypes from 'prop-types';
import React from 'react';

/**
 * Class representing the React class for card titles
 */
export default class DashboardDeviceInfo extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		serial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		ip: PropTypes.string,
	};

	/**
	 * Render the core DashboardDeviceInfo React instance.
	 * @return {jsx} The DashboardDeviceInfo React instance
	 */
	render() {
		const controls = [];

		if (this.props.model) {
			controls.push(
				<span key="model" className="hui-dashboard-device-info-item hui-dashboard-device-info-model">
					<b>{this.props.model}</b>
				</span>
			);
		}

		if (this.props.serial) {
			controls.push(
				<span key="serial" className="hui-dashboard-device-info-item hui-dashboard-device-info-serial">
					<b>Serial Number:</b> {this.props.serial}
				</span>
			);
		}

		if (this.props.ip) {
			controls.push(
				<span key="ip" className="hui-dashboard-device-info-item hui-dashboard-device-info-ip">
					<b>IP:</b> {this.props.ip}
				</span>
			);
		}

		return (
			<div className="hui-dashboard-device-info">{controls}</div>
		);
	}

}
