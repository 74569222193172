import Log from './Log';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

// global event bus
const listenersByEventName = {
	sidebarVisibilityChange: [],
	pageLayoutReflow: [],
	navbarSelectionChange: [],
	deviceSelectionWillChange: [],
	deviceSelectionChange: [],
	accountSelectionChange: [],
	sidebarMounted: [],
	headerMounted: [],
	// never actually triggered outside of unit tests
	_testEvent: [],
	// internally used
	globalResize: [],
	globalScroll: [],
};

/**
 * Adds a listener to the given HUI event
 * @param {string}   eventName The name of the event
 * @param {Function} callback  The function to be called when the event triggers
 * @param {boolean}   internal Whether the event handler should survive a simple .clear()
 */
function addHuiEventListener(eventName, callback, internal: ?boolean = false) {
	if (!listenersByEventName[eventName]) {
		return;
	}

	// mark this so .clear(false) doesn't remove it
	callback._internal = internal;

	listenersByEventName[eventName].push(callback);
}

/**
 * Removes a listener from the given HUI event
 * @param {string}   eventName The name of the event
 * @param {Function} callback  The function removed as an event handler
 */
function removeHuiEventListener(eventName, callback) {
	const listeners = listenersByEventName[eventName];
	if (!listeners) {
		return;
	}

	const listenerIdx = listeners.indexOf(callback);

	if (listenerIdx !== -1) {
		listeners.splice(listenerIdx, 1);
	}
}

/**
 * Listens to an event once, then removes the listener.
 * @param {string}   eventName The name of the event
 * @param {Function} callback  The function to be called when the event triggers
 */
function listenToHuiEventOnce(eventName, callback) {
	const oneTimeCallback = (...args) => {
		// Run the original function
		callback(...args);
		// Run removal code of the listener.
		removeHuiEventListener(eventName, oneTimeCallback);
	};

	addHuiEventListener(eventName, oneTimeCallback);
}

/**
 * Trigger a HUI event
 * @param   {string} eventName The name of the event to trigger
 * @param   {Object} data      Data to pass to the event listeners
 * @param   {boolean} preventable Whether the event can be prevented
 * @return  {Object} an object with return information
 * // TODO: provide dev-users with a more accessible reference for available events
 */
function triggerHuiEvent(eventName, data, preventable: ?boolean = false) {
	const listeners = listenersByEventName[eventName];
	const result = { defaultPrevented: false };

	if (listeners) {
		if (preventable) {
			data.preventDefault = () => {
				result.defaultPrevented = true;
			};
		}

		listeners.forEach((listener) => {
			if (typeof listener === 'function') {
				try {
					listener(data);
				} catch (e) {
					Log.error(`HUI: Error in ${eventName} handler:`, e);
				}
			}
		});
	}

	return result;
}

/**
 * Removes all listeners from all HUI events
 * @param {boolean}   includeInternal whether to clear all handlers
 */
function removeAllHuiEventListeners(includeInternal: ?boolean = false) {
	Object.entries(listenersByEventName).forEach(([eventName, callbacks]) => {
		listenersByEventName[eventName] = callbacks.filter((callback) => {
			return callback._internal && !includeInternal;
		});
	});
}

/**
 * Sets up global event handlers
 */
function bindGlobalEventProxies() {
	if (global.addEventListener) {
		global.addEventListener(
			'resize',
			debounce((data) => {
				global.requestAnimationFrame(() => {
					triggerHuiEvent('globalResize', data);
				});
			}, 100),
		);
		global.addEventListener(
			'scroll',
			throttle((data) => {
				global.requestAnimationFrame(() => {
					triggerHuiEvent('globalScroll', data);
				});
			}, 10),
		);
	}
}

export default {
	init: bindGlobalEventProxies,
	addEventListener: addHuiEventListener,
	removeEventListener: removeHuiEventListener,
	listenToOnce: listenToHuiEventOnce,
	trigger: triggerHuiEvent,
	clear: removeAllHuiEventListeners,
};
