import PropTypes from 'prop-types';
import React from 'react';
import Actions from '../../classes/Actions';
import DashboardDeviceInfo from './DashboardDeviceInfo';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import toPairs from 'lodash/toPairs';
import { LocalizedString } from 'js/lib/i18n';
import {
	LAYOUT_NO_SCALING,
	LAYOUT_SHRINK_TO_FIT,
	LAYOUT_GROW_TO_FIT,
	LAYOUT_ALWAYS_FIT,
	FILTER_TIME_24_HOURS,
	FILTER_TIME_7_DAYS,
	FILTER_TIME_30_DAYS,
	DASHBOARD_AUTOMATIC_REFRESH,
} from 'js/lib/lids';

/**
 * Class representing the react class for the dashboard control bar.
 */
export default class DashboardControlBar extends React.Component {
	static propTypes = {
		id: PropTypes.string,
		filterRange: PropTypes.string,
		autoRefresh: PropTypes.bool,
		scaling: PropTypes.string,
		sidebarId: PropTypes.string,
		selections: PropTypes.object,
		productsTree: PropTypes.array,
		hideControls: PropTypes.bool,
	};

	static defaultProps = {
		productsTree: [],
		scaling: '',
		hideControls: false,
	};

	/**
	 * Called when the filterRange is changed.
	 * @param  {event}  e        event object
	 * @param  {string} eventKey day / week / month
	 */
	handleFilterRangeChange(e, eventKey) {
		Actions.setDashboardFilterRange(this.props.id, eventKey);
	}

	/**
	 * Called when the autorefresh checkbox is toggled.
	 * @param  {event} e event object
	 */
	handleAutoRefreshChange(e) {
		Actions.setDashboardAutoRefresh(this.props.id, e.target.checked);
	}

	/**
	 * Called when the autorefresh checkbox is toggled.
	 * @param  {event} e event object
	 * @param  {string} eventKey the scaling value
	 */
	handleScalingChange(e, eventKey) {
		Actions.setDashboardScaling(this.props.id, eventKey);
	}

	/**
	 * Finds the device that is selected
	 * @returns {object} device The device we were looking for
	 */
	getSelectedDevice() {
		let specificDevice;
		let selectedId;

		if (this.props.selections.nodeDatas && this.props.selections.nodeDatas.length === 1) {
			selectedId = this.props.selections.nodeDatas[0].id;
		}

		if (selectedId) {
			this.props.productsTree.forEach((category) => {
				category.products.forEach((product) => {
					product.childItems.forEach((productChild) => {
						if (productChild.itemType === 'group') {
							productChild.childItems.forEach((device) => {
								if (device.id === selectedId) {
									specificDevice = device;
								}
							});
						} else {
							if (productChild.id === selectedId) {
								specificDevice = productChild;
							}
						}
					});
				});
			});
		}

		return specificDevice;
	}

	/**
	 * Gets a object of the scaling values, key:displayName
	 * @returns {object} The map of scaling values
	 */
	getScalingMenuOptions() {
		const scalingMap = {
			['']: LAYOUT_NO_SCALING,
			shrink: LAYOUT_SHRINK_TO_FIT,
			grow: LAYOUT_GROW_TO_FIT,
			all: LAYOUT_ALWAYS_FIT,
		};

		return {
			scalingMenuItems: Object.keys(scalingMap).map((key) => (
				<MenuItem key={key} eventKey={key}>
					<LocalizedString lid={scalingMap[key]} />
				</MenuItem>
			)),
			selectedScalingTitle: <LocalizedString lid={scalingMap[this.props.scaling]} />,
		};
	}

	/**
	 * Renders the DashboardControlBar.
	 *
	 * @return {jsx} the view
	 */
	render() {
		const device = this.getSelectedDevice();
		const deviceModel =
			device && device.rawApiData && device.rawApiData.attributes ? device.rawApiData.attributes.model : null;
		const deviceSerial = device ? device.serial : null;
		const deviceIp = device && device.local_ip !== '0.0.0.0' ? device.local_ip : null;

		const { scalingMenuItems, selectedScalingTitle } = this.getScalingMenuOptions();
		const filterRangeOptions = {
			day: FILTER_TIME_24_HOURS,
			week: FILTER_TIME_7_DAYS,
			month: FILTER_TIME_30_DAYS,
		};
		const selectedFilterRangeText = <LocalizedString lid={filterRangeOptions[this.props.filterRange]} />;

		return (
			<div className="hui-dashboard-controlbar">
				{!this.props.hideControls ? (
					<div className="hui-dashboard-controlbar-left">
						<DropdownButton
							id="hui-filterRange-dropdown"
							bsSize="xsmall"
							title={selectedFilterRangeText}
							onSelect={this.handleFilterRangeChange.bind(this)}
						>
							{toPairs(filterRangeOptions).map(([key, value]) => (
								<MenuItem active={key === this.props.filterRange} key={key} eventKey={key}>
									<LocalizedString lid={value} />
								</MenuItem>
							))}
						</DropdownButton>{' '}
						<label>
							<DropdownButton
								id="hui-dashboard-scaling-dropdown"
								title={selectedScalingTitle}
								value={this.props.scaling}
								onSelect={this.handleScalingChange.bind(this)}
								bsSize="xsmall"
								pullRight
							>
								{scalingMenuItems}
							</DropdownButton>
						</label>{' '}
						<label>
							<input
								type="checkbox"
								checked={this.props.autoRefresh}
								onChange={this.handleAutoRefreshChange.bind(this)}
							/>
							<span>
								<LocalizedString lid={DASHBOARD_AUTOMATIC_REFRESH} />
							</span>
						</label>
					</div>
				) : (
					<div className="hui-dashboard-controlbar-left" />
				)}
				<div className="hui-dashboard-controlbar-right">
					<DashboardDeviceInfo model={deviceModel} serial={deviceSerial} ip={deviceIp} />
				</div>
			</div>
		);
	}
}
