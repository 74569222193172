import axios from 'axios';

const AjaxCache = new Map();
const CACHE_TIMEOUT = 30000; // 30 secs

/**
 * A helper function that wraps a nanoajax call in a promise and parses nanoajax's return
 * @param   {object} opts  An object with options to pass to nanoajax.ajax
 * @returns {Promise}      A Promise that resolves based on nanoajax's success or failure
 */
export default function makeAjaxCall(opts) {
	return axios.request({
		// code written against nanoajax may have set 'body'
		data: opts.body,

		// default timeout of 30 seconds for all requests
		// TODO: make a soft timeout at 10 seconds so a 'sorry, still
		//       loading' message can be shown
		timeout: 30000,

		// for cors
		withCredentials: true,

		// code written against nanoajax expects an unprocessed response body
		transformResponse: (data) => data,

		// pass any other options through (incl overrides of the above)
		...opts,
	}).then(response => {

		// return data in the format/order nanoajax users expect
		// which is an array of [status, data]
		// the request object and full response are also tehn provided for helpfulness
		return [response.status, response.data, response.request, response];
	});
}

export function makeCachedAjaxCall(opts: Object) {
	const keyHash = JSON.stringify(opts);

	let promise;

		// check ajax promise cache
	if (AjaxCache.has(keyHash) && !this.ajax.bypassCache) {
		promise = AjaxCache.get(keyHash);

	} else {
		promise = makeAjaxCall(opts);

		AjaxCache.set(keyHash, promise);

		// delete from cache after CACHE_TIMEOUT ms
		setTimeout(() => AjaxCache.delete(keyHash), CACHE_TIMEOUT);
	}

	return promise;
}
