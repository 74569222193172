import MessagesStore from 'js/lib/stores/MessagesStore';
import humanizeDuration from 'humanize-duration';
import merge from 'lodash/merge';

/** humanizeDuration
 *
 * moment doesn't let us format duractions, so we use humanizeDuration
 * we also set some defaults, which can be overridden at call time
 * and we also need to multiply because this takes milliseconds and we usually have secs
 * you can set multiplier: 0 to return to normal hD behavior (ms)
 *
 * @param {int} secs
 * @param {object} opts
 * @returns {string} formatted duration
 */
export default function humanizeDurationLocalized(secs, opts = { multiplier: 1000 }) {
	let language;

	// try locale
	if (humanizeDuration.getSupportedLanguages().indexOf(MessagesStore.getLocale()) !== -1) {
		language = MessagesStore.getLocale();

		// try short version
	} else if (humanizeDuration.getSupportedLanguages().indexOf(MessagesStore.getLocale(true)) !== -1) {
		language = MessagesStore.getLocale(true);
	}

	return humanizeDuration.humanizer(
		merge(opts, {
			language: language || 'en',
			units: 'dhms',
		}),
	)(secs * opts.multiplier);
}
