import React from 'react';
import BaseWrapper from '../BaseWrapper';
import PanelGroup from './PanelGroup';
import PanelStore from '../../stores/PanelStore';
import actions from '../../classes/Actions';
import ControllerView from '../ControllerView';

/**
 * Class representing a dashboard panel group.
 */
export default class PanelGroupWrapper extends BaseWrapper {


	cards = [];

	/**
	 * Create a dashboard panel group.
	 *
	 * @param {object} opts Options of the format
	 *      {
	 *          id: a unique identifier for the panel
	 *          title: a string containing the title of the panel
	 *          name: a string containing the panel's unique id ??
	 *          container: (optional) an HtmlElement or unique CSS selector for the element's root
	 *              use only to render directly
	 *          options: an object of behavior customization options
	 *          panels: a list of Card objects
	 *      }
	 * @constructor
	 */
	constructor(opts) {
		const myPanels = opts.panels || [];
		super(opts, PanelGroup);

		// this._validateOptions(opts);

		// Set a parent reference on each CardWrapper
		myPanels.forEach(panel => {
			panel.parent = this;
		});

		// then replace the CardWrapper array with a panel id array
		this.reactProps.panels = myPanels.map(panel => panel.id);

		actions.createPanelGroup(this.reactProps);
	}

	/**
	 * Adds a panel to this panelgroup
	 * @param {PanelWrapper} panelWrapper - The panel to add
	 */
	addPanel(panelWrapper) {
		this.addPanels([panelWrapper]);
	}

	/**
	 * Adds panels to this dashboard
	 * @param {PanelWrapper[]} panelWrappers - An array of panels to add
	 */
	addPanels(panelWrappers) {
		if (!Array.isArray(panelWrappers) /* TODO: or it's not full of panels */) {
			// TODO: error
			return;
		}

		this.panels = this.panels.concat(panelWrappers);
		panelWrappers.forEach(panel => {
			panel.parent = this;
		});
		actions.addPanelsToPanelGroup(panelWrappers.map(panel => panel.id), this.id);
	}

	/**
	 * Instantiates the React component for direct ReactDOM rendering (ie no parent).
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		const panelId = this.id;

		/**
		 * Finds the component's data from the full store data
		 * @param   {Object} panelStoreData  The panelStore's full data
		 * @return {Object}  The panel component's data
		 */
		function getComponentDataFromStoreData(panelStoreData) {
			return panelStoreData[panelId];
		}

		return (
			<ControllerView
				transform={getComponentDataFromStoreData}
				component={PanelGroup}
				store={PanelStore}
			/>
		);
	}
}
