const DEFAULT_SEPARATOR = '::';

/**
 * This class helps standardize how pathing works within HUI.
 */
export default class HuiPath {

	/**
	 * New HuiPath object
	 * @param {string|Array} path  The path
	 * @param {string} separator   The separator for string-formating/parsing
	 */
	constructor(path:string|Array, separator:?string) {
		this.setPath(path, separator);
	}

	/**
	 * Gets the value at a particular index
	 * @param {number} index The index to get the value at
	 * @return {*}           The path value at the given index.
	 */
	get(index:number) {
		return this.pathArray[index] || false;
	}

	/**
	 * Returns the length of the path array
	 * @return {Number} The length of the path array
	 */
	length() {
		return this.pathArray.length;
	}

	/**
	 * Sets the path to a string
	 * @param {string|Array} path  The path
	 * @param {string} separator   The separator for string-formating/parsing
	 *                             if path is a string
	 */
	setPath(path:string|Array, separator:?string = DEFAULT_SEPARATOR) {
		if (typeof path === 'string') {
			this.pathArray = path.split(separator);
		} else if (Array.isArray(path)) {
			this.pathArray = path.slice();
		} else {
			throw new Error('Cannot make a HuiPath from anything but a string or array');
		}
	}

	/**
	 * Makes a new path from the current path with the given child identifier
	 * @param   {string} childPathId  A string identifier for the new part of the path
	 * @returns {HuiPath}             A new path including the child
	 */
	makeChildPath(childPathId:string) {
		if (typeof childPathId !== 'string') {
			throw new Error('Cannot make a child HuiPath from anything but a string');
		}
		const newPath = this.pathArray.slice();
		newPath.push(childPathId);
		return new HuiPath(newPath);
	}

	/**
	 * Checks for path equality with another HuiPath
	 * @param   {HuiPath} otherPath  The path to compare to
	 * @returns {boolean}            Whether the paths are the same
	 */
	equals(otherPath) {
		if (otherPath === undefined) {
			return false;
		} else if (!(otherPath instanceof HuiPath)) {
			throw new Error('Can only compare with another HuiPath');
		}
		const sameLength = this.pathArray.length === otherPath.pathArray.length;
		const sameElements = this.pathArray.every((val, idx) => val === otherPath.pathArray[idx]);
		return sameLength && sameElements;
	}

	/**
	 * Checks that a path starts with another HuiPath
	 * @param   {HuiPath} otherPath  The path to compare to
	 * @returns {boolean}            Whether this path starts with the otherPath
	 */
	startsWith(otherPath) {
		if (otherPath === undefined) {
			return false;
		} else if (!(otherPath instanceof HuiPath)) {
			throw new Error('Can only compare with another HuiPath');
		}
		return otherPath.pathArray.every((val, idx) => val === this.pathArray[idx]);
	}

	/**
	 * Checks that a path starts with another HuiPath, but is not that path
	 * @param   {HuiPath} otherPath  The path to compare to
	 * @returns {boolean}            Whether this path is a descendant of the otherPath
	 */
	isDescendantOf(otherPath) {
		return this.startsWith(otherPath) && !this.equals(otherPath);
	}

	/**
	* Returns the last identifier in the path
	* @returns {string}   The last identifier in the path
	*/
	last() {
		return this.pathArray[this.pathArray.length - 1];
	}

	/**
	 * Serializes a HuiPath
	 * @param   {string} separator  The separator to use when serializing
	 * @returns {string}            The serialized HuiPath
	 */
	toString(separator:?string = DEFAULT_SEPARATOR) {
		return this.pathArray.join(separator);
	}
}
