import SidebarStore from 'js/lib/stores/SidebarStore';
import { NotSignedInError } from 'js/lib/exceptions';
import Log from 'js/lib/classes/Log';

let promise;

/**
 * auth singleton
 */
export default function entitlementsPromise() {
	return promise || (promise = new Promise((resolve, reject) => {
		let unListen;

		/**
		 * Tries to get entitlements from the SidebarStore.
		 * @return {boolean} Whether or not it was able to.
		 */
		function tryToResolveWithEntitlements() {
			const sidebarState = SidebarStore.getData();

			if (sidebarState.products && !sidebarState.isFetchingProducts) {
				if (sidebarState.products) {
					// distill down to keys and bools
					const entitlements = sidebarState.products.reduce((obj, value) => {
						obj[value.id] = !value.isDisabled;
						return obj;
					}, {});
					Log.info('Got entitlements', entitlements);
					resolve(entitlements);
				} else {
					Log.warn('Could not get entitlements.');
					reject(new NotSignedInError('Could not get entitlements.'));
				}

				if (unListen) unListen();

				return true;
			}

			return false;
		}

		if (!tryToResolveWithEntitlements()) {
			try {
				unListen = SidebarStore.listen(tryToResolveWithEntitlements);
			} catch (e) {
				Log.warn(e);
			}
		}
	}));
}
