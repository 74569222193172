import React from 'react';
import BaseWrapper from '../BaseWrapper';
import CardStore from '../../stores/CardStore';
import actions from '../../classes/Actions';
import ControllerView from '../ControllerView';
import CardControllerView from '../CardControllerView';

/**
 * Class representing a dashboard card.
 */
export default class CardWrapper extends BaseWrapper {

	/**
	 * Create a dashboard card.
	 *
	 * @param {object} opts Options of the format
	 * @param {jsx} ReactClass A React class
	 *      {
	 *          id: a unique identifier for the card
	 *          dataProvider: a object implementing the dataProvider interface
	 *          ReactClass: The React component to use when rendering this Card;
	 *            Usually provided by a subclass
	 *          container: (optional) an HtmlElement or unique CSS selector for the element's root
	 *              use only to render directly
	 *      }
	 */
	constructor(opts, ReactClass) {

		if (!ReactClass) {
			throw new Error('Card is intended as abstract: Use a <type>Card instead of Card.');
		}

		super(opts, ReactClass);

		// This is an exception because Card is an abstract class,
		// and it needs to know which child class to render
		this.reactProps.ReactClass = ReactClass;

		// TODO FIXME HACK figure out why this is necessary
		this.reactProps.dataProvider = opts.dataProvider;

		actions.createCard(this.reactProps);
	}

	/**
	 * Instantiates the React component for direct ReactDOM rendering (ie no parent).
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		const cardId = this.id;

		/**
		 * Finds the component's data from the full store data
		 * @param   {Object} cardStoreData  The cardStore's full data
		 * @return {Object}  The card component's data
		 */
		function getComponentDataFromStoreData(cardStoreData) {
			return cardStoreData[cardId];
		}

		return (
			<ControllerView
				transform={getComponentDataFromStoreData}
				component={this.reactProps.ReactClass}
				store={CardStore}
			/>
		);
	}

	standalone() {
		return (
			<CardControllerView
				dataProvider={this.reactProps.dataProvider}
				cardId={this.id}
			>
				<this.reactProps.ReactClass {...this.reactProps} />
			</CardControllerView>
		);
	}
}
