'use strict';

import Log from './Log';
import Actions from './Actions';
import Constants from '../constants';
import SidebarStore from '../stores/SidebarStore';
import Events from 'js/lib/classes/Events';

class HuiApp {

	/**
	 * Initializes things
	 */
	init() {
		this.authDataProvider = require('plugins/bcc/AuthProvider').BCCAuthDataProvider;

		Actions.setLocale();

		Events.init();

		Actions.selectSidebarProduct.listen(this.refetchAuthBasedOnSelectedProduct.bind(this));
	}

	/**
	 * Refetches auth data based on selected serviceId
	 */
	refetchAuthBasedOnSelectedProduct() {
		this.getAuthDataFromProvider(this.getSelectedProductId());
	}

	/**
	 * Fetches auth data, but only once.
	 * @param	{string} productId	The product ID (converted to serviceId in the provider))
	 * @return {mixed} a Promise or false
	 */
	checkAuthOnce(productId: ?string) {
		if (!this.authPromise) {
			this.authPromise = this.getAuthDataFromProvider(productId || this.getSelectedProductId());
		}

		return this.authPromise;
	}

	/**
	 * Gets the productId of the currently selected product
	 *
	 * @param {string} productId the id of the calling product
	 */
	getSelectedProductId() {
		const sidebarStore = SidebarStore.getData();

		if (sidebarStore.selections) {
			this.authDataProvider.tools.productId = sidebarStore.selections.productId;
		} else {
			this.authDataProvider.tools.productId = Constants.Sidebar.homeProductServiceId;
		}
	}

	/**
	 * Gets the Header's data from its dataProvider
	 * @param	{string} productId	The product ID (converted to serviceId in the provider))
	 * @returns {mixed} the ajax request promise or false
	 */
	getAuthDataFromProvider(productId) {
		this.authDataProvider.tools.productId = productId;

		Actions.setAuthBusy(true);
		try {
			const providerPromise = this.authDataProvider.getData(data => {
				Actions.setAuthInfo(data);
				Actions.setAuthBusy(false);
			}, _e => {
				Actions.setAuthBusy(false);
			}, this);

			if (providerPromise) {
				return providerPromise.catch(e => {
					throw e;
				});
			}
		} catch (e) {
			Log.error(e, 'DataProvider was missing or threw an error');
			Actions.setAuthBusy(false);
		}

		return false;
	}
}

export default new HuiApp();
