import PropTypes from 'prop-types';
import React from 'react';
import Log from 'js/lib/classes/Log';
import MessagesStore from 'js/lib/stores/MessagesStore';
import Actions from 'js/lib/classes/Actions';
import getHtmlFromText from 'js/lib/utils/getHtmlFromText';

const identityFunction = param => param;

/**
 * Component that generates a <span> with the localized string value for a given LID.
 * @param {number|string} lid The LID to translate.
 * @param {array|object} variables Any variables used by the translation.
 * @param {boolean} html HTML used in the translation should be rendered.
 * @param {boolean} unsafeVariables When html is true, setting unsafeVariables will sanitize variables before output
 *                                  to guard against injection attacks.
 * @param {array} exceptSafeVariables The variables named in this array will not be sanitized if unsafeVariables is set.
 *                                    Note that for variables arrays, the values ARE 1-indexed, just like in the template strings.
 * @param {boolean} postFn A post-processing function run on the resulting text. Should accept and return a string.
 */
export class LocalizedString extends React.Component {
	state = {
		messages: {},
		locale: 'en_US',
	};

	static propTypes = {
		lid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		variables: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.object,
		]),
		html: PropTypes.bool,
		unsafeVariables: PropTypes.bool,
		exceptSafeVariables: PropTypes.array,
		postFn: PropTypes.func,
	};

	static defaultProps = {
		variables: [],
		postFn: identityFunction,
	}

	/**
	 * Called when MessagesStore changes
	 * @param {object} messagesData - The MessagesStore state
	 */
	onStatusChange(messagesData) {
		this.setState(messagesData);
	}

	/**
	 * Called when component will mount
	 */
	componentWillMount() {
		this.onStatusChange(MessagesStore.getData());
	}

	/**
	 * Called when component mounts
	 */
	componentDidMount() {
		this.unsubscribe = MessagesStore.listen(this.onStatusChange.bind(this));
	}

	/**
	 * Called when component unmounts
	 */
	componentWillUnmount() {
		this.unsubscribe();
	}

	/**
	 * React component
	 * @returns {jsx} the localized string component
	 */
	render() {
		const { messages, locale } = this.state;
		const { lid, variables: variablesProp, postFn, html, unsafeVariables, exceptSafeVariables } = this.props;
		let translatedString;
		let variables = variablesProp;

		if (html && unsafeVariables) {
			variables = sanitizeVariablesToHTML(variables, exceptSafeVariables);
		}

		try {
			translatedString = _translate(messages, locale, lid, variables, postFn);
		} catch (e) {
			translatedString = '';
		}

		return html ? <span dangerouslySetInnerHTML={{ __html: translatedString }} /> : <span>{translatedString}</span>;
	}
}

/**
 * Wrapping component for things like INPUT placeholder attributes.
 * @param {number} lid the id of the string
 * @param {string} attribute the attribute to inject to the single child
 * @return {jsx} the jsx of itself and passed children
 */
export class InjectLocalizedAttribute extends LocalizedString {
	static propTypes = {
		lid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		attribute: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired,
		postFn: PropTypes.func,
	};

	static defaultProps = {
		postFn: identityFunction,
	};

	/**
	 * React component
	 * @returns {jsx} the localized string component
	 */
	render() {
		if (React.Children.count(this.props.children) !== 1) {
			Log.warn('You may only pass one child element to InjectLocalizedAttribute.');

			// return the original children (wrapped so we don't explode)
			return <span>{this.props.children}</span>;
		}

		let translatedString;

		try {
			translatedString = _translate(this.state.messages, this.state.locale, this.props.lid, this.props.variables, this.props.postFn);
		} catch (e) {
			// return the original child
			return this.props.children;
		}

		// return a clone of the child with our localized attribute
		return React.cloneElement(this.props.children, {
			[this.props.attribute]: translatedString,
		});
	}
}

/**
 * Provide localized strings from LIDs as parameters to a rendering function.
 * @param {object} lidMap Object mapping keys to LIDs, or to an object consisting of { lid, variables }
 * @param {function} render Function to render the contents. Receives an object matching lidMap with translated strings.
 * @param {function} postFn Function to post-process the results of the translation.
 */
export class ProvideLocalizedStrings extends LocalizedString {
	static propTypes = {
		lidMap: PropTypes.object,
		render: PropTypes.func.isRequired,
		postFn: PropTypes.func,
	}

	/**
	 * React component
	 * @returns {jsx} the localized string component
	 */
	render() {
		const { lidMap, render: renderFunction, postFn } = this.props;
		const { messages, locale } = this.state;
		const translated = { };

		Object.keys(lidMap).forEach(key => {
			let lid = lidMap[key];
			let variables = {};

			if (typeof lid === 'object' && lid.lid) {
				variables = lid.variables;
				lid = lid.lid;
			}

			try {
				translated[key] = _translate(messages, locale, lid, variables, postFn || identityFunction);
			} catch (e) {
				translated[key] = lid;
			}
		}, {});

		return renderFunction(translated);
	}
}

/**
 * Adds a messagesLoader, for use by localized apps such as BCC's essentials wizard
 * @param {function} messagesLoader a function that returns a messages file
 */
export function addMessagesLoader(messagesLoader: Function) {
	Actions.addMessagesLoader(messagesLoader);
}

/**
 * Translates a LID into a string, based on the current locale
 * @param   {number} lid   The lid to identify the message to translate
 * @param   {object|array} variables  A 1-based array of strings or
 *                         object of keys and strings to replace with __ around them
 * @returns {string}       The translated string
 */
export function translateLid(lid, variables = [], postFn) {
	const { messages, locale } = MessagesStore.getData();

	try {
		return _translate(messages, locale, lid, variables, postFn);
	} catch (e) {
		// return empty
		return '';
	}
}

/**
 * Sanitize variables for inclusion into an HTML string.
 * @param {array|object} variables Variables.
 * @param {array} except Variables (keys) to pass through without sanitization.
 * @returns {array|object} Variables with HTML entities converted.
 */
export function sanitizeVariablesToHTML(variables: Array|Object, except?: Array) {
	const isArray = Array.isArray(variables);
	const varsOut = isArray ? [] : {};
	Object.keys(variables).forEach(k => {
		// In the case of an array, we need to make k a number and add 1 for 1-indexing.
		const isException = except && (except.indexOf(isArray ? Number(k) + 1 : k) !== -1);
		varsOut[k] = isException ? variables[k] : getHtmlFromText(variables[k]);
	});
	return varsOut;
}

/**
 * Get the translation text for a LID.
 * @param {object} messages Messages object.
 * @param {string} locale Locale string.
 * @param {number|string} lid LID key.
 * @returns {string} Returns the LID template without variables expanded.
 */
export function _getMessage(messages: Object, locale: string, lid: number|string) {
	// check if placeholder string
	if (typeof lid === 'string') {
		Log.warn(`[HUI][i18n] Showing lid placeholder '${lid} [!LID]'.`);
		return `${lid} [!LID]`;
	}

	// check if lid available
	if (messages[lid] === undefined) {
		Log.warn('[HUI][i18n]Missing lid ' + lid + ' for locale ' + locale);
		throw new Error(`MISSING LID: ${lid} for locale ${locale}`);
	}

	return messages[lid];
}

/**
 * Does the main part of the translation. Pulls from messages, interpolates vars.
 *
 * @param  {object} messages  [description]
 * @param  {number} lid       [description]
 * @param  {object|array} variables [description]
 * @return {string}           the final string, or empty string
 */
export function _translate(messages: Object, locale: string, lid: number|string, variables: Array|Object = [], postFn = text => text) {
	let variablesLocal = variables;
	const message = _getMessage(messages, locale, lid);

	// because message.txt lids are 1-based by convention
	// we are essentially adding an empty string entry to a copy of variables
	// if it's not an array, there's no need to actuall make a copy
	if (Array.isArray(variables) && variables.length) {
		variablesLocal = [''].concat(variables);
	}

	return postFn(Object.entries(variablesLocal).reduce((text, [key, value]) => {
		if (key === '0') return text;

		if (text.indexOf(`__${key}__`) === -1) {
			Log.warn(`Cannot find key '__${key}__' in lid ${lid}`);
		}

		// we split with a string instead of a global regex, because
		// key could theoretically contain regex characters
		return text.split(`__${key}__`).join(value);

	}, message || ''));
}

export default {
	LocalizedString,
	InjectLocalizedAttribute,
	ProvideLocalizedStrings,
	translateLid,
	addMessagesLoader,
};
