/* global HUI */

// these are real imports because they need to get pulled into the core bundle
import { BBSTreeNodesDataProvider } from '../../plugins/bbs/TreeNodesProvider';
import { BACTreeNodesDataProvider } from '../../plugins/bac/TreeNodesProvider';

export const BCCTreeNodesDataProviderProvider = new HUI.Providers.DataProvider((tools, resolve) => {
	resolve([
		{
			id: 'BBSTreeNodesDataProvider',
			dataProvider: BBSTreeNodesDataProvider,
			productIds: [
				'bbs',			// Backup
			],
		},
		{
			id: 'BACTreeNodesDataProvider',
			dataProvider: BACTreeNodesDataProvider,
			productIds: [
				'bac',			// Main Product (for busy spinner)
				'spyware',		// Web Filter
				'emailswitch',	// Spam Firewall
				'archiver',		// Message Archiver
				'bws',			// Web Application Firewall
				'balancer',		// Load Balancer
				'bwb',			// Link Balancer
				'bvs',			// SSL VPN
				'bess',			// Cloud Protection Layer
				'bfw',			// Next Gen Firewall X-Series
			],
		},
	]);
});
