import React from 'react';
import Card from '../Card';
import Loading from '../../common/Loading';
import ErrorView from '../../common/ErrorView';
import Watermark from '../stateless/Watermark';
import CardOverlay from '../stateless/CardOverlay';
import Categories from '../stateless/Categories';
import merge from 'lodash/merge';
import Events from '../../../classes/Events';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

const baseConfig = {
	categories: false,
	title: { text: null },
	chart: {
		type: 'line',
		// Preferably would be not defined since Highcharts will use parent's height,
		// But due to our nested markup, this is a better way.
		// Panel is 300px height w/ 1px border, so effective height is 298px
		height: 298,
		animation: false,
		spacing: [10, 20, 10, 20],
	},
	plotOptions: {
		series: {
			animation: false,
			marker: {
				fillColor: '#FFFFFF',
				lineWidth: 2,
				lineColor: null,
				symbol: 'circle',
			},
		},
	},
	credits: { enabled: false },
	legend: {
		borderWidth: 0,
		align: 'right',
		verticalAlign: 'top',
		symbolRadius: 5,
		symbolWidth: 10,
		symbolHeight: 10,
		itemStyle: {
			fontWeight: 'normal',
		},
	},
	xAxis: {
		title: { enabled: false },
	},
	yAxis: {
		title: { enabled: false },
	},
};

const lineConfig = {
	chart: {
		// type: 'line',
	},
};

const areaConfig = {
	chart: {
		type: 'area',
	},
	plotOptions: {
		area: {
			stacking: 'normal',
			fillOpacity: 0.3,
		},
	},
};

const stackedColumnConfig = {
	chart: {
		type: 'column',
	},
	plotOptions: {
		column: {
			stacking: 'normal',
			borderWidth: 0,
			dataLabels: {
				enabled: true,
				formatter: function formatLabel() {
					return this.y || '';
				},
				color: 'white',
				style: {
					textShadow: '0 0 3px black',
					fontSize: '8px',
				},
			},
		},
	},
	xAxis: {
		labels: {
			style: {
				color: '#777777', // $text-medium
				fontSize: '9px',
			},
			autoRotation: [-90],
		},
	},
	yAxis: {
		tickPixelInterval: 30,
		labels: {
			style: {
				color: '#777777', // $text-medium
			},
		},
	},
};

const stackedBarConfig = {
	chart: {
		type: 'bar',
	},
	xAxis: {
		tickWidth: 0,
		labels: {
			style: {
				fontSize: '9px',
				color: '#777777', // $text-medium
			},
		},
	},
	yAxis: {
		lineWidth: 1,
		lineColor: '#BBBBBB',
		tickWidth: 1,
		tickColor: '#BBBBBB',
		tickLength: 5,
		min: 0,
		title: { text: null },
		stackLabels: {
			enabled: true,
			style: {
				fontSize: '9px',
				fontWeight: 'normal',
			},
		},
		labels: {
			y: 15,
			x: 3,
			rotation: -90,
			style: {
				fontSize: '9px',
				color: '#777777', // $text-medium
			},
		},
		tickPixelInterval: 30,
		minTickInterval: 5,
	},
	legend: {
		itemStyle: {
			color: '#777777', // $text-medium
			fontFamily: 'Lato, helvetica, arial, sans-serif',
		},
	},
	plotOptions: {
		series: {
			borderWidth: 0,
			stacking: 'normal',
			dataLabels: {
				enabled: true,
				color: 'white',
				style: {
					textShadow: '0 0 3px black',
					fontSize: '8px',
				},
				formatter: function formatLabel() {
					return this.y || '';
				},
			},
		},
	},
};

const pieWithDataTableConfig = {
	chart: {
		type: 'pie',
	},
	legend: {
		title: {
			style: {
				textDecoration: 'underline',
				fontWeight: 'normal',
				color: '#777777', // $text-light
				fontFamily: 'Lato, helvetica, arial, sans-serif',
			},
		},
		align: 'center',
		enabled: true,
		layout: 'vertical',
		verticalAlign: 'bottom',
		itemMarginTop: 3,
		itemMarginBottom: 3,
		labelFormat: `
			<span class='hui-striped-row'>
				<span style="display: inline-block; width: 150px;">{name}</span>
				<span style="display: inline-block; width: 100px; text-align: right;">{y}</span>
			</span>
		`,
		itemStyle: {
			fontFamily: 'Lato, helvetica, arial, sans-serif',
		},
		floating: true,
		useHTML: true,
	},
	plotOptions: {
		pie: {
			dataLabels: {
				enabled: false,
			},
			showInLegend: true,
		},
	},
};

const donutConfig = {
	chart: {
		type: 'pie',
	},
	title: {
		align: 'center',
		verticalAlign: 'middle',
		y: -7,
		floating: true,
	},
	tooltip: {
		backgroundColor: 'rgba(0,0,0,0)',
		borderWidth: 0,
		headerFormat: '',
		enabled: false,
		shadow: false,
		useHTML: true,
	},
	plotOptions: {
		pie: {
			states: {
				hover: {
					enabled: false,
				},
			},
			dataLabels: {
				enabled: true,
				distance: 10,
				connectorWidth: 0,
				style: {
					color: '#777', // $text-light ($dark-gray-7s)
					textShadow: '',
					fontSize: 12,
					fontWeight: 'normal',
					fontFamily: '"helvetica neue", helvetica, arial, sans-serif',
				},
			},
			size: '80%',
			startAngle: 180,
			center: ['50%', '50%'],
		},
	},
	series: [
		{
			type: 'pie',
			innerSize: '80%',
		},
	],
};

/**
 * Class representing the react class for the dashboard.
 */
export default class GraphCard extends Card {
	/**
	 * @constructor
	 */
	constructor() {
		super();
		this.reflowGraph = this.reflowGraph.bind(this);
	}

	state = {
		config: {},
		isPureConfig: false,
		Chart: null,
		libsLoaded: false,
	};

	static defaultProps = {
		data: {},
	};

	/**
	 * Called when window has resized.
	 */
	reflowGraph() {
		global.requestAnimationFrame(() => {
			if (this.graphCard) {
				this.graphCard.getChart().reflow();
			}
		});
	}

	/**
	 * React life-cycle.
	 */
	componentWillMount() {
		require.ensure([], (require) => {
			const Highcharts = require('imports-loader?window=js/lib/utils/fakeWindow!Highcharts');
			require('HighchartsPatternFill')(Highcharts);
			const chartFactory = require('imports-loader?Highcharts=>true!chartFactory');

			// override highcharts offset method since it doesn't use document.scrollingElement
			require('js/lib/utils/highchartsOffsetPatch')(Highcharts);

			this.setState({
				libsLoaded: true,
				Chart: chartFactory('Chart', Highcharts),
			});
		});

		this.setState({
			config: this.getChartConfig(this.props.data),
			isPureConfig: false,
		});
	}

	/**
	 * React life-cycle.
	 */
	componentDidMount() {
		Events.addEventListener('pageLayoutReflow', this.reflowGraph);
		Events.addEventListener('globalResize', this.reflowGraph);
	}

	/**
	 * React life-cycle.
	 */
	componentDidUpdate() {
		if (this.refs.graphCard) {
			const chart = this.refs.graphCard.getChart();

			if (chart && this.props.data && this.props.data.series) {
				// Go through all the series from our dataprovider and
				// update the existing ones on our chart or add new ones.
				this.props.data.series.forEach((series, i) => {
					if (chart.series[i]) {
						chart.series[i].setData(series.data, true);
					} else {
						chart.addSeries(series);
					}
				});
			}
		}
	}

	/**
	 * React life-cycle hook.
	 */
	componentWillUnmount() {
		Events.removeEventListener('globalResize', this.reflowGraph);
		Events.removeEventListener('pageLayoutReflow', this.reflowGraph);
	}

	/**
	 * React life-cycle hook.
	 * @param   {object} nextProps  The component's soon-to-be props.
	 */
	componentWillReceiveProps(nextProps) {
		const config = this.getChartConfig(nextProps.data);
		let isPureConfig;

		// special case: if everything is empty, force full redraw of graph
		// (needed for transitions and initial draw but mostly transitions)
		if (isEqual({}, this.props.data) && isEqual({}, nextProps.data)) {
			isPureConfig = false;
		} else {
			isPureConfig = isEqual(omit(this.props.data, 'series'), omit(nextProps.data, 'series'));
		}

		this.setState({ config, isPureConfig });
	}

	/**
	 * Gets height adjustments by graph type and config
	 * @param   {object}  data  props.data or nextProps.data
	 * @returns {object}        Adjustments by graph type and config
	 */
	getChartAdjustmentsByGraphType(data) {
		let adjustments = {};

		try {
			switch (data.graphType) {
				case 'donut':
					adjustments = merge(adjustments, donutConfig);
					break;
				case 'line':
					adjustments = merge(adjustments, lineConfig);
					break;
				case 'area':
					adjustments = merge(adjustments, areaConfig);
					break;
				case 'stackedColumn':
					adjustments = merge(adjustments, stackedColumnConfig);
					break;
				case 'stackedBar':
					adjustments = merge(adjustments, stackedBarConfig);
					break;
				case 'pieWithDataTable':
					adjustments = merge(adjustments, pieWithDataTableConfig);
					break;
				default:
					break;
			}

			if (data.categories) {
				adjustments = merge(adjustments, { chart: { height: 245 } });
			}

			// allow for htmlHeaderHeight and htmlFooterHeight
			let chartHeight = merge({}, baseConfig, adjustments).chart.height;
			if (data.htmlHeaderHeight) {
				chartHeight -= data.htmlHeaderHeight;
				adjustments = merge(adjustments, { chart: { height: chartHeight } });
			}
			if (data.htmlFooterHeight) {
				chartHeight -= data.htmlFooterHeight;
				adjustments = merge(adjustments, { chart: { height: chartHeight } });
			}
		} catch (e) {
			// do nothing
		}

		return adjustments;
	}

	/**
	 * Builds the html for the middle of the donut for a donut graph
	 * @param   {object} data  props.data or nextProps.data
	 * @returns {object}       an object containing the title for a donut graph's config
	 */
	buildDonutGraphHoleTitle(data) {
		try {
			if (data.graphType === 'donut' && data.fancyTitle) {
				const unitHTML = data.fancyTitle.unit
					? `
					<span class="hui-card-donut-title-unit">${data.fancyTitle.unit}</span>
				`
					: '';

				return {
					title: {
						useHTML: true,
						text: `
							<span class="hui-card-donut-title">
								<span class="hui-card-donut-title-value">${data.fancyTitle.value}</span>
								${unitHTML}
							</span>
							<span class="hui-card-donut-title-subtitle">
								${data.fancyTitle.subtitle || ''}
							</span>
						`,
					},
				};
			}
		} catch (e) {
			return {};
		}
	}

	/**
	 * builds a highcharts config
	 * @param  {object} data  props.data or nextProps.data
	 * @return {object}       config object for highcharts
	 */
	getChartConfig(data) {
		// adjust height
		const adjustments = this.getChartAdjustmentsByGraphType(data);
		const donutGraphTitle = this.buildDonutGraphHoleTitle(data);

		return merge({}, baseConfig, adjustments, donutGraphTitle, data);
	}

	/**
	 * Render the core Card React instance.
	 * @return {jsx} The rendered JSX.
	 */
	render() {
		try {
			if (this.props.error) {
				return (
					<div className="hui-card hui-card-graphcard">
						<ErrorView onRetry={this.props.refetchData} />
					</div>
				);
			}

			const watermarkRendering =
				this.props.data && this.props.data.watermark ? <Watermark message={this.props.data.watermark} /> : null;

			const overlayRendering =
				this.props.data && this.props.data.overlay ? <CardOverlay content={this.props.data.overlay} /> : null;

			// build a type class if we can
			let graphTypeClass = '';
			try {
				graphTypeClass = `hui-card-graphcard-type-${this.state.config.chart.type}`;
			} catch (e) {
				// ignore
			}

			return (
				<div className={`hui-card hui-card-graphcard ${graphTypeClass} ${this.getCssIdClass()}`}>
					<Loading busy={!this.state.libsLoaded || this.props.busy}>
						{this.state.Chart ? (
							<div className="hui-card-flow">
								{this.props.data.htmlHeader ? (
									<div
										className="hui-card-header"
										ref={(ref) => {
											this.graphCardHeader = ref;
										}}
									>
										{this.props.data.htmlHeader}
									</div>
								) : null}
								<div
									className="hui-card-body"
									ref={(ref) => {
										this.graphCardBody = ref;
									}}
								>
									<this.state.Chart
										isPureConfig={this.state.isPureConfig}
										neverReflow={this.state.isPureConfig}
										config={this.state.config}
										callback={this.state.config.callback}
										ref={(ref) => {
											this.graphCard = ref;
										}}
									/>
								</div>
								{this.props.data.htmlFooter ? (
									<div
										className="hui-card-footer"
										ref={(ref) => {
											this.graphCardFooter = ref;
										}}
									>
										{this.props.data.htmlFooter}
									</div>
								) : null}
							</div>
						) : (
							<ErrorView onRetry={this.props.refetchData} />
						)}
						<Categories categories={this.state.config.categories} />
						{watermarkRendering}
						{overlayRendering}
					</Loading>
				</div>
			);
		} catch (e) {
			return (
				<div className="hui-card hui-card-graphcard">
					<ErrorView onRetry={this.props.refetchData} />
				</div>
			);
		}
	}
}
