import PropTypes, { instanceOf } from 'prop-types';
import React from 'react';
import 'scss/components/wrapper';
import Footer from '../footer/Footer';
import SidebarStore from '../../stores/SidebarStore';
import ControllerView from '../ControllerView';
import BccHeader from '../header/BccHeader';
import BccNavbar from '../navbar/BccNavbar';
import Sidebar from '../sidebar/Sidebar';
import Constants from '../../constants';
import HeaderStore from '../../stores/HeaderStore';
import NavbarStore from '../../stores/NavbarStore';
import AppStore from '../../stores/AppStore';
import Banner from '../banner/Banner';

import { withCookies, Cookies } from 'react-cookie';

/**
 * Root component for the BCC wrapper.
 * @param  {props} props should contain:
 *      {
 *          user: an object containing an 'email' key
 *          communityUrl: url of the community page
 *          supportUrl: url of the support page
 *          accountDropdownOptions: array of links of the form
 *            [{key, title, ?url}] - links with a falsy 'key' will render as dividers
 *      }
 * @return {jsx} jsx for the header
 *
 * NOTE: the global URLs we need to be aware of (like privacy and terms) should be in a main file
 * along with the ones in the Footer.
 */
class Wrapper extends React.Component {
	static propTypes = {
		user: PropTypes.object,
		header: PropTypes.object,
		navbar: PropTypes.object,
		tabs: PropTypes.array,
		contentArea: PropTypes.string,
		cookies: instanceOf(Cookies),
	};

	static defaultProps = {
		tabs: [],
	};

	state = {
		sidebarExpanded: true,
		sidebarExpandedMobile: false,
	};

	/**
	 * Called when component will mount
	 */
	componentWillMount() {
		this.onStatusChange(SidebarStore.getData());
		this.onAuthChange(AppStore.getData());
	}

	/**
	 * Called when component mounts
	 */
	componentDidMount() {
		this.unsubscribeSidebarStore = SidebarStore.listen(this.onStatusChange.bind(this));
		this.unsubscribeAppStore = AppStore.listen(this.onAuthChange.bind(this));
	}

	/**
	 * Called when component unmounts
	 */
	componentWillUnmount() {
		this.unsubscribeSidebarStore();
		this.unsubscribeAppStore();
	}

	/**
	 * Called when AppStore changes
	 * @param {object} appData - The AppStore state
	 */
	onAuthChange(appData) {
		this.setState({
			auth: appData.auth,
		});
	}

	/**
	 * Called when SidebarStore changes
	 * @param {object} sidebarData - The SidebarStore state
	 */
	onStatusChange(sidebarData) {
		this.setState({
			sidebarExpanded: sidebarData.expanded,
			sidebarExpandedMobile: sidebarData.expandedMobile,
		});
	}

	/**
	 * Banner hiding logic
	 * @returns {boolean} true if the banner should be hidden
	 */
	shouldHideBanner() {
		const loggedIn = this.state.auth && this.state.auth.success;
		const hasBannerClosedCookie =
			this.props.cookies && this.props.cookies.get('closedBannerForSecured22CustomerConference');
		const userIsMSP =
			this.state.auth &&
			// ugly ugly boolean string in API response
			(this.state.auth.is_msp === 'true' ||
				// and while accounts are switching, it is a real boolean
				this.state.auth.is_msp === true);
		return !loggedIn || hasBannerClosedCookie || userIsMSP;
	}

	/**
	 * React component
	 * @returns {jsx} The BCC root wrapper
	 */
	render() {
		// uncomment to show banner (vs. following line)
		// const bannerHiddenClass = this.shouldHideBanner() ? Constants.Banner.bannerHiddenCssClass : '';
		const bannerHiddenClass = Constants.Banner.bannerHiddenCssClass;

		const sidebarHiddenClass = this.state.sidebarExpanded ? '' : Constants.Sidebar.sidebarHiddenCssClass;
		const sidebarHiddenMobileClass = this.state.sidebarExpandedMobile
			? ''
			: Constants.Sidebar.sidebarHiddenMobileCssClass;

		const navbarTransform = (stores) => {
			const data = stores.navbarStore;

			if (stores.navbarStore.selectedTabData) {
				data.selectedPath = stores.navbarStore.selectedTabData.path;
			}

			data.sidebarSelections = stores.sidebarStore.selections;
			data.products = stores.sidebarStore.products;

			return data;
		};

		const sidebarTransform = (stores) => {
			const data = stores.sidebarStore;

			if (stores.navbarStore.selectedTabData) {
				data.navbarSelectedTab = stores.navbarStore.selectedTabData;
			}

			return data;
		};

		return (
			<div className={`hui-wrapper ${bannerHiddenClass} ${sidebarHiddenClass} ${sidebarHiddenMobileClass}`}>
				<ControllerView component={BccHeader} store={HeaderStore} />
				{/* // uncomment to show banner */}
				{/* <ControllerView
					component={Banner}
					store={AppStore}
					transform={(data) => ({ ...data, contentArea: this.props.contentArea })}
				/> */}
				<ControllerView
					component={Sidebar}
					transform={sidebarTransform}
					stores={{ sidebarStore: SidebarStore, navbarStore: NavbarStore }}
				/>
				<ControllerView
					component={BccNavbar}
					transform={navbarTransform}
					stores={{ sidebarStore: SidebarStore, navbarStore: NavbarStore }}
				/>
				<Footer contentArea={this.props.contentArea} />
			</div>
		);
	}
}

export default withCookies(Wrapper);
