import Reflux from 'reflux';
import Actions from '../classes/Actions';
import Events from '../classes/Events';
import TabData from '../schemas/TabData';
import getFirstDefined from '../utils/getFirstDefined';
import SidebarStore from './SidebarStore';
import AppStore from './AppStore';
import merge from 'lodash/merge';
import { flattenTabs, addPathToTabs } from '../components/navbar/Navbar';

// Brings in getState() and _data
import StoreMixins from '../stores/StoreMixins';

const NavbarStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	init() {
		this.listenTo(AppStore, this.onListenedStoreChange);
	},
	onListenedStoreChange() {
		this.triggerWithFullData();
	},
	getData() {
		const navbarData = this.getState();
		const appData = AppStore.getData();

		navbarData.auth = AppStore.getData().auth;

		if (appData.urls && appData.urls.bcc) {
			navbarData.homeHref = 'https://' + appData.urls.bcc;
		}

		return navbarData;
	},
	initializeNavbar(opts: ?Object) {
		let newNavbarState = this.getState();
		if (newNavbarState.isInitialized) {
			throw new Error('Cannot initialize the navbar more than once');
		}

		// On instantiation of the navbar we need to look at the tabs and determine their
		// initial selection status.
		if (opts && Array.isArray(opts.tabs)) {
			// Adds paths to all tabs
			addPathToTabs(opts.tabs);

			// Flatten out the tab structure to more easily identify selected tabs
			const flatTabs = flattenTabs(opts.tabs);

			let selectedTab = false;

			// Go through all the tabs and select the one with the deepest path
			flatTabs.forEach((tab) => {
				if (tab.isSelected && tab.path) {
					if (!selectedTab || tab.path.length() > selectedTab.path.length()) {
						selectedTab = tab;
					}
				}

				// isSelected isn't used from this point on. Remove it to help avoid confusion.
				delete tab.isSelected;
			});

			if (selectedTab) {
				newNavbarState.selectedTabData = new TabData({ ...selectedTab });
			}
		}

		newNavbarState = merge(newNavbarState, opts);
		newNavbarState.isInitialized = true;

		this._data = newNavbarState;
		this.triggerWithFullData();
	},
	selectNavbarTab(dataOrId: TabData | string, srcEvent) {
		const navbarState = this.getState();

		let delayTabSelection = false;
		let tabSelectionComplete = false;

		// support passing in a string id
		let data;
		if (typeof dataOrId === 'string') {
			const flatTabs = flattenTabs(navbarState.tabs);
			const tabIndex = findTabIndexById(flatTabs, dataOrId);

			data = new TabData({ ...flatTabs[tabIndex] });
		} else {
			data = dataOrId;
		}

		Events.trigger('navbarSelectionChange', {
			from: navbarState.selectedTabData,
			to: data,
			srcEvent: srcEvent ? srcEvent.nativeEvent : srcEvent,
			setLoading: (isLoading) => {
				Actions.setNavbarTabLoading(data.path, isLoading);
			},
			suspendTabSelection: () => {
				/* This function can be called to prevent the tab from showing as
				 * selected until `resumeTabSelection` is called. Note that it must
				 * be called synchronously in order to work. */
				delayTabSelection = true;
			},
			completeTabSelection: () => {
				if (delayTabSelection && !tabSelectionComplete) {
					tabSelectionComplete = true;
					this._completeNavbarTabSelection(data);
				}
			},
		});

		if (!delayTabSelection) {
			tabSelectionComplete = true;
			this._completeNavbarTabSelection(data);
		}
	},
	_completeNavbarTabSelection(data: TabData) {
		const newNavbarState = this.getState();

		newNavbarState.selectedTabData = data;

		this._data = newNavbarState;
		this.triggerWithFullData();
	},
	setNavbarTabLoading(tabPath, isLoading) {
		const newNavbarState = this.getState();
		const tab = findTabByPath(newNavbarState.tabs, tabPath);
		tab.isLoading = isLoading;

		this._data = newNavbarState;
		this.triggerWithFullData();
	},
	createWrapper(opts: Object = {}) {
		const newNavbarState = this.getState();

		const persistedSidebarData = this.loadFromCookie(SidebarStore.STORAGE_KEY);

		newNavbarState.sidebarExpanded = getFirstDefined(persistedSidebarData.expanded, opts.sidebarExpanded, true);

		this._data = newNavbarState;
		this.triggerWithFullData();
	},
	changeSidebarVisibility(expandSidebar) {
		const newNavbarState = this.getState();
		newNavbarState.sidebarExpanded = expandSidebar;

		this._data = newNavbarState;
		this.triggerWithFullData();
	},
	changeSidebarVisibilityMobile(expandSidebar) {
		const newNavbarState = this.getState();
		newNavbarState.sidebarExpandedMobile = expandSidebar;

		this._data = newNavbarState;
		this.triggerWithFullData();
	},
	shouldWrapTabs(shouldWrapTabs) {
		const newNavbarState = this.getState();
		newNavbarState.wrapTabs = shouldWrapTabs;

		this._data = newNavbarState;
		this.triggerWithFullData();
	},
	onAlterNavbarTab(tabId, newData: Object) {
		if (!tabId) {
			throw new Error('Tried to alter navbar tab with no id');
		}

		if (typeof tabId !== 'string' && typeof tabId !== 'number') {
			throw new Error('tab id must be a string or a number.');
		}

		const newNavbarState = this.getState();

		// If there are no tabs this action is meaningless
		if (newNavbarState.tabs && newNavbarState.tabs.length) {
			const tabIndex = findTabIndexById(newNavbarState.tabs, tabId);
			let subTabChildIndex = -1;
			let subTabIndex = false;

			if (tabIndex === -1) {
				// Maybe we didn't find it because it's a subnav
				newNavbarState.tabs.forEach((tab, idx) => {
					if (subTabChildIndex === -1 && tab.childItems && tab.childItems.length) {
						subTabChildIndex = findTabIndexById(tab.childItems, tabId);
						subTabIndex = idx;
					}
				});
			}

			if (tabIndex !== -1) {
				newNavbarState.tabs[tabIndex] = Object.assign(newNavbarState.tabs[tabIndex], newData);

				this._data = newNavbarState;
				this.triggerWithFullData();
			} else if (subTabChildIndex !== -1) {
				newNavbarState.tabs[subTabIndex].childItems[subTabChildIndex] = Object.assign(
					newNavbarState.tabs[subTabIndex].childItems[subTabChildIndex],
					newData,
				);

				this._data = newNavbarState;
				this.triggerWithFullData();
			}
		}
	},
});

/**
 * Gets a reference to the tab that matches the given HuiPath
 * @param   {Array} tabs    An array of tabs
 * @param   {HuiPath} path  The HuiPath to find a tab with
 * @returns {object}        A piece of the tabs data structure
 */
function findTabByPath(tabs, path) {
	let matchedTab;

	tabs.forEach((tab) => {
		if (tab.path && tab.path.equals(path)) {
			matchedTab = tab;
		} else if (tab.childItems) {
			matchedTab = findTabByPath(tab.childItems, path);
		}
	});

	return matchedTab;
}

/**
 * Finds a tab's index by its id
 * @param {Array} tabs            An array of tabs
 * @param {string|number} id    The id of the tab
 * @return {number}                The tab's index if found. -1 if not found.
 */
function findTabIndexById(tabs: Array, id) {
	let index = -1;

	tabs.forEach((tab, idx) => {
		if (tab.id && tab.id === id) {
			index = idx;
		}
	});

	return index;
}

export default NavbarStore;
