export function addClass(el, className) {
	if (el.className.indexOf(className) === -1) {
		el.className += ' ' + className;
	}
}

export function removeClass(el, className) {
	if (el.className.indexOf(className) !== -1) {
		el.className = el.className.replace(new RegExp(' ?' + className), '');
	}
}

export function toggleClass(el, className, toggle = undefined) {
	if (toggle !== undefined && !toggle) {
		removeClass(el, className);
	} else {
		addClass(el, className);
	}
}
