import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';
import Events from 'js/lib/classes/Events';
import Panel from './Panel';
import PanelGroup from './PanelGroup';
import DashboardControlBar from './DashboardControlBar';
import buildProductsTree from '../../classes/buildProductsTree';
import sum from 'lodash/sum';
import Constants from '../../constants';
import 'scss/components/dashboard';

const MAX_SCALING = 5;
const MIN_SCALING = 0.5;
const ROW_MARGIN_HEIGHT = 12;

/**
 * Class representing the react class for the dashboard.
 */
class Dashboard extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		gutter: PropTypes.number,
		padding: PropTypes.number,
		smartLayout: PropTypes.bool,
		panels: PropTypes.arrayOf(PropTypes.object),
		id: PropTypes.string.isRequired,
		products: PropTypes.array,
		treeNodesByProvider: PropTypes.object,
		treeNodeGroupExpansionMap: PropTypes.object,
		autoRefresh: PropTypes.bool,
		autoRefreshInterval: PropTypes.number,
		scaling: PropTypes.string,
		filterRange: PropTypes.oneOf(['day', 'week', 'month']),
		selections: PropTypes.shape({
			productId: PropTypes.string,
			nodeDatas: PropTypes.array,
			deviceIds: PropTypes.object,
		}),
		deviceIdsByProduct: PropTypes.object,
		filterPanels: PropTypes.func,
		searchBoxValue: PropTypes.string,
		isSearching: PropTypes.bool,
		hideControlBar: PropTypes.bool,
		hideControlBarControls: PropTypes.bool,
		maxMinWidthPerRow: PropTypes.number,
		panelGroupData: PropTypes.object,
	};

	static defaultProps = {
		gutter: 8,
		padding: 12,
		smartLayout: true,
		panels: [],
		autoRefresh: false,
		autoRefreshInterval: 30000, // 30 seconds
		filterRange: 'day',
		products: [],
		treeNodesByProvider: {},
		treeNodeGroupExpansionMap: {},
		selections: {
			nodeDatas: [],
			deviceIds: {},
		},
		deviceIdsByProduct: {},
		filterPanels: (selection, panelIds) => panelIds,
		hideControlBar: false,
		hideControlBarControls: false,
		panelGroupData: null,
	};

	state = {};

	/**
	 * On mount, get dashboard size and listen to resize events.
	 */
	componentDidMount() {
		this.recordDashboardSize();
		Events.addEventListener('globalResize', this.handleResize, true);

		// TODO: this is an ugly hack; do this better
		try {
			global.document
				.querySelectorAll('.hui-contentarea')[0]
				.addEventListener('transitionend', this.recordDashboardSize.bind(this));
		} catch (e) {
			// nothing, since it might not be there
		}

		// Layout isn't correct for measuring until after the first full render
		setTimeout(this.handleResize, 0);
	}

	/**
	 * Before mount, stop listening to resize events.
	 */
	componentWillUnmount() {
		Events.removeEventListener('globalResize', this.handleResize);
	}

	handleResize = debounce(() => this.recordDashboardSize(), 250, true);

	/**
	 * Stores the Dashboard's width.
	 */
	recordDashboardSize() {
		const domNode = ReactDOM.findDOMNode(this);

		this.setState({
			dashboardWidth: domNode.clientWidth,
			dashboardViewportHeight: this.getDashboardViewportHeight(domNode),
		});
	}

	/**
	 * Measures the height that the viewport of the dashboard is expected to be
	 * @returns {int} The height of the dashboard viewport in px
	 */
	getDashboardViewportHeight(domNode) {
		try {
			const controlBar = domNode.querySelector('.hui-dashboard-controlbar');
			const controlBarHeight = controlBar.clientHeight;
			// TODO: perhaps change this so that it's passed in from somewhere
			const bottomNeighbor = global.document.getElementsByClassName('hui-footer')[0];

			const topBound = domNode.offsetTop;
			const bottomBound = bottomNeighbor.offsetTop;
			const ROUNDING_ALLOWANCE = 1;

			return bottomBound - topBound - controlBarHeight - ROUNDING_ALLOWANCE;
		} catch (e) {
			return domNode.clientHeight;
		}
	}

	/**
	 * Calculates the height of the dashboard, given a layout and scalar
	 * @param   {array} panelDisplayRows  The layout of panels and rows for the dashboard
	 * @param   {int} scalar              The scaling factor (for css transform)
	 * @returns {int}                     The height of the dashboard in px
	 */
	calculateDashboardHeight(panelDisplayRows, scalar) {
		const rowSizes = panelDisplayRows
			.map((displayRow) => {
				try {
					// Everything in a row has the same size. Rows should always have at least 1 panel
					return displayRow[0].size || Constants.Panel.SIZE_MD;
				} catch (e) {
					return null;
				}
			})
			.filter(Boolean); // Just in case of empty rows

		// All rows have a margin on top and bottom, except the first and last, respectively.
		// Those margins should collapse, leaving us with `numRows-1` margins.
		const totalRowMarginHeight = (rowSizes.length - 1) * ROW_MARGIN_HEIGHT;
		const totalRowContentHeight = sum(rowSizes.map((size) => Constants.Panel.HEIGHT_BY_SIZE[size]));
		const totalDashboardHeight = totalRowMarginHeight + totalRowContentHeight;
		return totalDashboardHeight * scalar;
	}

	/**
	 * Attempts to identify the best layout and size for the dashboard
	 * @returns {object}  The optimized layout and scalar for the dashboard
	 */
	getBestFitPanelDisplayRows() {
		const defaultDisplayRows = this.groupPanelsIntoDisplayRows();
		const defaultDisplayHeight = this.calculateDashboardHeight(defaultDisplayRows, 1);

		if (!this.state.dashboardViewportHeight) {
			return {
				displayRows: defaultDisplayRows,
				scalar: 1,
			};
		}

		if (defaultDisplayHeight > this.state.dashboardViewportHeight) {
			return this.shrinkToFitDisplayRows(defaultDisplayRows);
		}

		return this.growToFitDisplayRows(defaultDisplayRows);
	}

	/**
	 * Attempts to identify the best layout and size for the dashboard when its default is too large
	 * @param   {array} defaultDisplayRows  The default layout
	 * @returns {object}                    The optimized layout and scalar for the dashboard
	 */
	shrinkToFitDisplayRows(defaultDisplayRows) {
		let proposedScalar = 1;
		const scaleDownEnabled = this.props.scaling === 'shrink' || this.props.scaling === 'all';
		while (scaleDownEnabled && proposedScalar >= MIN_SCALING) {
			proposedScalar -= 0.02;
			const proposedDisplayRows = this.groupPanelsIntoDisplayRows(proposedScalar);

			if (this.calculateDashboardHeight(proposedDisplayRows, proposedScalar) < this.state.dashboardViewportHeight) {
				return {
					displayRows: proposedDisplayRows,
					scalar: proposedScalar,
				};
			}
		}
		// We can't do better within reasonable bounds
		return {
			displayRows: defaultDisplayRows,
			scalar: 1,
		};
	}

	/**
	 * Attempts to identify the best layout and size for the dashboard when its default is too small
	 * @param   {array} defaultDisplayRows  The default layout
	 * @returns {object}                    The optimized layout and scalar for the dashboard
	 */
	growToFitDisplayRows(defaultDisplayRows) {
		let proposedScalar = 1;
		const lastFitDisplayData = {
			displayRows: defaultDisplayRows,
			scalar: proposedScalar,
		};
		const scaleUpEnabled = this.props.scaling === 'grow' || this.props.scaling === 'all';
		while (scaleUpEnabled && proposedScalar <= MAX_SCALING) {
			proposedScalar += 0.02;
			const proposedDisplayRows = this.groupPanelsIntoDisplayRows(proposedScalar);

			if (this.calculateDashboardHeight(proposedDisplayRows, proposedScalar) > this.state.dashboardViewportHeight) {
				return lastFitDisplayData;
			}
			lastFitDisplayData.displayRows = proposedDisplayRows;
			lastFitDisplayData.scalar = proposedScalar;
		}
		// We can't do better within reasonable bounds, scale reasonably and leave it there
		return lastFitDisplayData;
	}

	/**
	 * Filters the panels to display by props.filterPanels
	 * @returns {array}  An array of the panels to display
	 */
	getPanelsToDisplayBySelection() {
		const panelIds = this.props.panels.map((panel) => panel.id);

		let panelIdsToDisplay = null;
		try {
			const productEntitlements = {};
			this.props.products.forEach((product) => {
				productEntitlements[product.id] = !product.isDisabled;
			});

			panelIdsToDisplay = this.props.filterPanels(
				this.props.selections.nodeDatas,
				panelIds,
				productEntitlements,
				this.props.treeNodesByProvider,
			);
		} catch (e) {
			// If this.props.filterPanels is invalid, leave the default
			// panelIdsToDisplay so that we'll return all panels.
		}

		if (!Array.isArray(panelIdsToDisplay)) {
			return this.flattenPanelGroups(this.props.panels);
		}

		const panelsToDisplay = this.flattenPanelGroups(this.props.panels);

		return panelsToDisplay.filter((panel) => {
			return panelIdsToDisplay.indexOf(panel.id) !== -1;
		});
	}

	flattenPanelGroups(panels) {
		return panels.reduce((result, panel) => {
			// for panelgroups, return the panelgroup (renders as an empty <span>) AND the panels
			// we have to render the panelgroup so that it does the proper dataprovider instantiation
			// if this is a panelgroup...
			if (panel.panels) {
				let panelGroupData;
				let panelGroupBusy;

				// if we have a panelgroup's top level data array
				try {
					panelGroupData = this.props.panelGroupData[panel.id].cardProps.data;
					panelGroupBusy = this.props.panelGroupData[panel.id].cardProps.busy;

					if (panelGroupData.map) {
						// append
						return result.concat(
							// panelgroup object with the following
							[panel].concat(
								// for each data array element
								this.props.panelGroupData[panel.id].cardProps.data.reduce((result2, datum) => {
									// repeat the entire panelgroup's panels
									return result2.concat(
										panel.panels.map((p) => {
											// copying each data array element into the panel
											return Object.assign({}, p, {
												panelGroupData: datum,
												panelGroupBusy: !!panelGroupBusy,
											});
										}),
									);
								}, []),
							),
						);
					}

					return result.concat([panel].concat(panel.panels));
				} catch (e) {
					// do nothing
				}
			}

			return result.concat([panel]);
		}, []);
	}

	/**
	 * Calculates how panels are laid out on page and what row they belong to.
	 * @param  {float} presentationScalar  The rendering scalar
	 * @return {Array}                     A 2D array of panels, ordered and grouped by row.
	 */
	groupPanelsIntoDisplayRows(presentationScalar = 1) {
		const panels = this.getPanelsToDisplayBySelection();

		if (!this.state.dashboardWidth) {
			return [panels];
		}

		const panelDataRows = [];
		let row = [];
		let rowCurrentWidth = this.props.padding * 2;

		panels.forEach((panel, idx, arr) => {
			if (!panel.panels) {
				const panelTotalWidth = (panel.width + this.props.gutter) * presentationScalar;
				// if the panel either too wide for the current dashboard width (with gutters)
				// or if it's too wide (without gutters) for the maxMinWidthPerRow value
				const panelWouldOverflow =
					rowCurrentWidth + panelTotalWidth > this.state.dashboardWidth ||
					row.reduce((all, p) => all + p.width, 0) + panel.width > this.props.maxMinWidthPerRow;

				// If the panel has a different size than the previous one, it needs a new row
				const panelHasDifferentSize = idx !== 0 && panel.size !== arr[idx - 1].size;

				// Store the current row and start a fresh one
				if ((panelWouldOverflow && this.props.smartLayout) || panelHasDifferentSize) {
					panelDataRows.push(row);
					row = [];
					rowCurrentWidth = 0;
				}

				rowCurrentWidth += panelTotalWidth;
			}
			row.push(panel);
		}, this);

		// Flush any panels remaining into one last row group
		if (row.length) {
			panelDataRows.push(row);
		}

		return panelDataRows;
	}

	/**
	 * Render the core Dashboard React instance.
	 * @return {jsx} the jsx view
	 */
	render() {
		const displayRowData = this.getBestFitPanelDisplayRows();
		const panelDataRows = displayRowData.displayRows;

		const productsTreeFiltered = buildProductsTree({
			products: this.props.products,
			treeNodesByProvider: this.props.treeNodesByProvider,
			searchBoxValue: this.props.searchBoxValue,
			isSearching: this.props.isSearching,
			groupExpansionMap: this.props.treeNodeGroupExpansionMap,
		});

		const rowComponents = panelDataRows.map((panelDataRow, idx) => {
			const panelComponents = panelDataRow.map((panelData) => {
				const PanelOrPanelGroup = panelData.panels ? PanelGroup : Panel;
				return (
					<PanelOrPanelGroup
						key={panelData.id}
						{...panelData}
						autoRefresh={this.props.autoRefresh}
						autoRefreshInterval={this.props.autoRefreshInterval}
						filterRange={this.props.filterRange}
						selectedDeviceIds={this.props.selections.deviceIds}
					/>
				);
			});

			const rowSize = panelDataRow && panelDataRow[0] && panelDataRow[0].size ? panelDataRow[0].size : 'md';

			return (
				<div key={idx} className={`hui-panel-row hui-panel-row-${rowSize}`}>
					{panelComponents}
				</div>
			);
		});

		const dashboardStyle = this.props.smartLayout
			? {
					paddingTop: this.props.padding,
					paddingBottom: this.props.padding,
					paddingLeft: this.props.padding - this.props.gutter / 2,
					paddingRight: this.props.padding - this.props.gutter / 2,
					height: displayRowData.scalar < 1 ? this.state.dashboardViewportHeight : null,
			  }
			: {};

		const controlBarProps = {
			id: this.props.id,
			autoRefresh: this.props.autoRefresh,
			scaling: this.props.scaling,
			filterRange: this.props.filterRange,
			selections: this.props.selections,
			productsTree: productsTreeFiltered,
			hideControls: this.props.hideControlBarControls,
		};

		const scalingCss = {
			width: (1 / displayRowData.scalar) * 100 + '%',
			transform: 'scale(' + displayRowData.scalar + ')',
			transformOrigin: '0 0',
		};

		return (
			<div className="hui-dashboard hui-bootstrap-container">
				{!this.props.hideControlBar ? <DashboardControlBar {...controlBarProps} /> : []}
				<div className="hui-dashboard-rows" style={dashboardStyle}>
					<div className="hui-dashboard-scaling-container" style={scalingCss}>
						{rowComponents}
					</div>
				</div>
			</div>
		);
	}
}

export default Dashboard;
