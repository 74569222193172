import PropTypes from 'prop-types';
import React from 'react';

/**
 * This class helps wire a dumb component to a store,
 * facilitating component refresh by passing down store data
 * as props.
 */
class ControllerView extends React.Component {

	static propTypes = {
		// One of store|stores is required
		stores: PropTypes.object, // { storeName: store }
		store: PropTypes.object,
		transform: PropTypes.func,
		component: PropTypes.func.isRequired,
	};

	static defaultProps = {
		transform: data => data,
	};

	state = {};

	/**
	 * Binds an object containing multiple stores
	 * @param {object} stores An object containing Reflux stores
	 */
	bindStores(stores:Object) {
		Object.keys(stores).forEach(key => {
			const store = stores[key];
			this.bindStore(store, key);
		});
	}

	/**
	 * Binds a store to this component
	 * @param {object} store A Reflux store
	 * @param {string} key The store key
	 */
	bindStore(store:Object, key:?string) {
		this.addUnsubscribeFunction(store.listen(this.createStoreOnChangeHandler(key)));
	}

	/**
	 * Adds an unsubscribe function to the component
	 * @param {function} unsubscribeFromStore An unsubscribe function
	 */
	addUnsubscribeFunction(unsubscribeFromStore:Function) {
		this.storeUnsubscribeFunctions.push(unsubscribeFromStore);
	}

	/**
	 * Merges data from multiple stores into a single data object
	 * @param {object} stores An object containing Reflux stores
	 * @return {{}} Merged data
	 */
	getDataFromStores(stores:Object) {

		const data = {};

		Object.keys(stores).forEach(key => {
			const store = stores[key];
			data[key] = store.getData();
		});

		return data;
	}

	/**
	 * React lifecycle method
	 */
	componentWillMount() {

		// This is an array of functions that will unsubscribe store listeners
		this.storeUnsubscribeFunctions = [];

		let initialData = {};

		if (this.props.stores) {

			// Merges data from the stores
			initialData = this.getDataFromStores(this.props.stores);

			// This is used to handle situations where only one of the stores changed state
			this.setState({ storeData: initialData });
			this.bindStores(this.props.stores);
		} else if (this.props.store) {
			initialData = this.props.store.getData();
			this.bindStore(this.props.store);
		} else {
			throw Error('ControllerView attempted to mount without `store` or `stores` property');
		}

		const componentState = this.props.transform(initialData);
		this.setState({ transformedData: componentState });
	}

	/**
	 * React lifecycle method
	 */
	componentWillUnmount() {
		this.storeUnsubscribeFunctions.forEach(unsubscribe => {
			unsubscribe();
		});
	}

	/**
	 * Creates a store onChange handler
	 * @param   {string} storeKey  The key for the store
	 * @return {function}  The onChange handler for the store with the given key
	 */
	createStoreOnChangeHandler(storeKey:?string) {
		return (newStoreData) => {
			let eventualStoreData;

			if (!storeKey) {
				// If there is only one store, put the data on state.storeData as is
				eventualStoreData = newStoreData;
			} else {
				// Otherwise, save it on state.storeData[storeKey]
				const allStoreData = this.state.storeData;
				allStoreData[storeKey] = newStoreData;
				eventualStoreData = allStoreData;
			}

			const componentState = this.props.transform(eventualStoreData);
			this.setState({ transformedData: componentState, storeData: eventualStoreData });
		};
	}

	/**
	 * @return {jsx} The react component to render
	 */
	render() {
		return (
			<this.props.component { ...this.state.transformedData } />
		);
	}

}

export default ControllerView;
