import Reflux from 'reflux';
import Actions from '../classes/Actions';

// Brings in getState() and this._data
import StoreMixins from '../stores/StoreMixins';

const CardStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	getData() {
		const cards = this.getState();

		return cards;
	},
	getCard(id) {
		return this.getState()[id];
	},
	createCard(opts) {
		if (!opts) throw Error('Card options required');
		if (!opts.id) throw Error('Card `id` is required');
		if (opts.title === undefined) throw Error('Card `title` is required');

		const newState = this.getState();
		newState[opts.id] = opts;

		this._data = newState;
		this.triggerWithFullData();
	},
	persistHtmlCardInputs(id, inputs) {
		const newState = this.getState();

		if (newState[id]) {
			newState[id].formInputs = inputs;
		}

		this._data = newState;
		this.triggerWithFullData();
	},
});

export default CardStore;
