import React from 'react';

/**
 * Returns the specified component with a HUI class name attached
 * @param   {object} component  The component to return back and attach the class to.
 * @param   {string} huiClass  The class name given to the component.
 * @returns {object} The component with the class name and props it expects.
 */
export default function HuifyComponent(Component, huiClass) {
	const output = (props) => {
		const newProps = {
			...props,
			className: huiClass,
		};

		return (
			<Component {...newProps}>
				{props.children}
			</Component>
		);
	};

	// so it shows up in debugging tools properly
	Object.defineProperty(output, 'name', { value: Component.name });

	return output;
}
