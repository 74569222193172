/* global HUI_API_ENV */
import merge from 'lodash/merge';
import Cookie from 'js-cookie';
import * as urlsByEnv from 'plugins/urls.ini';
import sessionStorageHelper from 'js/lib/utils/sessionStorageHelper';

export default {
	_data: {},
	getState() {
		return merge({}, this._data);
	},
	triggerWithFullData() {
		this.trigger(this.getData());
	},
	saveToCookie(storeName, data) {
		const urls = urlsByEnv[HUI_API_ENV];
		const huiStorage = (global.document && Cookie.getJSON('HUI')) || {};
		huiStorage[storeName] = data;
		Cookie.set('HUI', huiStorage, { path: '/', domain: `.${urls.base}`, secure: true });
	},
	loadFromCookie(storeName) {
		const huiStorage = (global.document && Cookie.getJSON('HUI')) || {};
		return huiStorage[storeName] || {};
	},
	saveToSessionStorage(key, value) {
		const cloudSession = Cookie.getJSON('cloud_session');

		const storageObject = {
			value,
			cloudSession,
		};

		sessionStorageHelper.setItem('HUI-' + key, JSON.stringify(storageObject));
	},
	loadFromSessionStorage(key) {
		const storageKey = 'HUI-' + key;
		const storageObject = JSON.parse(sessionStorageHelper.getItem(storageKey));

		if (!storageObject) {
			return null;
		}

		if (storageObject.cloudSession !== Cookie.getJSON('cloud_session')) {
			sessionStorageHelper.removeItem(storageKey);
			return null;
		}

		return storageObject.value;
	},
};
