import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Actions from '../../classes/Actions';
import Loading from '../common/Loading';
import Scrolling from '../common/Scrolling';
import ErrorView from '../common/ErrorView';
import SidebarProduct from './SidebarProduct';
import SidebarProductCategory from './SidebarProductCategory';
import ProductTreeSearch from '../common/ProductTreeSearch';
import { Sidebar as Constants } from '../../constants';
import isMobile from '../../utils/isMobile';
import 'scss/components/sidebar';
import merge from 'lodash/merge';
import buildProductsTree from '../../classes/buildProductsTree';
import Events from '../../classes/Events';
import { InjectLocalizedAttribute } from 'js/lib/i18n';
import { BCC_SIDEBAR_HOME_PRODUCT_TITLE } from 'js/lib/lids';

// this is 15 mins to satisfy BAC's request for 15 min refresh interval
const DEVICE_REFRESH_INTERVAL = 1000 * 60 * 15; // 15 mins

/**
 * Top-level Sidebar React component.
 */
class Sidebar extends React.Component {
	/**
	 * @constructor
	 */
	constructor() {
		super();

		// so we can throw it around later
		this.addOrRemoveSidebarHiddenCssClass = this.addOrRemoveSidebarHiddenCssClass.bind(this);
		this.onSidebarToggleClick = this.onSidebarToggleClick.bind(this);
	}

	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		selections: PropTypes.shape({
			productId: PropTypes.string,
			nodeDatas: PropTypes.array,
		}),
		products: PropTypes.array,
		treeNodesByProvider: PropTypes.object,
		contentArea: PropTypes.string,
		expanded: PropTypes.bool,
		expandedMobile: PropTypes.bool,
		groupExpansionMap: PropTypes.object,
		navbarSelectedTab: PropTypes.object,
		sidebarItemHrefCallback: PropTypes.func,
		isFetchingProducts: PropTypes.bool,
		productStatusById: PropTypes.object,
		error: PropTypes.bool,
		errorMessage: PropTypes.string,
		searchBoxValue: PropTypes.string,
		showSelectionOnly: PropTypes.bool,
		isSearching: PropTypes.bool,
		homeHref: PropTypes.string,
	};

	static defaultProps = {
		selections: {
			nodeDatas: [],
		},
		products: [],
		treeNodesByProvider: {}, // this is organized by Provider name
		groupExpansionMap: {},
		expandedMobile: false,
		isFetchingProducts: false,
		productStatusById: {},
	};

	state = {
		isMobile: isMobile(),
	};

	/**
	 * React life-cycle method. Starts the process of fetching data.
	 */
	componentWillMount() {
		Actions.fetchProducts();
		Actions.fetchDevices();
		this.refreshDevicesInterval = setInterval(() => {
			Actions.fetchDevices(true);
		}, DEVICE_REFRESH_INTERVAL);
	}

	/**
	 * React life-cycle method.
	 */
	componentDidMount() {
		this.assertControlOverContentArea();
		this.bindSidebarExpansionClassEvent();
		this.bindTransitionEndEvent();
		this.updateFirstSelectedEl();
		Events.addEventListener('globalResize', this.handleResize.bind(this), true);
		this.addOrRemoveSidebarHiddenCssClass({
			expanded: this.props.expanded,
			expandedMobile: this.props.expandedMobile,
		});
		Events.trigger('sidebarMounted');
	}

	/**
	 * React lifecycle hook
	 * @param {Object} nextProps The component's soon-to-be props
	 */
	componentWillReceiveProps(nextProps) {
		if (!nextProps.isInInitialPosition) {
			const el = global.document.querySelector(this.props.contentArea);
			if (el) {
				el.classList.remove(Constants.contentAreaInitialPositionCssClass);
			}
		}

		this.updateFirstSelectedEl();
	}

	updateFirstSelectedEl() {
		// update this.firstSelectedEl if possible
		try {
			this.firstSelectedEl = ReactDOM.findDOMNode(this).querySelector('.hui-sidebar-treenode .hui-state-active');
		} catch (e) {
			// we don't need to do anything in this scenario, it isn't always around
		}
	}

	/**
	 * React life-cycle method.
	 */
	componentWillUnmount() {
		this.unbindSidebarExpansionClassEvent();
		this.unbindTransitionEndEvent();
		global.removeEventListener('resize', this.handleResize.bind(this));
		this.accedeControlOverContentArea();
		clearInterval(this.refreshDevicesInterval);
	}

	/**
	 * Adds a class to the contentArea to enables interaction
	 */
	assertControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.add(Constants.contentAreaCssClass);
			el.classList.add(Constants.contentAreaInitialPositionCssClass);
		}
	}

	/**
	 * Removes the class from the contentArea that enabled interaction
	 */
	accedeControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.remove(Constants.contentAreaCssClass);
			el.classList.remove(Constants.contentAreaInitialPositionCssClass);
		}
	}

	/**
	 * Removes the sidebarVisibilityChange event handler
	 */
	unbindSidebarExpansionClassEvent() {
		Events.removeEventListener('sidebarVisibilityChange', this.addOrRemoveSidebarHiddenCssClass);
	}

	/**
	 * Adds a sidebarVisibilityChange event handler
	 */
	bindSidebarExpansionClassEvent() {
		Events.addEventListener('sidebarVisibilityChange', this.addOrRemoveSidebarHiddenCssClass);
	}

	/**
	 * Adds an event handler for when the Sidebar transition ends.
	 */
	bindTransitionEndEvent() {
		const el = ReactDOM.findDOMNode(this);

		if (el) {
			el.addEventListener('transitionend', this.transitionEndTrigger);
		}
	}

	/**
	 * Removes an event handler for when the Sidebar transition ends.
	 */
	unbindTransitionEndEvent() {
		const el = ReactDOM.findDOMNode(this);

		if (el) {
			el.removeEventListener('transitionend', this.transitionEndTrigger);
		}
	}

	/**
	 * A shared function that's triggered by transitionend event listeners.
	 */
	transitionEndTrigger() {
		Events.trigger('pageLayoutReflow');
	}

	/**
	 * When we resize, we want to hide the sidebar on mobile size entry.
	 */
	handleResize() {
		if (!this.state.isMobile && isMobile()) {
			this.setState({ isMobile: true });
			Actions.changeSidebarVisibilityMobile(false);
		} else if (this.state.isMobile && !isMobile()) {
			this.setState({ isMobile: false });
			Actions.changeSidebarVisibilityMobile(false);
		}
	}

	/**
	 * Alters the content area based on the sidebar's expanded status
	 * @param {object} data  Event data of the format { expanded: Bool, expandedMobile: Bool }
	 */
	addOrRemoveSidebarHiddenCssClass(data) {
		const el = global.document.querySelector(this.props.contentArea);

		if (el) {
			if (typeof data.expanded !== 'undefined') {
				if (data.expanded) {
					el.classList.remove(Constants.sidebarHiddenCssClass);
				} else {
					el.classList.add(Constants.sidebarHiddenCssClass);
				}
			}
			if (typeof data.expandedMobile !== 'undefined') {
				if (data.expandedMobile) {
					el.classList.remove(Constants.sidebarHiddenMobileCssClass);
				} else {
					el.classList.add(Constants.sidebarHiddenMobileCssClass);
				}
			}
		}
	}

	/**
	 * Returns a data structure set up for rendering
	 * @return {Object[]}  A rendering data structure, starting with an array of product categories
	 */
	getRenderingData() {
		const callbacks = {};

		if (this.props.sidebarItemHrefCallback) {
			callbacks.sidebarItemHrefCallback = this.makeSidebarItemHrefCallback(
				this.props.sidebarItemHrefCallback,
				this.props.navbarSelectedTab,
			);
		}

		const productCategoryList = buildProductsTree({
			products: this.props.products,
			treeNodesByProvider: this.props.treeNodesByProvider,
			searchBoxValue: this.props.searchBoxValue,
			isSearching: this.props.isSearching,
			showSelectionOnly: this.props.showSelectionOnly,
			groupExpansionMap: this.props.groupExpansionMap,
			itemCallbacks: callbacks,
		});

		this.attachSelectionsToCategoriesList(productCategoryList);

		return productCategoryList;
	}

	/**
	 * Takes a callback and calls it with information that is expected to be passed back
	 * from SidebarDeviceNode (or other child devices)
	 * @param {Function} hrefCallback The callback function to be returned
	 * @param {TabData} navbarSelectedTab The selected path of the navbar
	 * @return {Function} A function to be called with device selection information
	 */
	makeSidebarItemHrefCallback(hrefCallback: Function, navbarSelectedTab: ?Object) {
		/**
		 * This function will call the product's html manipulation callback
		 * @param {string} sidebarDeviceHref The href of the node
		 * @param {string} [sidebarDeviceId] The id of the node
		 * @param {object} [sidebarDeviceSelection] The selection object of the current node
		 * @returns {*} Should be a string representing the new href
		 */
		return (sidebarDeviceHref: string, sidebarDeviceId: ?string, sidebarDeviceSelection: ?Object) => {
			return hrefCallback(sidebarDeviceHref, navbarSelectedTab, sidebarDeviceSelection);
		};
	}

	/**
	 * Used when clicking the interior sidebar close button (mobile screens)
	 */
	onSidebarToggleClick() {
		if (isMobile()) {
			Actions.changeSidebarVisibilityMobile(!this.props.expandedMobile);
		} else {
			Actions.changeSidebarVisibility(!this.props.expanded);
		}
	}

	/**
	 * Attaches selection info and callbacks to the categoriesList
	 * @param   {Array} categoriesList  The categoriesList to operate on
	 */
	attachSelectionsToCategoriesList(categoriesList) {
		const selectNode = (nodeData, evt) => {
			Actions.selectSidebarNode(nodeData, evt);
		};
		const toggleNodeSelection = (nodeData, evt) => {
			Actions.toggleSidebarNodeSelection(nodeData, evt);
		};
		const selectProduct = (productId, evt) => {
			Actions.selectSidebarProduct(productId, evt);
		};

		const selectionsWithProductFunctions = merge({ selectProduct }, this.props.selections);
		const selectionsWithNodeFunctions = merge({ selectNode, toggleNodeSelection }, this.props.selections);

		categoriesList.forEach((category) => {
			category.products.forEach((product) => {
				product.selections = selectionsWithProductFunctions;

				product.forceExpand = this.props.isSearching;
				// apply busy/error flags
				const productStatus = this.props.productStatusById[product.id];
				product.isBusy = productStatus && productStatus.busy;
				product.isErrored = productStatus && productStatus.error;

				product.childItems.forEach((child) => {
					// Give selection data/cbs to groups and first level treeNodes
					child.selections = selectionsWithNodeFunctions;
					if (this.props.isSearching) {
						child.matchText = this.props.searchBoxValue;
					}

					if (Array.isArray(child.childItems)) {
						// Give selection data/cbs to treeNodes within groups
						child.childItems.forEach((grandChild) => {
							grandChild.selections = selectionsWithNodeFunctions;

							if (this.props.isSearching) {
								grandChild.matchText = this.props.searchBoxValue;
							}
						});
					}
				});
			});
		});
	}

	/**
	 * Render the core Dashboard React instance.
	 * @return {jsx} the jsx view
	 */
	render() {
		const homeSelections = merge(
			{
				selectProduct: (evt) => {
					Actions.selectSidebarProduct(Constants.homeProductId, evt);
				},
			},
			this.props.selections,
		);

		if (this.props.error) {
			return (
				<div className="hui-sidebar">
					<div className="hui-sidebar-controls">
						<ProductTreeSearch searchBoxValue={this.props.searchBoxValue} disabled />
					</div>

					<Scrolling scrollTo={this.firstSelectedEl}>
						<InjectLocalizedAttribute lid={BCC_SIDEBAR_HOME_PRODUCT_TITLE} attribute="title">
							<SidebarProduct
								title=""
								icon="house"
								id={Constants.homeProductId}
								selections={homeSelections}
								href={this.props.homeHref}
							/>
						</InjectLocalizedAttribute>
						<ErrorView message={this.props.errorMessage} />
					</Scrolling>
				</div>
			);
		}

		const renderingData = this.getRenderingData();
		const categoriesRendering = renderingData.map((category, idx) => {
			return <SidebarProductCategory key={idx} {...category} />;
		});
		const productRendering = (renderingData[0] ? renderingData[0].products : [])
			.filter((category) => category.id === this.props.selectedProductId)
			.map((product) => <SidebarProduct {...product} title="All Items" />);

		const showAllResultsToggleRendering = (
			<div className="hui-sidebar-show-all-results-toggle">
				{this.props.isSearching && this.props.showSelectionOnly ? (
					<a href="javascript:void(0)" onClick={() => Actions.setShowSelectionOnly(false)}>
						Show all search results...
					</a>
				) : null}
			</div>
		);

		/* no sidebar expander needed in HUI 2.0 */
		const sidebarToggle = !this.props.hideSidebarToggle && (
			<div className="hui-sidebar-toggle" onClick={this.onSidebarToggleClick} />
		);

		return (
			<div className={`hui-sidebar ${this.props.isSearching ? 'hui-sidebar-searching' : ''}`}>
				<div className="hui-sidebar-controls">
					<ProductTreeSearch searchBoxValue={this.props.searchBoxValue} />
					{sidebarToggle}
				</div>
				<Loading busy={this.props.isFetchingProducts && !this.props.products.length}>
					<Scrolling scrollTo={this.firstSelectedEl}>
						{!this.props.hideProductRows ? (
							<>
								<InjectLocalizedAttribute lid={BCC_SIDEBAR_HOME_PRODUCT_TITLE} attribute="title">
									<SidebarProduct
										title=""
										icon="house"
										id={Constants.homeProductId}
										selections={homeSelections}
										href={this.props.homeHref}
									/>
								</InjectLocalizedAttribute>
								{categoriesRendering}
								{showAllResultsToggleRendering}
							</>
						) : (
							<>
								{this.props.isSearching && !this.props.showSelectionOnly ? categoriesRendering : productRendering}
								{showAllResultsToggleRendering}
							</>
						)}
					</Scrolling>
					{this.props.isFetchingProducts ? <div className="hui-sidebar-cache-refreshing" /> : null}
				</Loading>
			</div>
		);
	}
}

export default Sidebar;
