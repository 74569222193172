/* global HUI_API_ENV */
import actions from '../../classes/Actions';
import Wrapper from './Wrapper';
import BaseWrapper from '../BaseWrapper';
import React from 'react';
import Events from '../../classes/Events';
import App from 'js/lib/classes/App';

import { CookiesProvider } from 'react-cookie';

/**
 * Class representing a dashboard.
 */
export default class WrapperWrapper extends BaseWrapper {
	initialProductId;
	contentArea;

	/**
	 * Create a wrapper.
	 *
	 * @param {Object} opts Options of the format:
	 *      {
	 *          id: (required) a string, a unique identifier to give the Wrapper
	 *          container: (required) an HtmlElement or unique CSS selector for the element's root
	 *          contentArea: a string CSS selector of the root of the rest of the page
	 *          headerTitle: the title to show in the header: "Barracuda " + headerTitle
	 *          headerHref: a string of where the logo should link to
	 *          initialProductId: a string of the productId to start selected in the sidebar and header
	 *          selectedDeviceIds: an array of strings of deviceIds to start selected in the sidebar
	 *          selectedGroupIds: an array of strings of groupIds to start selected in the sidebar
	 *          navbarTabHrefCallback: a function used to transform navbar tab hrefs using current selection data
	 *          sidebarItemHrefCallback: a function used to transform sidebar items hrefs using current selection data
	 *          sidebarExpanded: a boolean of whether to start the sidebar off expanded or not
	 *          sidebarSelectedProductIdOverride: a string of the productId to start selected in the sidebar (if set, overrides initialProductId in sidebar context)
	 *          tabs: an array of tab datas for the navbar
	 *          urls: an object that overrides the environment's url list (dev/staging only)
	 *          disableHidingContentDuringLoad: a boolean that allows the disabling of our content flash workaround.
	 *      }
	 */
	constructor(opts) {
		super(opts, Wrapper);

		// HACK to help make sure contentArea gets down to each component
		this.contentArea = opts.contentArea;

		if (!opts.container && opts.container !== false) {
			throw new Error(`Tried to instantiate Wrapper with no container.
				Container must be an HtmlElement or a selector matching exactly one element.`);
		}

		if (!opts.disableHidingContentDuringLoad) {
			this.hideContentArea(opts.contentArea);
		}

		// save this for authcheckonce use
		this.initialProductId = opts.initialProductId;

		// allow overrides for urls.ini, but only in dev/staging
		// if (HUI_API_ENV !== 'production' && (opts.urls || opts.urlsEnv)) {
		if (opts.urls || opts.urlsEnv) {
			actions.setUrlOverrides(opts.urls, opts.urlsEnv);
		}

		actions.initializeHeader({
			title: opts.headerTitle || opts.productTitle,
			productId: opts.initialProductId,
			contentArea: opts.contentArea,
			href: opts.headerHref,
		});
		actions.initializeNavbar({
			navbarTabHrefCallback: opts.navbarTabHrefCallback,
			tabs: opts.tabs,
			contentArea: opts.contentArea,
			// for CHUI / HUI 2.0
			hideSidebarToggle: opts.hideSidebarToggle,
			hideProductIcon: opts.hideProductIcon,
		});
		actions.initializeSidebar({
			sidebarItemHrefCallback: opts.sidebarItemHrefCallback,
			selectedProductId: opts.sidebarSelectedProductIdOverride || opts.initialProductId,
			selectedDeviceIds: opts.selectedDeviceIds,
			selectedGroupIds: opts.selectedGroupIds,
			selectedTagIds: opts.selectedTagIds,
			searchBoxValue: opts.searchBoxValue,
			showSelectionOnly: opts.showSelectionOnly,
			expanded: opts.sidebarExpanded,
			contentArea: opts.contentArea,
			// for CHUI / HUI 2.0
			hideSidebarToggle: opts.hideSidebarToggle,
			hideProductRows: opts.hideProductRows,
		});

		actions.createWrapper({
			sidebarExpanded: opts.sidebarExpanded,
			contentArea: opts.contentArea, // for footer
		});

		if (!opts.disableHidingContentDuringLoad) {
			Events.listenToOnce('headerMounted', this.relatedComponentDidMount.bind(this));
			Events.listenToOnce('sidebarMounted', this.relatedComponentDidMount.bind(this));
		}
	}

	/**
	 * Instantiates the React component.
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		App.checkAuthOnce(this.initialProductId);

		return (
			<CookiesProvider>
				<Wrapper {...this.reactProps} contentArea={this.contentArea} />
			</CookiesProvider>
		);
	}

	/**
	 * Keeps track of mounting components and shows content when everything has mounted.
	 */
	relatedComponentDidMount() {
		if (this.mountedComponents) {
			this.mountedComponents++;

			// Sidebar and Header are the 2 that make the content area need to be positioned.
			if (this.mountedComponents === 2) {
				this.showContentArea();
			}
		} else {
			this.mountedComponents = 1;
		}
	}

	/**
	 * Hides the content area until we're done loading.
	 * @param {string} contentAreaSelector CSS Selector we're targetting
	 */
	hideContentArea(contentAreaSelector) {
		const css = global.document.createElement('style');
		css.setAttribute('id', 'hui-wrapper-loading-css');
		css.type = 'text/css';
		css.innerHTML = contentAreaSelector + ' { visibility: hidden; }';
		global.document.head.appendChild(css);

		// fail-safe so we never just fail with no content visible
		setTimeout(this.showContentArea, 5000);
	}

	/**
	 * Shows the content area once we're done loading.
	 */
	showContentArea() {
		const css = global.document.getElementById('hui-wrapper-loading-css');

		if (css) {
			css.parentNode.removeChild(css);
		}
	}
}
