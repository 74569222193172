import CardWrapper from '../CardWrapper';
import TableCard from './TableCard';

/**
 * Class representing a dashboard card.
 */
export default class TableCardWrapper extends CardWrapper {

	/**
	 * Creates a TableCard
	 * @param {Object} opts  see CardWrapper opts
	 */
	constructor(opts) {
		super(opts, TableCard);
	}
}
