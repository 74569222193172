/* global HUI_API_ENV */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import 'scss/components/header';
import actions from '../../classes/Actions';
import Loading from '../common/Loading';
import find from 'lodash/find';
import beResponsive from '../../utils/beResponsive';
import Header from './Header';
import Constants from '../../constants';
import merge from 'lodash/merge';
import Events from 'js/lib/classes/Events';
import * as urlsByEnv from 'plugins/urls.ini';
import { LocalizedString } from 'js/lib/i18n';
import {
	BILLING_ACCOUNT_DROPDOWN_ITEM_TITLE,
	USER_PROFILE_DROPDOWN_ITEM_TITLE,
	CHANGE_LANGUAGE_DROPDOWN_ITEM_TITLE,
	BCC_HEADER_COMMUNITY_LINK_TITLE,
	BCC_HEADER_DOWNLOADS_LINK_TITLE,
	BCC_HEADER_SUPPORT_LINK_TITLE,
	SIGN_IN_DROPDOWN_ITEM_TITLE,
	SIGN_OUT_DROPDOWN_ITEM_TITLE,
	CHANGE_ACCOUNT_DROPDOWN_LABEL_TITLE,
	ACCOUNT_DROPDOWN_DEFAULT,
	ACCOUNT_DROPDOWN_MANAGE_ALL,
} from 'js/lib/lids';

/**
 * Gets url portion for redirecting back after login
 * @return {string} portion of the url, or not
 */
function getRedirectUrlPortion() {
	// these properties won't be available server-side
	if (global.location && global.location.href && global.encodeURIComponent) {
		return '?service=' + global.encodeURIComponent(global.location.href);
	}

	return '';
}

/**
 * BCC Header Component.
 *
 * NOTE: the global URLs we need to be aware of (like privacy and terms)
 * should be in a main file, same as the ones in the Header and Footer.
 */
export default class BccHeader extends React.Component {
	/**
	 * @constructor
	 */
	constructor() {
		super();

		this.beResponsive = beResponsive(this);
		this.getUserDropdownMenuItemRendering = this.getUserDropdownMenuItemRendering.bind(this);
	}

	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		auth: PropTypes.object,
		title: PropTypes.string,
		communityUrl: PropTypes.string,
		supportUrl: PropTypes.string,
		downloadsUrl: PropTypes.string,
		signInUrl: PropTypes.string,
		signOutUrl: PropTypes.string,
		accountDropdownOptions: PropTypes.arrayOf(PropTypes.object),
		contentArea: PropTypes.string,
		href: PropTypes.string,
		urls: PropTypes.object,
		className: PropTypes.string,
		productId: PropTypes.string,
	};

	/**
	 * Set up default props.
	 */
	static defaultProps = {
		auth: {},
		title: Constants.Sidebar.homeProductTitle,
		urls: urlsByEnv[HUI_API_ENV],
	};

	state = {
		data: {},
		layout: {},
	};

	/**
	 * Menu choose handler.
	 * @param {number} accountId id of the account chosen
	 */
	onChooseAccount(accountId) {
		actions.setAuthAccount(accountId);
	}

	/**
	 * React lifecycle hook
	 * @param   {object} prevProps  The previous props
	 * @param   {object} prevState  The previous state
	 */
	componentDidUpdate(prevProps, prevState) {
		this.beResponsive(prevState);
	}

	/**
	 * React lifecycle hook
	 */
	componentDidMount() {
		this.beResponsive();
		Events.addEventListener('globalResize', this.beResponsive, true);
	}

	/**
	 * React lifecycle hook
	 */
	componentWillUnmount() {
		Events.removeEventListener('globalResize', this.beResponsive);
	}

	/**
	 * Returns things that would be default props but need urls that may be overridden
	 * @returns {object} the "props"
	 */
	getPropsWithUrls() {
		// we merge to allow the urls to be overridden
		return merge(
			{
				communityUrl: `https://${this.props.urls.bcc}/community`,
				supportUrl: `https://${this.props.urls.bcc}/support`,
				downloadsUrl: `https://${this.props.urls.bcc}/downloads`,
				signInUrl: `https://${this.props.urls.bcc}/auth/login/${getRedirectUrlPortion()}`,
				signOutUrl: `https://${this.props.urls.bcc}/auth/logout/`,
				accountDropdownOptions: [
					{
						key: 'billing',
						title: <LocalizedString lid={BILLING_ACCOUNT_DROPDOWN_ITEM_TITLE} />,
						href: `https://${this.props.urls.bcc}/account/details/`,
					},
					{
						key: 'profile',
						title: <LocalizedString lid={USER_PROFILE_DROPDOWN_ITEM_TITLE} />,
						href: `https://${this.props.urls.bcc}/account/profile/`,
					},
					{
						key: 'language',
						title: <LocalizedString lid={CHANGE_LANGUAGE_DROPDOWN_ITEM_TITLE} />,
						href: `https://${this.props.urls.bcc}/account/profile/`,
					},
				],
				href: `https://${this.props.urls.bcc}/`,
			},
			this.props,
		);
	}

	/**
	 * Returns whether the layout is currently broken
	 * @returns {Boolean} Whether the layout is currently vertically blown out
	 */
	isLayoutBroken() {
		const domNode = ReactDOM.findDOMNode(this);
		const contentNodes = domNode.querySelectorAll('.hui-header-right-contents, .hui-header-left-contents');

		// Array.from() because querySelector returns nodeLists, not arrays
		const blownOut = Array.from(contentNodes).some((contentNode) => {
			return Array.from(contentNode.children).some((child) => {
				return child.clientHeight > contentNode.clientHeight;
			});
		});

		return blownOut;
	}

	/**
	 * Returns whether the account ID passed in is the default or not.
	 * @param   {number} accountId  The id to check against
	 * @returns {Boolean} whether the account ID passed in is the default or not.
	 */
	isDefaultAccount(accountId) {
		return accountId === this.props.auth.default_account;
	}

	/**
	 * Returns whether the account ID passed in is the special 'manage all accounts' account.
	 * @param   {number} accountId  The id to check against
	 * @returns {Boolean} whether the account ID passed in is the special account or not.
	 */
	isManageAllAccountsAccount(accountId) {
		return accountId === Constants.Header.manageAllAccountsAccountId;
	}

	/**
	 * Returns the number of layout alternateLayouts the header can make
	 * @returns {int} The number of alternateLayouts
	 */
	maxAlternateLayouts() {
		// alternateLayout 1, drop the username from the user dropdown
		// alternateLayout 2, move the account dropdown into the user dropdown
		// alternateLayout 3, move the links into the user dropdown
		return 3;
	}

	/**
	 * Generates the rendering for the user dropdown
	 * @returns {jsx} The user dropdown component rendering
	 */
	getUserDropdownRendering() {
		const propsWithUrls = this.getPropsWithUrls();

		if (!this.props.auth.username) {
			return null;
		}

		const userDropdownTitle = [
			<span key="icon" className="hui-user-icon"></span>,
			<span key="title" className="hui-user-title">
				{this.props.auth.username}
			</span>,
		];

		if (this.state.layout && this.state.layout.alternateLayout >= 1) {
			userDropdownTitle.pop();
		}

		let linksRendering = null;
		if (this.state.layout.alternateLayout >= 2) {
			linksRendering = this.getLinkMenuItemRendering();
		}

		let accountSwitcherRendering = null;
		if (this.state.layout.alternateLayout >= 3) {
			const jsx = this.getAccountSwitcherMenuItemsRendering(true);
			accountSwitcherRendering = jsx
				? [
						<MenuItem divider />,
						<MenuItem header>
							<LocalizedString lid={CHANGE_ACCOUNT_DROPDOWN_LABEL_TITLE} />
						</MenuItem>,
						jsx,
				  ]
				: [];
		}

		return (
			<span className="hui-user">
				<DropdownButton bsStyle="link" id="hui-user-dropdown" title={userDropdownTitle} pullRight>
					{propsWithUrls.accountDropdownOptions.map(this.getUserDropdownMenuItemRendering)}
					{linksRendering}
					{accountSwitcherRendering}
					<MenuItem divider />
					<MenuItem key="signOut" eventKey="signOut" href={propsWithUrls.signOutUrl}>
						<LocalizedString lid={SIGN_OUT_DROPDOWN_ITEM_TITLE} />
					</MenuItem>
				</DropdownButton>
			</span>
		);
	}

	/**
	 * Generates the rendering for a user dropdown menu item
	 * @param   {object} option  The menu item info, { key, title, ?href }
	 * @returns {jsx}            The user dropdown component rendering
	 */
	getUserDropdownMenuItemRendering(option) {
		if (!option.key) {
			return <MenuItem divider />;
		}

		// Hide 'Administration' aka 'Billing and Info' if they can't see it.
		if (this.props.auth.has_billing === 'false' && option.key === Constants.Header.administrationBilling) {
			return null;
		}

		return (
			<MenuItem key={option.key} eventKey={option.key} href={option.href}>
				{option.title}
			</MenuItem>
		);
	}

	/**
	 * Generates the rendering for the account switcher dropdown
	 * @returns {jsx} The account switcher dropdown component rendering
	 */
	getAccountDropdownRendering() {
		if (!this.props.auth.accounts || this.props.auth.accounts.length <= 1 || this.state.layout.alternateLayout >= 3) {
			return null;
		}

		const account = find(this.props.auth.accounts, {
			// support all_accounts_selected property
			id: this.props.auth.all_accounts_selected
				? Constants.Header.manageAllAccountsAccountId
				: this.props.auth.account_id,
		});

		if (!account) {
			return null;
		}

		let accountDropdownTitle = account.name;

		if (this.props.auth.accountChangeInProgress) {
			accountDropdownTitle = [<span className="hui-loading-spinner"></span>, accountDropdownTitle];
		}

		return (
			<span className="hui-account">
				<DropdownButton id="hui-account-dropdown" title={accountDropdownTitle}>
					{this.getAccountSwitcherMenuItemsRendering(false)}
				</DropdownButton>
			</span>
		);
	}

	/**
	 * Generates the normal rendering for the links
	 * @returns {jsx} The links component rendering
	 */
	getStandardLinksRendering() {
		const propsWithUrls = this.getPropsWithUrls();

		if (this.state.layout.alternateLayout >= 2) {
			if (this.props.auth.username) {
				return null; // They'll be in the user dropdown
			}
			return this.getSignInDropdownRendering(); // Make a links dropdown
		}

		return (
			<span className="hui-links">
				<a className="hui-href" href={propsWithUrls.downloadsUrl}>
					<LocalizedString lid={BCC_HEADER_DOWNLOADS_LINK_TITLE} />
				</a>
				<a className="hui-href hui-href-flagged" href={propsWithUrls.communityUrl} target="_blank">
					<LocalizedString lid={BCC_HEADER_COMMUNITY_LINK_TITLE} />
				</a>
				<a className="hui-href" href={propsWithUrls.supportUrl} target="_blank">
					<LocalizedString lid={BCC_HEADER_SUPPORT_LINK_TITLE} />
				</a>
				{!this.props.auth.username ? (
					<a className="hui-href" href={propsWithUrls.signInUrl}>
						<LocalizedString lid={SIGN_IN_DROPDOWN_ITEM_TITLE} />
					</a>
				) : (
					[]
				)}
			</span>
		);
	}

	/**
	 * Generates a dropdown with a sign-in link along with the provided links
	 * @returns {jsx} The dropdown component rendering
	 */
	getSignInDropdownRendering() {
		const propsWithUrls = this.getPropsWithUrls();
		const signInDropdownTitle = <span className="hui-user-icon"></span>;

		return (
			<span className="hui-user">
				<DropdownButton bsStyle="link" id="hui-signin-dropdown" title={signInDropdownTitle} pullRight>
					{this.getLinkMenuItemRendering()}
					<MenuItem divider />
					<MenuItem key="signIn" eventKey="signIn" href={propsWithUrls.signInUrl}>
						<LocalizedString lid={SIGN_IN_DROPDOWN_ITEM_TITLE} />
					</MenuItem>
				</DropdownButton>
			</span>
		);
	}

	/**
	 * Generates the menu-item rendering for the links
	 * @returns {jsx} The links component rendering
	 */
	getLinkMenuItemRendering() {
		const propsWithUrls = this.getPropsWithUrls();
		return [
			<MenuItem key="downloadsLink" eventKey="downloadsLink" href={propsWithUrls.downloadsUrl}>
				<LocalizedString lid={BCC_HEADER_DOWNLOADS_LINK_TITLE} />
			</MenuItem>,
			<MenuItem key="communityLink" eventKey="communityLink" href={propsWithUrls.communityUrl} target="_blank">
				<LocalizedString lid={BCC_HEADER_COMMUNITY_LINK_TITLE} />
			</MenuItem>,
			<MenuItem key="supportLink" eventKey="supportLink" href={propsWithUrls.supportUrl} target="_blank">
				<LocalizedString lid={BCC_HEADER_SUPPORT_LINK_TITLE} />
			</MenuItem>,
		];
	}

	/**
	 * Generates the rendering for the account switcher menu items
	 * @param {bool} inUserDropdown governs whether or not to add a separator between Manage All and the other accounts
	 * @returns {jsx} The account switcher menu item components renderings
	 */
	getAccountSwitcherMenuItemsRendering(inUserDropdown: boolean = false) {
		if (!this.props.auth.accounts) {
			return null;
		}

		const sortedAccounts = this.props.auth.accounts.slice().sort((left, right) =>
			// make sure manage all is first
			this.isManageAllAccountsAccount(right.id)
				? 1
				: this.isManageAllAccountsAccount(left.id)
				? -1
				: left.name.localeCompare(right.name),
		);

		return sortedAccounts.map(({ id, name }) => {
			const selectedClass =
				(this.props.auth.all_accounts_selected && this.isManageAllAccountsAccount(id)) ||
				(!this.props.auth.all_accounts_selected && this.props.auth.account_id === id)
					? 'hui-menuitem-checked'
					: '';

			if (this.isDefaultAccount(id)) {
				name = (
					<span>
						{name} (<LocalizedString lid={ACCOUNT_DROPDOWN_DEFAULT} />)
					</span>
				);
			} else if (this.isManageAllAccountsAccount(id)) {
				name = <LocalizedString lid={ACCOUNT_DROPDOWN_MANAGE_ALL} />;
			}

			return [
				<MenuItem key={id} eventKey={id} className={selectedClass} onSelect={this.onChooseAccount.bind(this)}>
					{name}
				</MenuItem>,
				!inUserDropdown && this.isManageAllAccountsAccount(id) ? <MenuItem divider /> : null,
			];
		});
	}

	getProductClassName() {
		const productId = this.props.productId;
		const knownProductIds =
			'bbs ccb ccbb account support bcc x-bac cga ngf sdwan ess o365 fir pshln sntl waas ets mas vlm'.split(' ');
		return knownProductIds.indexOf(productId) !== -1 ? 'hui-header-product hui-header-product-' + productId : '';
	}

	/**
	 * Render the header
	 * @return {jsx} the jsx of the header.
	 */
	render() {
		const propsWithUrls = this.getPropsWithUrls();
		const userDropdown = this.getUserDropdownRendering();
		const accountDropdown = this.getAccountDropdownRendering();
		const linksRendering = this.getStandardLinksRendering();
		const productClassName = this.getProductClassName();

		return (
			<Header
				title={this.props.title}
				contentArea={this.props.contentArea}
				href={propsWithUrls.href}
				className={productClassName}
			>
				<Loading busy={!!this.props.auth.busy}>
					{linksRendering}
					{accountDropdown}
					{userDropdown}
				</Loading>
			</Header>
		);
	}
}
