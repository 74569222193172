import PropTypes from 'prop-types';
import React from 'react';
import Panel from './Panel';
import DataProvider from 'js/lib/classes/DataProvider';
import CardControllerView from 'js/lib/components/CardControllerView';

const PanelGroupPanelRepeater = props => {
	// make sure this is an array
	const dataset = props.data.map ? props.data : [props.data];

	return (
		<div className="hui-panelgroup">
			{dataset.map(data => (
				React.Children.map(props.children, child => (
					React.cloneElement(child, { panelGroupData: data })
				))
			))}
		</div>
	);
};

PanelGroupPanelRepeater.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]),
	children: PropTypes.node,
};

PanelGroupPanelRepeater.defaultProps = {
	data: [],
};

const PanelGroup = props => (
	<CardControllerView
		multiple
		{...props}
		dataProvider={props.dataProvider}
		cardId={props.id}
		useStoreForPanelGroupId={props.id}
	>
		{/*
			// Since we're using useStoreForPanelGroupId, we don't need this
			// but if React Fragments are implemented in Fiber, we can
			// throw out a ton of code and use it
			<PanelGroupPanelRepeater>
			{props.panels.map((panelData) => {
				return (
					<Panel
						key={panelData.id}
						{...panelData}
						autoRefresh={props.autoRefresh}
						autoRefreshInterval={props.autoRefreshInterval}
						filterRange={props.filterRange}
						selectedDeviceIds={props.selectedDeviceIds}
					/>
				);
			})}
		</PanelGroupPanelRepeater> */}
	</CardControllerView>
);

/**
 * Set up validation rules.
 */
PanelGroup.propTypes = {
	id: PropTypes.any.isRequired,
	panels: PropTypes.arrayOf(PropTypes.object),

	autoRefresh: PropTypes.bool,
	autoRefreshInterval: PropTypes.number,
	filterRange: PropTypes.oneOf(['day', 'week', 'month']),
	selectedDeviceIds: PropTypes.object,

	dataProvider: PropTypes.object,
};

PanelGroup.defaultProps = {
	panels: [],
	dataProvider: new DataProvider((tools, resolve, _reject) => {
		resolve([{}]);
	}),
};

export default PanelGroup;
