import PropTypes from 'prop-types';
import React from 'react';
import SidebarProduct from './SidebarProduct';
import 'scss/components/sidebar';

/**
 * Sidebar React component for a product category.
 */
class SidebarProductCategory extends React.Component {

	static propTypes = {
		title: PropTypes.string,
		products: PropTypes.array,
	};

	/**
	 * Renders the core React component.
	 * @return {jsx} the jsx view
	 */
	render() {
		const productRendering = this.props.products
			.map(product => <SidebarProduct key={product.id} {...product} />);

		if (!this.props.title) {
			return <div>{productRendering}</div>;
		}

		return (
			<div className="hui-sidebar-product-category-container">
				<div className="hui-sidebar-product-category">{this.props.title}</div>
				{productRendering}
			</div>
		);
	}
}

export default SidebarProductCategory;
