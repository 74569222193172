import React from 'react';
import BaseWrapper from '../BaseWrapper';
import Footer from './Footer';
// import actions from '../../classes/Actions';

/**
 * Class representing a dashboard.
 */
export default class FooterWrapper extends BaseWrapper {

	/**
	 * Create a footer.
	 *
	 * @param {Object} opts Options of the format:
	 *      {
	 *          id: a unique identifier for the dashboard
	 *      {
	 */
	constructor(opts) {

		super(opts, Footer);

		// actions.createFooter(opts);
	}

	/**
	 * Instantiates the React component.
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {

		return (
			<Footer key={this.reactProps.id} {...this.reactProps} />
		);
	}
}
