import React from 'react';
import BaseWrapper from '../BaseWrapper';
import Constants from 'js/lib/constants';
import { Dialog, DialogButton } from './Dialog';
// import actions from '../../classes/Actions';

/**
 * Class representing a dashboard.
 */
export default class DialogWrapper extends BaseWrapper {

	/**
	 * Create a footer.
	 *
	 * @param {Object} opts Options of the format:
	 *      {
	 *          id: a unique identifier for the dashboard
	 *      {
	 */
	constructor(opts) {
		super(opts, Dialog);
	}

	/**
	 * Instantiates the React component.
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		return (
			<Dialog key={this.reactProps.id} {...this.reactProps} />
		);
	}

	close() {
		global.document.body.removeChild(this.container);
	}

	static open({ title, content, buttons = [], curtain, closeBox, onCurtainClick, onCloseBoxClick }) {
		const container = global.document.createElement('div');
		const dialog = new DialogWrapper({
			id: 'hui.dialog.id-' + Math.random(),
			title,
			children: [content],
			buttons: buttons.map((props, idx) => (
				<DialogButton key={idx} {...props}>{[props.title]}</DialogButton>
			)),
			curtain,
			closeBox,
			onCurtainClick,
			onCloseBoxClick,
			container,
		});

		global.document.body.appendChild(container);

		dialog.render();

		return dialog;
	}

	static confirm({ title, content }) {
		return new Promise((resolve) => {
			const dialog = DialogWrapper.open({
				title,
				content,
				curtain: true,
				buttons: [{
					id: Constants.Dialog.BUTTON_ID_OKAY,
					title: 'OK',
					onClick: () => resolve([dialog, Constants.Dialog.BUTTON_ID_OKAY]),
					isDefault: true,
				}, {
					id: Constants.Dialog.BUTTON_ID_CANCEL,
					title: 'Cancel',
					onClick: () => resolve([dialog, Constants.Dialog.BUTTON_ID_CANCEL]),
					// isCancel: true,
				}],
			});
		});
	}

	static alert({ title, content }) {
		return new Promise((resolve) => {
			const dialog = DialogWrapper.open({
				title,
				content,
				curtain: true,
				buttons: [{
					title: 'OK',
					onClick: () => {
						if (resolve([dialog, Constants.Dialog.BUTTON_ID_OKAY]) !== false) {
							dialog.close();
						}
					},
					isDefault: true,
				}],
			});
		});
	}

	static info({ title, content }) {
		return new Promise((resolve) => {
			const dialog = DialogWrapper.open({
				title,
				content,
				curtain: true,
				closeBox: true,
				buttons: [],
				onCurtainClick: () => { dialog.close(); },
				onCloseBoxClick: () => { dialog.close(); },
			});
		});
	}
}
