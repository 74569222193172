/**
 * This helper provides a component with functionality that assists in js-driven
 * responsive layouts.
 *
 * Usage:
 * constructor() {
 *   this.beResponsive = beResponsive(this, debounceTimeInMsDefaultsTo250);
 * }
 * componentDidMount() {
 *   this.beResponsive();
 *   global.addEventListener('globalResize', this.beResponsive);
 * }
 * componentDidUpdate(prevProps, prevState) {
 *   this.beResponsive(prevState);
 * }
 * componentWillUnmount() {
 *   global.removeEventListener('resize', this.beResponsive);
 * }
 *
 * Requirements:
 *
 * beResponsive expects that the component using it has the following:
 *
 * An isLayoutBroken() function that returns a Boolean indicating if the layout
 *   is broken
 * A maxAlternateLayouts() function that returns how many different things the
 *   component can do to change its layout. This is the maximum number of
 *   render->measure->render steps that beResponsive will take (via setState).
 *
 * Behavior:
 *
 * beResponsive will set a `layout` property on the component's state.
 * The layout property will be of the format { stable: bool, alternateLayout: int }.
 *
 * The component's render function should look at `state.layout.alternateLayout` to
 * determine what changes to make to its layout in order to find a layout that
 * is not broken, as determined by the component's isLayoutBroken function. Each
 * number should represent a discrete combination of changes, not a single change,
 * as beResponsive will strictly increment the alternateLayout number as successive
 * renders fail to pass isLayoutBroken().
 *
 * If the component does not have an isLayoutBroken() function _and_ a
 * maxAlternateLayouts() function, beResponsive will do nothing. This is checked
 * at the time the initial beResponsive function-factory function is called.
 * Additionally, if the maxAlternateLayouts() function returns 0, it will do nothing.
 * This is checked each time the resultant function is called.
 *
 * The component will experience a setState and render call for each layout
 * attempt, up to a max of maxAlternateLayouts() per call to beResponsive().
 */

import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

/**
 * Kicks off the iterative layout process
 * @param   {object} component  The React component to layout
 * @param   {object} prevState  optional, the prevState from componentDidUpdate
 */
function respond(component, prevState) {
	if (componentDidUpdateDueToBeResponsive(component, prevState)) {
		// If the state changes were due to beResponsive, don't start another go
		return;
	}
	component.setState(
		{
			layout: {
				stable: false,
				alternateLayout: 0,
			},
		},
		fixLayout.bind(null, component),
	);
}

/**
 * Iterates the layout process
 * @param   {object} component  The React component to layout
 */
function fixLayout(component) {
	const maxAlternateLayouts = component.maxAlternateLayouts();
	if (component.isLayoutBroken() && component.state.layout.alternateLayout < maxAlternateLayouts) {
		component.setState(
			{
				layout: {
					alternateLayout: component.state.layout.alternateLayout + 1, // new value
					stable: false, // same as before
				},
			},
			fixLayout.bind(null, component),
		);
	} else {
		component.setState({
			layout: {
				alternateLayout: component.state.layout.alternateLayout, // same as before
				stable: true, // new value
			},
		});
	}
}

/**
 * Checks parameters to see if a call to respond is from changes made by beResponsive
 * @param   {object} component  The component
 * @param   {object} prevState  The prevState from componentDidUpdate
 * @returns {bool}              If beResponsive caused this call
 */
function componentDidUpdateDueToBeResponsive(component, prevState) {
	// prevState should only be passed in on a componentDidUpdate.
	// If it's not there, then it wasn't a call from componentDidUpdate.
	if (!prevState) {
		return false;
	}
	// If the state is the same except for layout and layout is different,
	// then the componentUpdate was caused by beResponsive
	return (
		isEqual(omit(prevState, 'layout'), omit(component.state, 'layout')) &&
		!isEqual(prevState.layout, component.state.layout)
	);
}

/**
 * A factory function that provides a function that can be called for
 * iterative, alternateLayout-based layout changes. This will also add an empty
 * object on state.layout without triggering a setState if there is no state.layout.
 * @param   {object} component  The React component to make responsive
 * @param   {int} debounceTime  The int (in ms) to pass to debounce
 * @returns {function}          A beResponsive function debounced and bound to
 *                              the provided component.
 */
function beResponsive(component, debounceTime = 100) {
	if (component.state.layout === undefined) {
		component.state.layout = {};
	}
	if (!isFunction(component.isLayoutBroken) || !isFunction(component.maxAlternateLayouts)) {
		return () => {};
	}
	return debounce(respond.bind(null, component), debounceTime);
}

export default beResponsive;
