/* global HUI, HUI_API_ENV */

import Log from 'js/lib/classes/Log';
import { Sidebar as Constants } from 'js/lib/constants';

/**
 * Builds a query string.
 * @param  {Object} paramsObj keys and values to build from
 * @return {string}           the newly minted query string
 */
function buildQueryString(paramsObj) {
	return '?' + Object.entries(paramsObj)
		.map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
		.join('&');
}

export const BACTreeNodesDataProvider = new HUI.Providers.DataProvider((tools, resolve, reject) => {
	const wwwUrl = `https://${tools.urls.bac_www}/cgi-mod/index.cgi`;

	if (!tools.urls.bac) {
		Log.warn(`No BAC API available for environment '${HUI_API_ENV}'.`);
		resolve([]);
		return false;
	}

	return Promise.all([
		tools.auth,
		tools.entitlements,
	]).then(([auth, entitlements]) => {

		if (!entitlements.bac) {
			Log.info('Skipping BAC API call because account ' + auth.account_id +
				' does not have an entitlement thereto.');
			// we have to return empty here, or it won't get zeroed out against
			// sessionstorage cache  upon load, or when switching to the account
			resolve([]);
			return false;
		}

		return tools.ajax({
			url: `https://${tools.urls.bac}/dashboard/v1/tree`,
			// make sure that the ajax call is not reused when the account id changes
			_cachebust: auth.account_id,
		}).then(([code, response]) => {
			if (code !== 200) {
				reject(new Error('Could not get BAC tree nodes.'));
				return;
			}

			let result = [];

			const productNamesMap = {
				emailswitch: 'Email Security Gateway',
				spyware: 'Web Security Gateway',
				bfw: 'Next Gen Firewall X-Series',
				bws: 'Web Application Firewall',
				archiver: 'Message Archiver',
				balancer: 'Load Balancer',
				bwb: 'Link Balancer',
			};

			const productSortOrderMap = {
				emailswitch: 0,
				spyware: 1,
				bfw: 2,
				bws: 3,
				archiver: 4,
				balancer: 5,
				bwb: 6,
			};

			try {
				const data = JSON.parse(response);

				result = data.devices.map(device => {

					// match tags to devices
					const tagsForDevice = data.tags.map(tag => {
						return (tag.children.indexOf(device.serial) !== -1) ? {
							id: tag.id,
							title: tag.tag,
							href: wwwUrl + buildQueryString({
								tag_id: tag.id,
								tree_filter: (() => {
									// get a list of device types on this tag
									const types = tag.children.map(serial => (
										data.devices.find(d => (
											serial === d.serial
										)).type
									));
									// reduce to unique types; return first if only one, else 'bccadmin'
									return new Set(types).size === 1 ? types[0] : 'bccadmin';
								})(),
								tree_name: 'devices',
								...(tools.searchBoxValue ? { tree_search: tools.searchBoxValue } : {}),
							}),
						} : null;
					}).filter(tag => tag);

					// HACK requested by BAC
					if (device.serial === 'BESS') {
						device.type = 'emailswitch';

						// if this is already set, it won't be overwritten farther down
						device.href = `https://${tools.urls.ess_www}/?force=cpl`;
					}

					// hide serial for CPL object
					const title = device.serial !== 'BESS'
						? `${device.name} [${device.serial}]`
						: device.name;

					const groups = productNamesMap[device.type] ? [
						{
							id: device.type,
							title: productNamesMap[device.type],
							groupProductId: device.type,
							href: wwwUrl + buildQueryString({
								tag_id: device.tag_id,
								user: auth.username,
								tree_filter: device.type,
								tree_name: 'devices',
								...(tools.searchBoxValue ? { tree_search: tools.searchBoxValue } : {}),
							}),
							sortOrder: productSortOrderMap[device.type],
						},
					] : false;

					return {
						id: device.serial,
						itemType: Constants.productChildTypes.DEVICE,
						serial: device.serial,

						// use device.href if already set (set earlier if CPL/BESS)
						href: device.href || wwwUrl + buildQueryString({
							nodeid: device.node_id,
							tag_id: device.tag_id,
							user: auth.username,
							tree_filter: device.type,
							tree_name: 'devices',
							...(tools.searchBoxValue ? { tree_search: tools.searchBoxValue } : {}),
						}),
						title,
						groupProductId: device.type,
						groupProductTitle: productNamesMap[device.type],
						// productId: device.type, // TODO: use when we promote BAC products to top-level
						productId: 'bac',
						groups,
						tags: tagsForDevice,
						status: device.connected ? 'good' : 'bad',
						rawApiData: device,
					};
				});

				sortBessToTopOfEsg(result);

				// add tags button to bottom of result array
				result.push({
					id: 'bac.tree.manageTags',
					itemType: Constants.productChildTypes.BUTTON,
					title: 'Manage Tags',
					productId: 'bac',
					onClick: () => {
						try {
							// Opens the manage tags dialog
							global.showTagsDialog();
							tools.log.info('Opening tags window.');
						} catch (e) {
							tools.log.info('Could not open tags window: ', e);
						}
					},
				});
			} catch (e) {
				reject(new Error('Could not decode BAC tree nodes response.'));
				return;
			}

			resolve(result);
		});
	});
});

/**
 * Sorts the BESS/Cloud Protection Layer device to the front of the list
 * @param   {array} results  The processed device results
 */
function sortBessToTopOfEsg(results) {
	const bess = results.filter(device => device.serial === 'BESS')[0];
	if (bess) {
		results.splice(results.indexOf(bess), 1);
		results.splice(0, 0, bess);
	}
}
