import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/dialog.scss';
import 'scss/lib/buttons.scss';

const Dialog = ({ curtain, children, buttons, title, closeBox, onCurtainClick, onCloseBoxClick }) => (
	<div className="hui-dialog-wrapper">

		{curtain ? <div className="hui-dialog-curtain" onClick={onCurtainClick} /> : ''}

		<div className="hui-dialog-container">
			<DialogContents {...{ children, buttons, title, closeBox, onCloseBoxClick }} />
		</div>
	</div>
);

Dialog.propTypes = {
	buttons: PropTypes.array,
	title: PropTypes.node,
	curtain: PropTypes.bool,
	closeBox: PropTypes.bool,
	onCurtainClick: PropTypes.func,
	onCloseBoxClick: PropTypes.func,
	children: PropTypes.node,
};

Dialog.defaultProps = {
	onCurtainClick: () => {},
	onCloseBoxClick: () => {},
};

const DialogContents = ({children, buttons, title, closeBox, onCloseBoxClick}) => (
	<div>

		{closeBox ? (
			<a href="javascript:void(0)" onClick={onCloseBoxClick} className="hui-dialog-closebox">
				<div className="hui-dialog-closebox-icon">Close</div>
			</a>
		) : []}

		{title ? <div className="hui-dialog-title">{title}</div> : ''}

		<div className="hui-dialog-content">
			{children}
		</div>

		{buttons && buttons.length ? (
			<div className="hui-dialog-buttons">
				{buttons}
			</div>
		) : []}

	</div>
)

const DialogButton = ({ isDefault, isCancel, onClick, children }) => (
	<a href="javascript:void(0)"
		className={'btn btn-sm hui-dialogbutton ' +
			(isDefault ? 'btn-default hui-dialogbutton-isDefault ' : '') +
			(isCancel ? 'hui-dialogbutton-isCancel ' : '')}
		onClick={onClick}
	>{children}</a>
);

DialogButton.propTypes = {
	isDefault: PropTypes.bool,
	isCancel: PropTypes.bool,
	onClick: PropTypes.func,
	children: PropTypes.node,
};

export default Dialog;
export { Dialog, DialogContents, DialogButton };
