import PropTypes from 'prop-types';
import React from 'react';
import sidebarTreeUtils from '../../../utils/sidebarTreeUtils';
import SidebarTreeNode from '../SidebarTreeNode';
import HighlightText from 'js/lib/components/common/HighlightText';
import Actions from '../../../classes/Actions';
import 'scss/components/sidebar';

/**
 * Sidebar React component for an item group.
 */
class SidebarGroupNode extends SidebarTreeNode {

	static propTypes = {
		id: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]).isRequired,
		itemType: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		href: PropTypes.string,
		childItems: PropTypes.arrayOf(PropTypes.object),
		selections: PropTypes.shape({
			nodeDatas: PropTypes.array,
			selectNode: PropTypes.func,
			toggleNode: PropTypes.func,
		}),
		matchText: PropTypes.string,
		depth: PropTypes.number,
		isExpanded: PropTypes.bool,
		path: PropTypes.object,
	};

	static defaultProps = {
		childItems: [],
	};

	/**
	 * Event handler for a click on the device
	 * @param   {Event} evt  The browser click event
	 */
	onGroupClick(evt) {
		const { selections, matchText, depth, isExpanded, ...groupNodeData } = this.props;
		if (evt.ctrlKey || evt.metaKey) {
			this.props.selections.toggleNodeSelection(groupNodeData, evt);
		} else {
			this.props.selections.selectNode(groupNodeData, evt);
		}

		if (!this.props.isExpanded) {
			Actions.toggleSidebarGroupNodeExpansion(this.props.path, true);
		}
	}

	/**
	 * Event handler for a click on the device
	 * @param {SyntheticEvent} evt  the React SyntheticEvent from the user's click
	 */
	onExpandoClick(evt) {
		Actions.toggleSidebarGroupNodeExpansion(this.props.path, !this.props.isExpanded);
		evt.stopPropagation();
		evt.preventDefault();
	}

	/**
	 * Renders the core React component.
	 * @return {jsx} the jsx view
	 */
	render() {
		const childRendering = (this.props.isExpanded || this.containsSelectedNode()) ?
			sidebarTreeUtils.renderChildNodes(this.props.childItems, this.props.depth) : null;

		const expandedClass = (this.props.isExpanded || this.containsSelectedNode()) ?
			'hui-state-expanded' : '';

		const selectedClass = this.isSelected() ? 'hui-state-active' : '';
		const hiddenClass = this.isHidden() ? 'hui-sidebar-treenode-hidden' : '';

		return (
			<div className={`hui-sidebar-itemgroup-container ${selectedClass} ${hiddenClass}`}>
				<a
					onClick={this.onGroupClick.bind(this)} href={this.props.href}
					className={`hui-sidebar-itemgroup hui-sidebar-treenode
						hui-sidebar-treedepth-${this.props.depth} ${selectedClass}`
					}
				>
					<span className={`hui-sidebar-expando ${expandedClass}`}
						onClick={this.onExpandoClick.bind(this)}
					/>
					<span className="hui-sidebar-itemgroup-title">
						<HighlightText fullText={this.props.title}
							matchText={this.props.matchText}
						/>
					</span>
					{this.props.count ?
						<span className="hui-sidebar-itemgroup-count">{this.props.count}</span>
					: []}
				</a>
				<div className="hui-sidebar-itemgroup-children">
					{childRendering}
				</div>
			</div>
		);
	}
}

export default SidebarGroupNode;
