import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Banner as Constants } from 'js/lib/constants';

import 'scss/components/banner';

import { useCookies } from 'react-cookie';

const Banner = ({ contentArea, urls }) => {
	const [cookies, setCookie] = useCookies(['closedBannerForSecured22CustomerConference']);
	const cookieDomain = `.${urls.base}`;

	/**
	 * React lifecycle hook
	 */
	useEffect(() => {
		assertControlOverContentArea();

		return () => {
			accedeControlOverContentArea();
		};
	}, [cookies]);

	/**
	 * Adds CSS control classes to the element specified by props.contentArea
	 */
	function assertControlOverContentArea() {
		const el = global.document.querySelector(contentArea);
		if (el) {
			el.classList.add(Constants.contentAreaCssClass);
		}
	}

	/**
	 * Removes CSS control classes from the element specified by props.contentArea
	 */
	function accedeControlOverContentArea() {
		const el = global.document.querySelector(contentArea);
		if (el) {
			el.classList.remove(Constants.contentAreaCssClass);
		}
	}

	return (
		<div className={`hui-banner conferenceBanner qa-conference-banner`}>
			<div>
				<div className={'conferenceBannerIcon'} style={{ width: 24, height: 24 }}>
					<svg
						className="MuiSvgIcon-root jss155"
						focusable="false"
						viewBox="0 0 24 24"
						aria-hidden="true"
						height={24}
						width={24}
					>
						<path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
					</svg>
				</div>
				<div className={'conferenceBannerText'}>
					Join us at the Barracuda Secured.22 Virtual Customer Conference Sept. 13-14
				</div>
				<a href="https://barracuda.events/secured.22/register/bcc/" target="_blank" className={'conferenceBannerLink'}>
					Sign Up Now!
				</a>
			</div>
			<a
				href="#"
				onClick={(event) => {
					event.preventDefault();
					setCookie('closedBannerForSecured22CustomerConference', 1, {
						expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
						domain: cookieDomain,
						secure: true,
						path: '/',
					});
				}}
				className={'conferenceBannerCloseIcon'}
				style={{ width: 24, height: 24 }}
			>
				<svg
					className="MuiSvgIcon-root jss155"
					focusable="false"
					viewBox="0 0 24 24"
					aria-hidden="true"
					height={24}
					width={24}
				>
					<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
				</svg>
			</a>
		</div>
	);
};

Banner.propTypes = {
	contentArea: PropTypes.string,
	urls: PropTypes.object,
};

export default Banner;
