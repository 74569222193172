import PropTypes from 'prop-types';
import React from 'react';
import SidebarTreeNode from '../SidebarTreeNode';
import 'scss/components/sidebar';

/**
 * Sidebar React component for a button.
 */
class SidebarButtonNode extends SidebarTreeNode {

	static propTypes = {
		title: PropTypes.string.isRequired,
		depth: PropTypes.number,
		onClick: PropTypes.func,
		href: PropTypes.string,
	};

	static defaultProps = {
		onClick: function defaultButtonOnClick() {
			if (this.props.href) {
				location.href = this.props.href;
			}
		},
	};

	/**
	 * Renders the core React component.
	 * @return {jsx} the jsx view
	 */
	render() {
		const hiddenClass = this.isHidden() ? 'hui-sidebar-treenode-hidden' : '';

		return (
			<div className={`hui-sidebar-item-button hui-sidebar-treenode hui-sidebar-treedepth-${this.props.depth} ${hiddenClass}`}>
				<button
					type="button"
					href={this.props.href}
					onClick={this.props.onClick.bind(this)}
				>
					{this.props.title}
				</button>
			</div>
		);
	}
}

export default SidebarButtonNode;
