/**
 * Convert a text string to the HTML necessary to display that string. Creates entites
 * for reserved characters, and does not parse tags or entities.
 * @param {string} text Text to convert.
 * @returns {string} Resulting HTML string.
 */
export default text => {
	const node = global.document.createElement('div');
	node.textContent = text;
	return node.innerHTML;
};
