import PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CardTitle from './CardTitle';
import PaginationMarkers from './PaginationMarkers';
import constants from '../../constants';
import CardControllerView from '../CardControllerView';
import Actions from '../../classes/Actions';
import 'scss/components/panel';

/**
 * Class representing the react class for the panel.
 */
class Panel extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		id: PropTypes.any.isRequired,
		title: PropTypes.string.isRequired,
		width: PropTypes.number,
		gutter: PropTypes.number,
		size: PropTypes.oneOf(constants.Panel.SIZES),
		smartLayout: PropTypes.bool,
		cards: PropTypes.array,
		stack: PropTypes.bool,
		autoRefresh: PropTypes.bool,
		autoRefreshInterval: PropTypes.number,
		filterRange: PropTypes.string,
		activeCardIndex: PropTypes.number,
		selectedDeviceIds: PropTypes.object,
		noTitleDropdown: PropTypes.bool,
		panelGroupData: PropTypes.object,
		panelGroupBusy: PropTypes.bool,
		paginationStyle: PropTypes.oneOf(constants.Panel.MARKER_TYPES),
	};

	static defaultProps = {
		width: constants.Panel.WIDTH_DEFAULT,
		gutter: constants.Dashboard.GUTTER_DEFAULT,
		size: constants.Panel.SIZE_MD,
		smartLayout: true,
		noTitleDropdown: false,
		cards: [],
		activeCardIndex: 0, // Active card is the first by default
		panelGroupData: null,
		panelGroupBusy: null,
		paginationStyle: constants.Panel.MARKER_TYPE_DOT,
	};

	/**
	 * Sets the active card to the given index
	 * @param {int} index The index of the new active card
	 */
	setActiveCard(index) {
		Actions.setPanelActiveCard(this.props.id, index);
	}

	/**
	 * Returns the child element representing the active child Card
	 * @return {Object} The active card
	 */
	getActiveCard() {
		let activeCard;

		this.props.cards.forEach((card, index) => {
			if (this.isActiveCardIndex(index)) {
				activeCard = card;
			}
		});

		return activeCard;
	}

	/**
	 * Determines if the given index corresponds to the currently active child Card
	 * @param   {int}  childIndex The child element's index to test
	 * @return {Boolean}         Whether or not the given index is active
	 */
	isActiveCardIndex(childIndex) {
		return this.props.activeCardIndex === childIndex;
	}

	/**
	 * Creates the stacked panel rendering
	 * @param   {string} rootModifierClasses CSS classes to attach to the panel root
	 * @param   {object} panelStyles          Styles to attach to the panel root
	 * @returns {jsx}                        the component rendering
	 */
	getStackedPanelRendering(rootModifierClasses, panelStyles) {
		const passedProps = {
			autoRefresh: this.props.autoRefresh,
			autoRefreshInterval: this.props.autoRefreshInterval,
			filterRange: this.props.filterRange,
			selectedDeviceIds: this.props.selectedDeviceIds,
			panelGroupData: this.props.panelGroupData,
			panelGroupBusy: this.props.panelGroupBusy,
		};

		const cardComponents = this.props.cards.map((card, idx) => {
			const cardTitleProps = {
				id: this.props.id,
				title: card.title,
				titleNote: card.titleNote,
				noTitleDropdown: this.props.noTitleDropdown,
				cards: [card],
				setActiveCard: this.setActiveCard.bind(this),
				activeCardIndex: this.props.activeCardIndex,
				titleInfoIcon: card.titleInfoIcon,
			};

			return (
				<div key={idx} className="hui-panel-stack-wrapper">
					<div className="hui-panel-margin-container" style={panelStyles.marginContainer}>
						<div className="hui-stacked-panel-card-wrapper">
							<CardControllerView {...passedProps} dataProvider={card.dataProvider} cardId={card.id}>
								<CardTitle {...cardTitleProps} />

								<card.ReactClass key={card.id} {...card} />
							</CardControllerView>
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className={`hui-panel hui-stacked-panel ${rootModifierClasses}`} style={panelStyles.panel}>
				{ cardComponents }
				<div className="hui-pagination-markers"></div>
			</div>
		);
	}

	/**
	 * Creates the standard panel rendering
	 * @param   {string} rootModifierClasses CSS classes to attach to the panel root
	 * @param   {object} panelStyles         Styles to attach to the panel root
	 * @returns {jsx}                        the component rendering
	 */
	getStandardPanelRendering(rootModifierClasses, panelStyles) {
		const activeCard = this.getActiveCard();

		const passedProps = {
			autoRefresh: this.props.autoRefresh,
			autoRefreshInterval: this.props.autoRefreshInterval,
			filterRange: this.props.filterRange,
			selectedDeviceIds: this.props.selectedDeviceIds,
			panelGroupData: this.props.panelGroupData,
			panelGroupBusy: this.props.panelGroupBusy,
		};

		let activeCardRendering = null;
		if (activeCard) {
			const cardTitleProps = {
				id: this.props.id,
				title: this.props.title,
				titleNote: activeCard.titleNote,
				noTitleDropdown: this.props.noTitleDropdown,
				cards: this.props.cards,
				setActiveCard: this.setActiveCard.bind(this),
				activeCardIndex: this.props.activeCardIndex,
				titleInfoIcon: activeCard.titleInfoIcon,
			};

			activeCardRendering = (
				<CardControllerView
					{...passedProps}
					dataProvider={activeCard.dataProvider}
					key={activeCard.id}
					cardId={activeCard.id}
				>
					<CardTitle
						{...cardTitleProps}
					/>

					<activeCard.ReactClass {...activeCard} />
				</CardControllerView>
			);
		}

		return (
			<div
				className={`hui-panel ${rootModifierClasses}`}
				style={panelStyles.panel}
			>
				<div className="hui-panel-margin-container" style={panelStyles.marginContainer}>

					<ReactCSSTransitionGroup component="div" className="hui-panel-animation-container"
						transitionName="hui-panel-card-transition"
						transitionEnterTimeout={300}
						transitionLeaveTimeout={300}
					>
						{activeCardRendering}
					</ReactCSSTransitionGroup>

					<PaginationMarkers
						cards={this.props.cards}
						onClick={this.setActiveCard.bind(this)}
						activeCardIndex={this.props.activeCardIndex}
						markerStyle={this.props.paginationStyle}
					/>
				</div>
			</div>
		);
	}

	/**
	 * Render the core Panel React instance.
	 * @return {jsx} The Panel React instance
	 */
	render() {
		const sizingClass = this.props.size ? 'hui-panel-' + this.props.size : '';
		// Cards in stacked panels don't get their typical height.
		const panelClasses = this.props.stack ? '' : sizingClass;

		const panelStyles = this.props.smartLayout ? {
			panel: {
				width: this.props.width,
				flexGrow: this.props.width,
			},
			marginContainer: {
				marginLeft: this.props.gutter / 2,
				marginRight: this.props.gutter / 2,
			},
		} : {};

		// TODO: Probably set to a sensible default
		let output = '';

		if (this.props.stack) {
			output = this.getStackedPanelRendering(panelClasses, panelStyles);
		} else {
			output = this.getStandardPanelRendering(panelClasses, panelStyles);
		}

		return output;
	}
}

export default Panel;
