import Reflux from 'reflux';
import Cookie from 'js-cookie';
import Actions from 'js/lib/classes/Actions';
import Log from 'js/lib/classes/Log';
import merge from 'lodash/merge';

const LOCALE_EN_US = 'en_US';
const LOCALE_EN_QA = 'en_QA';

// Brings in getState() and _data
import StoreMixins from '../stores/StoreMixins';

const MessagesStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	init() {
		this._data = {
			messages: {},
			locale: 'en_US',
			messagesLoaders: [],
			thereAreNewMessagesLoaders: false,
		};

		// install our own HUI messages
		this.addMessagesLoader((locale) => {
			// eslint-disable-next-line import/no-dynamic-require
			try {
				return require('commondata/locale/' + locale + '/messages.txt');
			} catch (e) {
				Log.warn(`Could not load messages for locale ${locale}, will fall back to en_US.`);
				return {};
			}
		});
	},

	getData() {
		const data = this.getState();

		// we always need en_US
		// if we don't have it in the store yet, load it
		if (!this._data.messages[LOCALE_EN_US]) {
			this.loadLocaleMessages(LOCALE_EN_US);

			// try again (this will be skipped next time)
			return this.getData();
		}

		if (data.locale === LOCALE_EN_US) {
			data.messages = data.messages[LOCALE_EN_US] || {};
		} else if (data.locale === LOCALE_EN_QA) {
			data.messages = data.messages[LOCALE_EN_US] || {};

			// add [lid] to end of messages for QA
			if (data.messages) {
				Object.keys(data.messages).forEach((lid) => {
					data.messages[lid] += ` [${lid.replace('locale_text_')}]`;
				});
			}
		} else {
			// if we don't have the other locale in the store yet, load it
			if (!this._data.messages[data.locale]) {
				this.loadLocaleMessages(data.locale);

				// try again (this will be skipped next time)
				return this.getData();
			}

			// merge locale down into us
			data.messages = merge(data.messages[LOCALE_EN_US], data.messages[data.locale]);
		}

		return data;
	},

	/**
	 * Loads messages for the specified local from the messageLoaders, merging them
	 * @param {string} locale the locale for which to load messages
	 */
	loadLocaleMessages(locale = 'en_US') {
		let result = {};

		// run all messagesLoaders merging
		this._data.messagesLoaders.forEach((loaderFn) => {
			let messages = {};
			try {
				messages = loaderFn.call(null, locale);
			} catch (e) {
				Log.warn(`[HUI] Could not load messages for ${locale}, will fall back to en_US.`);
			}
			result = merge(result, messages);
		});

		this._data.messages[locale] = result;
	},

	onListenedStoreChange() {
		this.triggerWithFullData();
	},

	// maps to public action, should be called after cookie changes
	setLocale(locale = null) {
		const newState = this.getState();

		newState.locale = locale || Cookie.get('CLOUD_LOCALE') || 'en_US';

		this._data = newState;
		this.triggerWithFullData();
	},

	getLocale(short = false) {
		if (short) {
			return this.getState().locale.split('_').shift();
		}

		return this.getState().locale;
	},

	addMessagesLoader(loaderFn) {
		const newState = this.getState();

		newState.messagesLoaders.push(loaderFn);

		// clear messages so we re-build the cache
		newState.messages = {};

		this._data = newState;
		this.triggerWithFullData();
	},
});

export default MessagesStore;
