/* global HUI_API_ENV */
import Reflux from 'reflux';
import Actions from '../classes/Actions';
import Events from '../classes/Events';
import makeAjaxCall from 'makeAjaxCall';
import merge from 'lodash/merge';
import Log from 'js/lib/classes/Log';
import Constants from 'js/lib/constants';
import Cookie from 'js-cookie';

// 'urls' is a webpack alias that resolves differently in testing
import urls from 'urls';

// Brings in getState() and this._data
import StoreMixins from '../stores/StoreMixins';

const AppStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	_makeAjaxCall: makeAjaxCall,
	init() {
		// clear sidebar and refetch when account changes
		Events.addEventListener('accountSelectionChange', () => {
			Log.info('clearing devices');
			// Actions.clearProducts();
			Actions.clearDevices();

			Log.info('refetching products and devices');
			Actions.fetchProducts();
			Actions.fetchDevices();
		});
	},
	getState() {
		const state = merge({}, this._data);

		// maintain reference to auth
		if (this._data.auth) state.auth = this._data.auth;

		return state;
	},
	getData() {
		const appData = this.getState();

		// merge saved urls into defaults
		// if (HUI_API_ENV !== 'production' && appData.urls) {
		if (appData.urls) {
			appData.urls = merge({}, urls[appData.urlsEnv || HUI_API_ENV], appData.urls || {});
		} else {
			appData.urls = urls[appData.urlsEnv || HUI_API_ENV];
		}

		return appData;
	},

	/**
	 * Assists in maintaining the current_account_all cookie
	 * @param  {Boolean} isSelected   if Manage All is selected
	 * @param  {string}  cookieDomain the cookie's domain property
	 */
	persistIsAllAccountsSelected(isSelected: boolean, cookieDomain: string) {
		// save that manage all was chosen or not (as 1 or 0)
		Cookie.set('current_account_all', Number(isSelected), { path: '/', domain: `.${cookieDomain}`, secure: true });
	},

	setAuthAccount(accountId: number | string) {
		const newState = this.getState();

		if (!newState.auth) {
			return Promise.reject(new Error('No auth info'));
		}

		const oldAccountId = newState.auth.account_id;
		const oldAllAccountsSelected = newState.auth.all_accounts_selected;

		newState.auth.all_accounts_selected = accountId === Constants.Header.manageAllAccountsAccountId;

		const envUrls = this.getData().urls;

		// if 'manage all', save default to API, set internal and cookie to -1
		if (accountId === Constants.Header.manageAllAccountsAccountId) {
			this.persistIsAllAccountsSelected(true, envUrls.base);
			accountId = newState.auth.default_account; // eslint-disable-line no-param-reassign
		} else {
			this.persistIsAllAccountsSelected(false, envUrls.base);
		}

		newState.auth.account_id = accountId;
		newState.auth.accountChangeInProgress = true;

		this._data = newState;
		this.triggerWithFullData();

		return this._makeAjaxCall({
			method: 'POST',
			url: `https://${envUrls.bcc}/auth/account/`,
			body: `account_id=${accountId}`,
			headers: {
				'Content-Type': 'text/plain',
			},
		})
			.then(([code, rawResponse]) => {
				let response = {};
				try {
					response = JSON.parse(rawResponse);
				} catch (e) {
					// leave it empty
				}

				if (code !== 200 || !response.success) {
					throw new Error(response.error);
				}

				// accept a known set of permission values
				if (response.permissions) {
					const { has_admin, has_billing, is_msp, is_reseller, has_bos } = response.permissions;

					Object.assign(newState.auth, {
						has_admin,
						has_billing,
						is_msp,
						is_reseller,
						has_bos,
					});
				}

				Events.trigger('accountSelectionChange', {
					accountId: Number(
						newState.auth.all_accounts_selected ? Constants.Header.manageAllAccountsAccountId : accountId,
					),
					response,
				});

				newState.auth.accountChangeInProgress = false;

				this._data = newState;
				this.triggerWithFullData();
			})
			.catch((e) => {
				let errorMsg = 'Error switching to new account';

				if (e) {
					errorMsg += ': ' + e;
				}

				// TODO: replace with user-facing error message handling, once implemented
				global.alert(errorMsg); // eslint-disable-line no-alert
				newState.auth.account_id = oldAccountId;
				newState.auth.oldAllAccountsSelected = oldAllAccountsSelected;
				newState.auth.all_accounts_selected = oldAllAccountsSelected;
				newState.auth.accountChangeInProgress = false;

				this._data = newState;
				this.triggerWithFullData();

				Log.warn(errorMsg);

				throw errorMsg;
			});
	},
	setAuthInfo(authInfo: Object) {
		const newState = this.getState();

		newState.auth = authInfo;

		this._data = newState;
		this.triggerWithFullData();
	},
	setAuthBusy(busy: boolean) {
		const newState = this.getState();

		// create if not exists
		if (!newState.auth) {
			newState.auth = {};
		}

		newState.auth.busy = busy;

		this._data = newState;
		this.triggerWithFullData();
	},
	setUrlOverrides(newUrls: ?Object, newUrlsEnv: ?string) {
		const newState = this.getState();

		// create if not exists
		if (!newState.urls) {
			newState.urls = newUrls;
			newState.urlsEnv = newUrlsEnv;
		}

		// be annoying about it
		Log.info('Using API Overrides:', newUrls, newUrlsEnv);

		this._data = newState;
		this.triggerWithFullData();
	},
});

export default AppStore;
