import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/highlight';

/**
 * React Component Class for highlighting text for search matches and such.
 */
export default class HighlightText extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		fullText: PropTypes.string,
		matchText: PropTypes.string,
	};

	/**
	 * Creates markup for highlighting text.
	 * @param {string} fullText The full string.
	 * @param {string} highlightText The highlight string.
	 * @return {jsx} The HighlightText React instance
	 */
	getHighlightMarkup(fullText = '', highlightText = '') {
		const matchStartIndex = fullText.toLowerCase().indexOf(highlightText.toLowerCase());

		if (highlightText && matchStartIndex !== -1) {
			const matchEndIndex = matchStartIndex + highlightText.length;

			return (
				<span>
					{fullText.substring(0, matchStartIndex)}
					<span className="hui-highlight">
						{fullText.substring(matchStartIndex, matchEndIndex)}
					</span>
					{fullText.substring(matchEndIndex)}
				</span>
			);
		}

		return <span>{fullText}</span>;
	}

	/**
	 * Render the core HighlightText React instance.
	 * @return {jsx} The HighlightText React instance
	 */
	render() {
		return this.getHighlightMarkup(this.props.fullText, this.props.matchText);
	}

}
