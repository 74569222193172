import AppStore from 'js/lib/stores/AppStore';
import { NotSignedInError } from 'js/lib/exceptions';
import Log from 'js/lib/classes/Log';

let promise;

/**
 * auth singleton
 */
export default function authPromise() {
	return promise || (promise = new Promise((resolve, reject) => {
		let unListen;

		/**
		 * Tries to get auth from the AppStore.
		 * @return {boolean} Whether or not it was able to.
		*/
		function tryToResolveWithAuth() {
			const auth = AppStore.getData().auth;

			if (auth && !auth.busy) {
				if (auth.success === 'true') {
					Log.info('User is signed in as', auth.username);
					resolve(auth);
				} else {
					Log.warn('User is not signed in.');
					reject(new NotSignedInError('User is not signed in.'));
				}

				if (unListen) unListen();

				return true;
			}

			return false;
		}

		if (!tryToResolveWithAuth()) {
			try {
				unListen = AppStore.listen(tryToResolveWithAuth);
			} catch (e) {
				Log.warn(e);
			}
		}
	}));
}
