import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/error';
import Loading from './Loading';

class ErrorView extends React.Component {

	static propTypes = {
		onRetry: PropTypes.func,
	};

	state = {};

	/**
	 * React lifecycle hook
	 */
	componentWillReceiveProps() {
		this.setState({ retryRequested: false });
	}

	/**
	 * Click handler for the retry/reload button
	 */
	onRetryClick() {
		this.setState({ retryRequested: true });

		if (this.props.onRetry) {
			this.props.onRetry();
		}
	}

	/**
	 * @return {jsx} The react component to render
	 */
	render() {

		const retryRendering = this.props.onRetry ? (
			<div>
				<button className="btn btn-sm hui-button" onClick={this.onRetryClick.bind(this)}>
					Reload
				</button>
			</div>
		) : null;

		return (
			<Loading busy={this.state.retryRequested}>
				<div className="hui-error">
					<div className="hui-error-result">
						<div className="hui-error-icon" />
						<span className="hui-error-text">An error occurred while preparing this area.</span>
					</div>
					{ retryRendering }
				</div>
			</Loading>
		);
	}

}

export default ErrorView;
