import CardWrapper from '../CardWrapper';
import MapCard from './MapCard';

/**
 * Class representing a dashboard card.
 */
export default class MapCardWrapper extends CardWrapper {

	/**
	 * Creates a MapCard
	 * @param {Object} opts  see CardWrapper opts
	 */
	constructor(opts) {
		super(opts, MapCard);
	}
}
