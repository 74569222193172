import DataProvider from './DataProvider';
import AppStore from 'js/lib/stores/AppStore';
import merge from 'lodash/merge';

class State {
	state = {};

	/**
	 * No-op default callback
	 */
	callback() {}

	/**
	 * @constructor
	 * @param   {object}   initial   The initial state data
	 * @param   {Function} callback  The callback to call whenever new state data is set
	 */
	constructor(initial, callback) {
		this.state = initial;
		this.callback = callback;
	}

	/**
	 * Merges new state data onto the existing state
	 * @param {object} state  The new state data
	 */
	setState(state) {
		merge(this.state, state);
		this.callback(this.state);
	}
}

/**
 * MergedProvider combines the results of two or more DataProviders
 */
export default class MergedProvider extends DataProvider {
	/**
	 * Creates a new MergedProvider.
	 * @param {array} providers    The providers to merge
	 * @param {Function} callback  called when the request comes back.
	 * @constructor
	 */
	constructor(providers: Array, callback: ?Function) {
		// check required params before calling super
		if (!providers || !Array.isArray(providers)) {
			throw new Error('MergedProviders requires an array of DataProviders to merge.');
		}

		super(callback);

		// assign parameters (callback is handled by superclass)
		this.providers = providers;
	}

	/**
	 * Called when data is requested.
	 *
	 * @param {Function} resolve called when request returns.
	 * @param {Function} reject called when there is an error.
	 * @param {Object} target reference to the calling target
	 * @param {Object?} targetProps the calling target's props or nextProps
	 */
	getData(resolve, reject, target, targetProps) {
		this.getDataResolveCallback = resolve;
		this.getDataRejectCallback = reject;

		// merge this again so that target does not overlap between getData calls
		const tools = merge({}, this.tools);

		tools.target = target;

		// If targetProps are passed, they might be nextProps instead of props,
		// (if getData was called in a componentWillReceiveProps), so we use them instead
		const mostCurrentProps = targetProps || (target && target.props);

		if (mostCurrentProps) {
			tools.filterRange = mostCurrentProps.filterRange;
			tools.selectedDeviceIds = mostCurrentProps.selectedDeviceIds;
		}

		tools.urls = AppStore.getData().urls;

		let states;

		const callback = () => {
			const datas = states.map((s) => s.state.data).filter(Boolean);
			const errors = states.map((s) => s.state.error).filter(Boolean);
			const errorMessages = states.map((s) => s.state.errorMessage).filter(Boolean);
			const all = states.map((s) => (s.state.error ? new Error(s.state.errorMessage) : s.state.data));

			if (errors.length + datas.length === states.length) {
				if (!datas.length) {
					this.getDataRejectCallback(new Error(errorMessages.join('\n')));
				} else {
					try {
						this.callback(all, tools, resolve, reject);
					} catch (e) {
						reject(e);
					}
				}
			}
		};

		// here we are setting up a state object for each provider
		// this keeps track of data and errors for each
		// and would be a hell of a lot easier with promises, but we need
		// re-resolution. Maybe this is a job for a reactive/stream library.
		states = this.providers.map((provider) => {
			const state = new State(
				{
					data: false,
					error: false,
				},
				callback,
			);

			setImmediate(() => {
				provider.getData(
					(data) => {
						state.setState({ data, error: false });
					},
					(error) => {
						state.setState({ error: true, errorMessage: error.message });
					},
					target,
				);
			});

			return state;
		});
	}
}
