import PropTypes from 'prop-types';
import React from 'react';
import SidebarTreeNode from '../SidebarTreeNode';
import 'scss/components/sidebar';

/**
 * Sidebar React component for a tree node whose type doesn't match known types.
 */
class SidebarUnknownNode extends SidebarTreeNode {

	static propTypes = {
		title: PropTypes.string.isRequired,
		depth: PropTypes.number,
	};

	/**
	 * Renders the core React component.
	 * @return {jsx} the jsx view
	 */
	render() {
		const hiddenClass = this.isHidden() ? 'hui-sidebar-treenode-hidden' : '';

		return (
			<div className={`hui-sidebar-treenode hui-sidebar-treedepth-${this.props.depth} ${hiddenClass}`}>
				{this.props.title}
			</div>
		);
	}
}

export default SidebarUnknownNode;
