import PropTypes from 'prop-types';
import React from 'react';

const Categories = (props) => {
	return !props.categories ? <div /> : (
		<div className="hui-card-categories">
			{props.categories.map((category, idx) => {
				return (
					<div key={idx} className="hui-card-category">
						<div className="hui-card-category-value">{category.value}</div>
						<div className="hui-card-category-label">{category.label}</div>
					</div>
				);
			})}
		</div>
	);
};

Categories.propTypes = {
	categories: PropTypes.oneOfType([
		PropTypes.array.isRequired,
		PropTypes.bool.isRequired,
	]),
};

export default Categories;
