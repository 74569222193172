import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/scrolling';

/**
 * Scrolling
 *
 * @param  {props} props should contain:
 *      {
 *          children: inner contents to be shown
 *      }
 * @return {jsx} the contained children in a scrolling container
 */
export default class Scrolling extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		children: PropTypes.node.isRequired,
		scrollTo: PropTypes.object,
	}

	/**
	 * Scrolls Scrolling component to `scrollTo` element.
	 */
	scrollToElement() {
		// if an scrollTo element was specified
		if (this.props.scrollTo) {
			const bottomEdgeOfElement = this.props.scrollTo.offsetTop + this.props.scrollTo.clientHeight;
			const centerOfElement = this.props.scrollTo.offsetTop + (this.props.scrollTo.clientHeight / 2);
			const centerOfScrollingViewport = (this.scrollingEl.clientHeight / 2);

			// if the scrollTo element would appear offscreen (below the fold)
			if (bottomEdgeOfElement > this.scrollingEl.clientHeight) {
				// make the specified element appear directly in center of area
				this.scrollingEl.scrollTop = centerOfElement - centerOfScrollingViewport;
			}
		}
	}

	/**
	 * Called when component mounts
	 */
	componentDidMount() {
		// Need a timeout due to timing issues
		// http://stackoverflow.com/a/34999925/231730
		setTimeout(() => {
			this.scrollToElement();
		}, 0);
	}

	/**
	 * Render the core Dashboard React instance.
	 * @return {jsx} the jsx view
	 */
	render() {
		return (
			<div ref={(scrollingElement) => { this.scrollingEl = scrollingElement; }} className="hui-scrolling-context">
				{this.props.children}
			</div>
		);
	}
}
