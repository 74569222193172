import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Events from 'js/lib/classes/Events';
import throttle from 'lodash/throttle';
import { addClass, toggleClass } from 'js/lib/utils/cssClassUtils';

class StickyTable extends React.Component {
	static defaultProps = {
		pinningTop: 0,
		static: false,
	};

	constructor(...props) {
		super(...props);

		this.scrollStickyHeaders = this.scrollStickyHeaders.bind(this);
		this.throttledScrollStickyHeaders = throttle(
			global.requestAnimationFrame.bind(global, this.scrollStickyHeaders),
			10,
		);
	}

	componentWillMount() {
		Events.addEventListener('globalScroll', this.scrollStickyHeaders, true);
	}

	componentDidMount() {
		this.updateStickyHeaders();
	}

	componentDidUpdate() {
		this.updateStickyHeaders();
	}

	componentWillUnmount() {
		Events.removeEventListener('globalScroll', this.scrollStickyHeaders);

		if (this.scrollingNode) {
			this.scrollingNode.removeEventListener('scroll', this.throttledScrollStickyHeaders);
		}
	}

	updateStickyHeaders() {
		this.thead = ReactDOM.findDOMNode(this).getElementsByTagName('thead')[0];

		if (!this.props.static) {
			addClass(this.thead, 'hui-tablecard-thead-sticky');
		}

		// remove node scroll event if it's bound before reassigning this.scrollingNode (in case it's different)
		if (this.scrollingNode) {
			this.scrollingNode.removeEventListener('scroll', this.throttledScrollStickyHeaders);
		}

		// get scrolling node
		this.scrollingNode = this.getClosestScrollingNode(this.thead);

		// bind new event to node
		this.scrollingNode.addEventListener('scroll', this.throttledScrollStickyHeaders);
	}

	scrollStickyHeaders() {
		if (!this.props.static) {
			try {
				const pinningTop =
					typeof this.props.pinningTop === 'function' ? this.props.pinningTop() : this.props.pinningTop;
				const pageOffsetTop = -this.thead.parentNode.getBoundingClientRect().top;
				const nodeScrollTop = this.scrollingNode.scrollTop;
				const adjustment = nodeScrollTop + Math.max(0, pageOffsetTop + pinningTop - nodeScrollTop);

				this.thead.style.transform = 'translateY(' + adjustment + 'px)';

				toggleClass(this.thead, 'hui-tablecard-thead-sticking', adjustment);
			} catch (e) {
				// silent failure
				throw e;
			}
		}
	}

	getClosestScrollingNode(el) {
		if (el && el.style) {
			const style = global.getComputedStyle(el);

			return style.overflowY === 'scroll' || style.overflowY === 'auto'
				? el
				: this.getClosestScrollingNode(el.parentNode);
		}

		return global.document.body;
	}

	render() {
		return <div>{this.props.children}</div>;
	}
}

export default StickyTable;
