import Reflux from 'reflux';

/**
 * List all actions here
 */
const Actions = Reflux.createActions({
	'createDashboard': { sync: true },
	'addPanelsToDashboard': { sync: true },

	'createPanelGroup': { sync: true },
	'addPanelsToPanelGroup': { sync: true },

	'createPanel': { sync: true },
	'addCardsToPanel': { sync: true },
	'setPanelActiveCard': {},
	'makeCardActive': {},
	'setPanelGroupData': { sync: true },

	'createCard': { sync: true },
	'persistHtmlCardInputs': {},

	'initializeSidebar': { sync: true },
	'clearProducts': {},
	'fetchProducts': {},
	'clearDevices': {},
	'fetchDevices': {},
	'refreshDevices': {},
	'setProducts': { sync: true },
	'setTreeNodes': { sync: true },
	'setProductSearch': { sync: true },
	'setShowSelectionOnly': { sync: true },

	'changeSidebarVisibility': { sync: true },
	'changeSidebarVisibilityMobile': { sync: true },
	// Selection actions _must_ be { sync: true }, even if perhaps others don't
	'selectSidebarProduct': { sync: true },
	'selectSidebarNode': { sync: true },
	'toggleSidebarNodeSelection': { sync: true },
	'toggleSidebarGroupNodeExpansion': { sync: true },
	'shouldWrapTabs': { sync: true },

	'initializeHeader': { sync: true },

	'setAuthBusy': { sync: true },
	'setAuthInfo': { sync: true },
	'setAuthAccount': { sync: true },
	'setUrlOverrides': { sync: true },
	'setDashboardFilterRange': { sync: true },
	'setDashboardAutoRefresh': { sync: true },
	'setDashboardAutoRefreshInterval': { sync: true },
	'setDashboardScaling': { sync: true },

	'initializeNavbar': { sync: true },
	'selectNavbarTab': { sync: true },
	'setNavbarTabLoading': {},
	'alterNavbarTab': { sync: true },

	'createWrapper': { sync: true },

	'setLocale': { sync: true },
	'addMessagesLoader': { sync: true },
});

export const PublicActions = {
	'changeSidebarVisibility': Actions.changeSidebarVisibility,
	'changeSidebarVisibilityMobile': Actions.changeSidebarVisibilityMobile,
	'selectNavbarTab': Actions.selectNavbarTab,
	'setLocale': Actions.setLocale,
	'refreshDevices': Actions.refreshDevices,
	'makeCardActive': Actions.makeCardActive,
};

/**
 * All the actions created above will be available on this action
 * as callable functions (e.g Actions.setPanels())
 */
export default Actions;
