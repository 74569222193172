export default class Log {
	static LOG_LEVEL_OFF = 0;
	static LOG_LEVEL_ERROR = 1;
	static LOG_LEVEL_WARN = 2;
	static LOG_LEVEL_INFO = 3;
	static LOG_LEVEL_DEBUG = 4;

	/**
	 * Logs to the console at the info level.
	 * @return {void}
	 */
	static info(...args) {
		if (global.console && global.HUI_LOG_LEVEL >= Log.LOG_LEVEL_INFO) {
			global.console.info(...args);
		}
	}

	/**
	 * Logs to the console at the warn level.
	 * @return {void}
	 */
	static warn(...args) {
		if (global.console && global.HUI_LOG_LEVEL >= Log.LOG_LEVEL_WARN) {
			global.console.warn(...args);
		}
	}

	/**
	 * Logs to the console at the error level.
	 * @return {void}
	 */
	static error(...args) {
		if (global.console && global.HUI_LOG_LEVEL >= Log.LOG_LEVEL_ERROR) {
			global.console.error(...args);
		}
	}

	/**
	 * Logs to the console at the debug level.
	 * @return {void}
	 */
	static debug(...args) {
		if (global.console && global.HUI_LOG_LEVEL >= Log.LOG_LEVEL_DEBUG) {
			global.console.debug(...args);
		}
	}
}
