export const Panel = {
	SIZE_MD: 'md',
	SIZE_SM: 'sm',
	SIZE_INFINITE: 'infinite',

	WIDTH_DEFAULT: 300,
	HEIGHT_BY_SIZE: {
		md: 350,
		sm: 50,
		infinite: 'auto',
	},

	MARKER_TYPE_DOT: 'dot',
	MARKER_TYPE_TEXT: 'text',
	MARKER_TYPE_NONE: 'none',
};
Panel.SIZES = [Panel.SIZE_MD, Panel.SIZE_SM, Panel.SIZE_INFINITE];

Panel.MARKER_TYPES = [Panel.MARKER_TYPE_DOT, Panel.MARKER_TYPE_TEXT, Panel.MARKER_TYPE_NONE];

export const Dashboard = {
	GUTTER_DEFAULT: 8,
};

export const Sidebar = {
	productChildTypes: {
		GROUP: 'group',
		BUTTON: 'button',
		DEVICE: 'device',
	},
	homeProductId: 'bcc',
	homeProductServiceId: 100,
	homeProductTitle: 'Cloud Control',
	contentAreaCssClass: 'hui-contentarea-sidebar-symbiote',
	contentAreaInitialPositionCssClass: 'hui-contentarea-sidebar-initialposition',
	sidebarHiddenCssClass: 'hui-state-sidebar-hidden',
	sidebarHiddenMobileCssClass: 'hui-state-sidebar-hidden-mobile',
};

export const Footer = {
	contentAreaCssClass: 'hui-contentarea-footer-symbiote',
};

export const Header = {
	contentAreaCssClass: 'hui-contentarea-header-symbiote',
	manageAllAccountsAccountId: '-1',
	administrationBilling: 'billing',
};

export const Banner = {
	contentAreaCssClass: 'hui-contentarea-banner-symbiote',
	bannerHiddenCssClass: 'hui-state-banner-hidden',
};

export const Navbar = {
	contentAreaCssClass: 'hui-contentarea-navbar-symbiote',
};

export const Dialog = {
	BUTTON_ID_OKAY: 'okay',
	BUTTON_ID_CANCEL: 'cancel',
};

export default {
	Panel,
	Dashboard,
	Sidebar,
	Navbar,
	Banner,
	Header,
	Footer,
	Dialog,
	MOBILE_BREAKPOINT: 600,
};
