/* global HUI_API_ENV */

import React from 'react';
import BaseWrapper from '../BaseWrapper';
import Dashboard from './Dashboard';
import DashboardStore from '../../stores/DashboardStore';
import PanelGroupDataStore from '../../stores/PanelGroupDataStore';
import actions from '../../classes/Actions';
import ControllerView from '../ControllerView';
import App from 'js/lib/classes/App';

/**
 * Class representing a dashboard.
 */
export default class DashboardWrapper extends BaseWrapper {

	panels = [];

	initialProductId;

	/**
	 * Create a dashboard.
	 *
	 * @param {Object} opts Options of the format:
	 *      {
	 *          id: a unique identifier for the dashboard
	 *          container: an HtmlElement or unique CSS selector for the element's root
	 *          panels: a list of PanelWrapper objects
	 *          hideControlBar: boolean whether to hide it
	 *          urls: an object that overrides the environment's url list (dev/staging only)
	 *      }
	 */
	constructor(opts) {

		const myPanels = opts.panels || [];

		super(opts, Dashboard);

		// Set a parent reference on the PanelWrapper
		myPanels.forEach(panel => {
			panel.parent = this;
		});

		// then replace the PanelWrapper array with a panel id array
		this.reactProps.panels = myPanels.map(panel => panel.id);

		// save this for authcheckonce use
		this.initialProductId = opts.initialProductId;

		// allow overrides for urls.ini, but only in dev/staging
		// if (HUI_API_ENV !== 'production' && (opts.urls || opts.urlsEnv)) {
		if (opts.urls || opts.urlsEnv) {
			actions.setUrlOverrides(opts.urls, opts.urlsEnv);
		}

		actions.createDashboard(this.reactProps);
	}

	/**
	 * Adds a panel to this dashboard
	 * @param {PanelWrapper} panelWrapper - The panel to add
	 */
	addPanel(panelWrapper) {
		this.addPanels([panelWrapper]);
	}

	/**
	 * Adds panels to this dashboard
	 * @param {PanelWrapper[]} panelWrappers - An array of panels to add
	 */
	addPanels(panelWrappers) {
		if (!Array.isArray(panelWrappers) /* TODO: or it's not full of panels */) {
			// TODO: error
			return;
		}

		this.panels = this.panels.concat(panelWrappers);
		panelWrappers.forEach(panel => {
			panel.parent = this;
		});
		actions.addPanelsToDashboard(panelWrappers.map(panel => panel.id), this.id);
	}

	/**
	 * Instantiates the React component.
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		const dashboardId = this.id;

		App.checkAuthOnce(this.initialProductId);

		/**
		 * Finds the component's data from the stores
		 * @param   {Object} stores  The stores to combine
		 * @return {Object}  The dashboard component's data
		 */
		function getComponentDataFromStoreDatas(stores) {
			const dashboardData = stores.DashboardStore[dashboardId];

			return {
				...dashboardData,
				panelGroupData: stores.PanelGroupDataStore,
			};
		}


		return (
			<ControllerView
				transform={getComponentDataFromStoreDatas}
				component={Dashboard}
				stores={{ DashboardStore, PanelGroupDataStore }}
			/>
		);
	}
}
