/**
 * Renders a string to HTML to find the text.
 * @param {string} html to be used {string} The text content inside the HTML.
 * @returns {string} The text content inside the HTML.
 */
export default function getTextFromHtml(html) {
	const tempDiv = global.document.createElement('div');
	tempDiv.innerHTML = html;

	return tempDiv.textContent || tempDiv.innerText || html;
}
