import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/footer';
import { Footer as Constants } from '../../constants';
import { LocalizedString } from 'js/lib/i18n';
import {
	BCC_FOOTER_ALL_RIGHTS_RESERVED,
	BCC_FOOTER_PRIVACY_POLICY_LINK_TITLE,
	BCC_FOOTER_TERMS_OF_SERVICE_LINK_TITLE,
} from 'js/lib/lids';

/**
 * Stateless component for the footer.
 *
 * @param  {props} props should contain:
 *      {
 *          year: copyright year
 *          privacyUrl: url of the privacy page
 *          termsUrl: url of the terms of service page
 *      }
 * @return {jsx} the jsx for footer
 *
 * NOTE: the global URLs we need to be aware of (like privacy and terms) should be in a main file
 * along with the ones in the Header.
 */
class Footer extends React.Component {
	static propTypes = {
		contentArea: PropTypes.string,
	};

	/**
	 * React lifecycle hook
	 */
	componentDidMount() {
		this.assertControlOverContentArea();
	}

	/**
	 * React lifecycle hook
	 */
	componentWillUnmount() {
		this.accedeControlOverContentArea();
	}

	/**
	 * Adds CSS control classes to the element specified by props.contentArea
	 */
	assertControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.add(Constants.contentAreaCssClass);
		}
	}

	/**
	 * Removes CSS control classes from the element specified by props.contentArea
	 */
	accedeControlOverContentArea() {
		const el = global.document.querySelector(this.props.contentArea);
		if (el) {
			el.classList.remove(Constants.contentAreaCssClass);
		}
	}

	/**
	 * Renders the component.
	 * @return {jsx} The JSX view.
	 */
	render() {
		return (
			<div className="hui-footer">
				<span>&copy; {this.props.year} Barracuda Networks, Inc. </span>
				<LocalizedString lid={BCC_FOOTER_ALL_RIGHTS_RESERVED} />
				<span className="hui-link-divider">|</span>
				<a className="hui-href" href={this.props.privacyUrl}>
					<LocalizedString lid={BCC_FOOTER_PRIVACY_POLICY_LINK_TITLE} />
				</a>
				<span className="hui-link-divider">|</span>
				<a className="hui-href" href={this.props.termsUrl}>
					<LocalizedString lid={BCC_FOOTER_TERMS_OF_SERVICE_LINK_TITLE} />
				</a>
			</div>
		);
	}
}

/**
* Set up validation rules.
*/
Footer.propTypes = {
	year: PropTypes.number,
	privacyUrl: PropTypes.string,
	termsUrl: PropTypes.string,
};

/**
 * Set up default props.
 */
Footer.defaultProps = {
	year: (new Date()).getFullYear(),
	privacyUrl: 'http://barracuda.com/privacy',
	termsUrl: 'http://barracuda.com/terms',
};

export default Footer;
