import PropTypes from 'prop-types';
import React from 'react';
import SidebarTreeNode from '../SidebarTreeNode';
import Label from '../../common/Label';
import HighlightText from 'js/lib/components/common/HighlightText';
import 'scss/components/sidebar';

const noop = () => {};

/**
 * Sidebar React component for a device.
 */
class SidebarDeviceNode extends SidebarTreeNode {

	static propTypes = {
		id: PropTypes.string,
		title: PropTypes.string.isRequired,
		href: PropTypes.string,
		selections: PropTypes.shape({
			nodeDatas: PropTypes.array,
			selectNode: PropTypes.func,
			toggleNodeSelection: PropTypes.func,
		}),
		matchText: PropTypes.string,
		depth: PropTypes.number,
		label: PropTypes.object,
	};

	static defaultProps = {
		selectDevice: noop,
	};

	/**
	 * Event handler for a click on the device
	 * @param   {Event} evt  The browser click event
	 */
	onDeviceClick(evt) {
		const { selections, matchText, depth, ...deviceNodeData } = this.props;
		if (evt.ctrlKey || evt.metaKey) {
			evt.preventDefault(); // always prevent nav on multiselect
			this.props.selections.toggleNodeSelection(deviceNodeData, evt);
		} else {
			this.props.selections.selectNode(deviceNodeData, evt);
		}
	}

	/**
	 * Renders the core React component.
	 * @return {jsx} the jsx view
	 */
	render() {
		const selectedClass = this.isSelected() ? 'hui-state-active' : '';
		const hiddenClass = this.isHidden() ? 'hui-sidebar-treenode-hidden' : '';

		const href = this.props.href ? { href: this.props.href } : null;
		const label = this.props.label ? (
			<span className="hui-sidebar-label">
				<Label {...this.props.label} />
			</span>
		) : null;

		return (
			<a className={`
					hui-sidebar-device hui-sidebar-treenode ${selectedClass}
					hui-sidebar-treedepth-${this.props.depth}
					${hiddenClass}
				`}
				onClick={this.onDeviceClick.bind(this)}
				title={`${this.props.title} [${this.props.id}]`}
				{...href}
			>
				<span className="hui-sidebar-device-title">
					<span className={`hui-icon-device-status hui-icon-device-status-${this.props.status}`}/>
					<HighlightText fullText={this.props.title} matchText={this.props.matchText} />
				</span>
				{label}
			</a>
		);
	}
}

export default SidebarDeviceNode;
