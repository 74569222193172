import PropTypes from 'prop-types';
import React from 'react';

const Icon = (props) => {
	if (props.onClick) {
		return (
			<a onClick={props.onClick} className={`hui-icon-${props.icon}`}>
				{props.icon}
			</a>
		);
	}

	return <span className={`hui-icon-${props.icon}`}>{props.icon}</span>;
};

Icon.propTypes = {
	icon: PropTypes.string,
	onClick: PropTypes.func,
};

export default Icon;
