import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import 'scss/components/tooltip';

/**
 * React component Class representing a Tooltip trigger/anchor.
 */
export default class TooltipTrigger extends React.Component {

	static propTypes = {
		id: PropTypes.string,
		children: PropTypes.node,
		// see https://react-bootstrap.github.io/components.html#tooltips-props
		// for Tooltip options
		overlay: PropTypes.node,
	};

	static defaultProps = {
		trigger: 'click',
	};

	/**
	 * Calls the composed OverlayTrigger's hide function
	 */
	closeOverlay() {
		this.refs.overlayTrigger.hide();
	}

	/**
	 * Render the core TooltipTrigger React instance.
	 * @return {jsx} The TooltipTrigger React instance
	 */
	render() {
		const { children, overlay, ...wrappedComponentProps } = this.props;

		const tooltip = (
			<Popover className="hui-tooltip" id={this.props.id + 'Popover'}>
				<span onClick={this.closeOverlay.bind(this)}
					className="hui-tooltip-close-button"
				/>
				{overlay}
			</Popover>
		);

		return (
			<OverlayTrigger
				ref="overlayTrigger"
				{...wrappedComponentProps}
				overlay={tooltip}
			>
				{children}
			</OverlayTrigger>
		);
	}

}
