/* global HUI */

export const BCCProductsDataProvider = new HUI.Providers.DataProvider((tools, resolve, _reject) => {
	// if we authed successfully
	return tools.auth.then(auth => {

		// support Manage All by not sending -1 to getAccountServices
		const accountId = auth.all_accounts_selected ? auth.default_account : auth.account_id;

		// call fetchProducts with good values
		return fetchProducts(tools, accountId).then(resolve);
	// but if it's rejected
	}).catch(() => {
		// call fetchProducts anyway with empty values
		return fetchProducts(tools).then(resolve);
	});
});

/**
 * Builds a query string.
 * @param  {Object} paramsObj keys and values to build from
 * @return {string}           the newly minted query string
 */
function buildQueryString(paramsObj) {
	return '?' + Object.entries(paramsObj)
		.map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
		.join('&');
}

/**
 * Fetches products via ajax.
 *
 * @param  {object} tools the tools obejct passed from DP
 * @param  {number} accountId the account id if known
 * @return {Promise} the ajax promise
 */
function fetchProducts(tools, accountId = '') {
	// the CLOUD_AT cookie is httpOnly, so we can't access it to pass in `auth_token`
	// the falsey value indicates that the SOAP API server should check the cookie itself

	return tools.ajax({
		url: `https://${tools.urls.bcc_soap}/api/soap`,
		headers: {
			'Content-Type': 'text/xml; charset=utf-8',
			'SOAPAction': 'getAccountServices',
		},
		method: 'POST',
		body: `
			<?xml version="1.0" encoding="utf-8"?>
			<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:BarracudaCloudAPI">
				<x:Header/>
				<x:Body>
					<urn:getAccountServicesRequest>
						<urn:auth_token></urn:auth_token>
						<urn:account_id>${accountId}</urn:account_id>
					</urn:getAccountServicesRequest>
				</x:Body>
			</x:Envelope>
		`.trim(),
	}).then(([code, response]) => {
		return new Promise((resolve, reject) => {
			if (code !== 200) {
				reject(new Error('Received error during ajax request'));
			}

			let result = tools.parseXMLViaDOMParser(response);
			let output;

			try {
				result = result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ns1:getAccountServicesResponse'];
				output = result.services.map(service => ({
					id: service.abbr,
					serviceId: Number(service.id),
					title: service.title,
					href: service.href + (
							tools.searchBoxValue
								? buildQueryString({ tree_search: tools.searchBoxValue })
								: ''
						),
					target: service.link_target || null,
					isDisabled: !Number(service.enabled),
					navCallout: service.nav_callout || null,
						// category: (currently unused)
				}));
			} catch (e) {
				output = [];
			}

			resolve(output);
		});
	});
}
