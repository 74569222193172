import React from 'react';
import Card from '../Card';
import Loading from '../../common/Loading';
import ErrorView from '../../common/ErrorView';
import StickyTable from '../../common/StickyTable';
import Watermark from '../stateless/Watermark';
import { LocalizedString } from 'js/lib/i18n';
import { DASHBOARD_TABLE_CARD_EMPTY } from 'js/lib/lids';
import Log from '../../../classes/Log';

/**
 * DataProviders for TableCard are expected to provide responses in the following format:
 *
 * {
 *   dataset: [{
 *     colName: value, // a simple value
 *     colName: {      // or separate the display value from the sorting value
 *       sortValue: value,
 *       displayValue: formattedValue,
 *     }
 *   }, ...],
 *   tableConfig: { an optional TableConfig object as defined in lib/schemas/TableConfig.js }
 * }
 */

export default class TableCard extends Card {
	state = {
		// give this a default so React doesn't complain
		// when it is instantiated before we've loaded the real lib
		// and so we have a fallback
		Reactable: { Table: ErrorView },
		libsLoaded: false,
	};

	static defaultProps = {
		data: {
			dataset: [],
			sticky: false,
		},
	};

	/**
	 * React life-cycle.
	 */
	componentWillMount() {
		this.mounted = true;

		require.ensure([], require => {
			// A high chance that this component has been unmounted (and not yet remounted)
			// due to how we handle moving panels around on startup. Double check.
			if (!this.mounted) {
				return;
			}

			const Reactable = require('reactable');

			this.setState({
				libsLoaded: true,
				Reactable,
			});
		});
	}

	/**
	 * React life-cycle.
	 */
	componentWillUnmount() {
		this.mounted = false;
	}

	/**
	 * Takes a tableConfig options object, either tableConfig.defaults or
	 * tableConfig.columns[colName], and returns the appropriate cell alignment
	 * css class.
	 * @param   {object} tableConfig   A tableConfig object.
	 * @param   {string} colKey        A column key.
	 * @param   {boolean} isHeader     Whether or not this cell is a header
	 * @returns {string}               The cell alignment css class.
	 */
	getCellAlignmentClass(tableConfig, colKey, isHeader) {

		let alignmentKey = '';

		if (tableConfig && tableConfig.columnDefaults) {
			// Prefer headerAlign on header, but if its not set, use align
			alignmentKey = (isHeader && tableConfig.columnDefaults.headerAlign) ? tableConfig.columnDefaults.headerAlign : tableConfig.columnDefaults.align;
		}

		if (tableConfig && tableConfig.columns &&
				tableConfig.columns[colKey] &&
				(tableConfig.columns[colKey].align || (isHeader && tableConfig.columns[colKey].headerAlign))) {
			// Only override the default if there is a value for align
			alignmentKey = (isHeader && tableConfig.columns[colKey].headerAlign) ? tableConfig.columns[colKey].headerAlign : tableConfig.columns[colKey].align;
		}

		switch (alignmentKey) {
			case 'left': return 'hui-card-tablecell-align-left';
			case 'right': return 'hui-card-tablecell-align-right';
			case 'center': return 'hui-card-tablecell-align-center';
			default: return '';
		}
	}

	/**
	 * Takes a tableConfig options object, either tableConfig.defaults or
	 * tableConfig.columns[colName], and returns the appropriate highlight
	 * css class.
	 * @param   {object} tableConfig   A tableConfig object.
	 * @param   {string} colKey        A column key.
	 * @returns {string}               The highlight css class.
	 */
	getCellHighlightClass(tableConfig, colKey) {
		let highlightClass = '';

		if (tableConfig && tableConfig.columns &&
			tableConfig.columns[colKey] &&
			tableConfig.columns[colKey].highlight) {

			highlightClass =
				tableConfig.columns[colKey].highlight ? 'hui-table-highlight-cell' : '';
		}

		return highlightClass;
	}

	/**
	 * Gets the jsx for the table header, or null if the header shouldn't show
	 * @returns {jsx}  The table header component
	 */
	getTableHeaderRendering() {
		const Reactable = this.state.Reactable;

		try {
			const headerCellsRendering = Object
				.keys(this.props.data.dataset[0] || this.props.data.tableConfig.columns)
				.map((colName, colIdx) => {
					// get all classes needed
					let classes = [];
					classes.push(this.getCellAlignmentClass(this.props.data.tableConfig, colName, true));
					classes.push(this.getCellHighlightClass(this.props.data.tableConfig, colName));
					classes.push(this.props.data.tableConfig.alwaysShowSortArrows ? 'reactable-header-sort-always-show': '');
					classes = classes.join(' ');

					let colDisplayName;
					try {
						const displayValue = this.props.data.tableConfig.columns[colName].displayValue;
						colDisplayName = displayValue ? this.state.Reactable.unsafe(displayValue) : colName;
					} catch (e) {
						colDisplayName = colName;
					}

					return (
						<Reactable.Th column={colName} key={colIdx} className={classes}>
							{colDisplayName}
						</Reactable.Th>
					);
				});

			return (
				<Reactable.Thead key="thead">
					{headerCellsRendering}
				</Reactable.Thead>
			);
		} catch (e) {
			return [];
		}
	}

	/**
	 * Gets an array of the jsx for the table body
	 * @returns {array}  An array of table contents nodes
	 */
	getTableBodyRendering() {
		const Reactable = this.state.Reactable;

		try {
			const bodyRowsRendering = this.props.data.dataset.map((row, rowIdx) => {
				const cellsRendering = Object.keys(row).map((colName, colIdx) => {
					// get all classes needed
					let classes = [];
					classes.push(this.getCellAlignmentClass(this.props.data.tableConfig, colName, false));
					classes.push(this.getCellHighlightClass(this.props.data.tableConfig, colName));
					classes = classes.join(' ');

					const value = row[colName];
					const hasSortValue = value.sortValue !== undefined;
					const hasDisplayValue = value.displayValue !== undefined;

					if ((hasSortValue && !hasDisplayValue) ||
							(!hasSortValue && hasDisplayValue)) {
						Log.warn('TableCard: Attempt to render table cell with only one of displayValue or sortValue');
					}

					const rowValue = hasSortValue ? value.sortValue : value;
					const rowLabel = hasDisplayValue ? value.displayValue : value;

					return (
						<Reactable.Td
							column={colName}
							key={colIdx}
							className={classes}
							value={rowValue}
						>
							{rowLabel}
						</Reactable.Td>
					);
				});

				return (
					<Reactable.Tr key={rowIdx}>
						{cellsRendering}
					</Reactable.Tr>
				);
			});

			return bodyRowsRendering;
		} catch (e) {
			return [];
		}
	}

	/**
	 * Gets an array of the jsx for the table contents
	 * @returns {array}  An array of table contents nodes
	 */
	getTableContentsRendering() {
		// If we don't have Reactable yet, don't render the table contents
		if (!this.state.libsLoaded) {
			return [];
		}

		let contents;

		// no contents for table?
		if (this.props.data.dataset && !this.props.data.dataset.length) {
			contents = null;
		} else {
			contents = this.getTableBodyRendering();
		}


		return [this.getTableHeaderRendering(), contents];
	}

	/**
	 * Gets the hideHeader css class, if needed
	 * @returns {string}  The hideHeader css class, or an empty string if not needed
	 */
	getHideHeaderClass() {
		if (this.props.data && this.props.data.tableConfig &&
				this.props.data.tableConfig.hideHeaders) {
			return 'hui-table-hide-header';
		}

		return '';
	}

	/**
	 * Gets the rowSize css class, if needed
	 * @returns {string}  The rowSize css class, or an empty string if not needed
	 */
	getRowSizeClass() {
		if (this.props.data && this.props.data.tableConfig &&
				this.props.data.tableConfig.rowSize) {
			return 'hui-table-row-size-' + this.props.data.tableConfig.rowSize;
		}

		return '';
	}

	/**
	 * Gets the rowStriping css class, if needed
	 * @returns {string}  The rowStriping css class, or an empty string if not needed
	 */
	getRowStripingClass() {
		try {
			if (this.props.data.tableConfig.stripeRows === false) {
				return '';
			}
		} catch (e) {
			// Use the default
		}
		return 'hui-table-striped';
	}

	/**
	 * Gets the custom header rendering
	 * @returns {string}  The hideHeader css class, or an empty string if not needed
	 */
	getCustomHeaderRendering() {
		if (!this.props.data || !this.props.data.tableConfig || !this.props.data.tableConfig.title) {
			return null;
		}

		const tableConfig = this.props.data.tableConfig;

		const customHeader = [
			<div className="hui-card-tablecard-title" key="title">{tableConfig.title}</div>,
		];

		if (tableConfig.subtitle) {
			const subtitle = (
				<div className="hui-card-tablecard-subtitle" key="subtitle">
					{tableConfig.subtitle}
				</div>
			);

			if (tableConfig.subtitlePosition === 'above') {
				customHeader.unshift(subtitle);
			} else {
				customHeader.push(subtitle);
			}
		}

		return (
			<div className="hui-card-tablecard-title-section">
				{customHeader}
			</div>
		);
	}

	/**
	 * Gets the tableConfig sorting value
	 * @returns {array|boolean}  The ReacTable sorting config value
	 */
	getTableSorting() {
		try {
			if (this.props.data.tableConfig.sorting === undefined) {
				return true;
			}
			return this.props.data.tableConfig.sorting;
		} catch (e) {
			return true;
		}
	}

	/**
	 * Gets the tableConfig sorting value
	 * @returns {array|boolean}  The ReacTable sorting config value
	 */
	getTableFilters() {
		if (!this.props.data || !this.props.data.tableConfig || !this.props.data.tableConfig.filters) {
			return null;
		}

		return this.props.data.tableConfig.filters;
	}

	/**
	 * Render the core Card React instance.
	 *   Note that no title is rendered within the card.
	 * @return {jsx} the jsx view
	 */
	render() {
		try {
			if (this.props.error) {
				return (
					<div className="hui-card hui-card-tablecard">
						<Loading busy={this.props.busy}>
							<ErrorView onRetry={this.props.refetchData} />
						</Loading>
					</div>
				);
			}

			const tableContentsRendering = this.getTableContentsRendering();

			const hideHeaderClass = this.getHideHeaderClass();
			const rowSizeClass = this.getRowSizeClass();
			const rowStripingClass = this.getRowStripingClass();
			const customHeaderRendering = this.getCustomHeaderRendering();
			const noDataText = (this.props.data.tableConfig && this.props.data.tableConfig.noDataText)
				? this.props.data.tableConfig.noDataText
				: <LocalizedString lid={DASHBOARD_TABLE_CARD_EMPTY} />;

			let stickyPinningTop;

			try {
				stickyPinningTop = global.document.querySelectorAll('.hui-dashboard')[0].offsetTop;
			} catch (e) {
				// console.log('e:', e);
			}

			// if sticky was specified as a prop or dataprovider element, merge the two; else false
			const sticky = (this.props.sticky || this.props.data.sticky)
				? Object.assign(stickyPinningTop ? {
					pinningTop: stickyPinningTop,
				} : {}, this.props.sticky, this.props.data.sticky || {})
				: false;

			return (
				<div className={`hui-card hui-card-tablecard ${this.getCssIdClass()}`}>
					<Loading busy={!this.state.libsLoaded || this.props.busy}>
						{customHeaderRendering}
						<StickyTable {...sticky} static={!sticky}>
							<this.state.Reactable.Table
								className={`hui-table ${hideHeaderClass} ${rowSizeClass} ${rowStripingClass}`}
								sortable={this.getTableSorting()}
								filterable={this.getTableFilters()}
								hideFilterInput
								cellSpacing="0"
								noDataText={noDataText}
							>
								{tableContentsRendering}
							</this.state.Reactable.Table>
						</StickyTable>
						<Watermark message={this.props.data.watermark} />
					</Loading>
				</div>
			);
		} catch (e) {
			return (
				<div className="hui-card hui-card-tablecard">
					<Loading busy={this.props.busy}>
						<ErrorView onRetry={this.props.refetchData} />
					</Loading>
				</div>
			);
		}
	}
}
