import PropTypes from 'prop-types';
import React from 'react';
import PaginationDropdown from './PaginationDropdown';
import getTextFromHtml from '../../utils/getTextFromHtml';

/**
 * Class representing the React class for card titles
 */
export default class CardTitle extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		id: PropTypes.any,
		data: PropTypes.object,
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
		titleNote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		cards: PropTypes.array,
		setActiveCard: PropTypes.func,
		activeCardIndex: PropTypes.number,
		titleInfoIcon: PropTypes.node,
		noTitleDropdown: PropTypes.bool,
	};

	/**
	 * Render the core CardTitle React instance.
	 * @return {jsx} The CardTitle React instance
	 */
	render() {
		const titleInfoRendering = (this.props.data && this.props.data.titleInfoIcon) || this.props.titleInfoIcon;
		let titleNoteRendering;

		if ((typeof this.props.data.titleNote == "string")){
			const titleNoteHtml = (this.props.data && this.props.data.titleNote) || this.props.titleNote;
			const titleNoteText = getTextFromHtml(titleNoteHtml);
			titleNoteRendering = (titleNoteHtml && titleNoteText) ?
				<div
					className="hui-card-titlenote"
					title={titleNoteText}
					dangerouslySetInnerHTML={{ __html: titleNoteHtml }}
				/> : null;
		}
		else {
			titleNoteRendering = (
				<div className="hui-card-titlenote">
					{this.props.data.titleNote}
				</div>
			);
		}

		const title = (this.props.data && this.props.data.panelTitle)
			? this.props.data.panelTitle
			: this.props.title;

		if (this.props.cards.length > 1 && this.props.noTitleDropdown !== true) {
			const cardTitles = this.props.cards.map(card => card.title);

			return (
				<div className="hui-card-title-wrapper">
					<div className="hui-card-title hui-card-title-dropdown">
						<PaginationDropdown
							id={this.props.id + 'PaginationDropdown'}
							prefix={this.props.title}
							optionValues={cardTitles}
							onChange={this.props.setActiveCard}
							activeIndex={this.props.activeCardIndex}
						/>
					</div>
					{titleInfoRendering}
					{titleNoteRendering}
				</div>
			);
		}

		return (
			<div className="hui-card-title-wrapper">
				<div className="hui-card-title" title={title}>{title}</div>
				{titleInfoRendering}
				{titleNoteRendering}
			</div>
		);
	}

}
