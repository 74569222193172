import React from 'react';
import { WithPopover as ReactWithPopover } from 'react-with-popover';

// this is a wrapper component so we can add our HUI css classes as defaults
const WithPopover = props => {
	// add our hui class defaults
	const newProps = {
		...props,
		popoverClasses: [
			'hui-withpopover-wrapper',
			...(props.popoverClasses || []),
		],
		tetherOptions: {
			classPrefix: 'hui-withpopover-tether',
			...(props.tetherOptions || {}),
		},
	};

	return (
		<ReactWithPopover {...newProps}>
			{props.children}
		</ReactWithPopover>
	)
};

export default WithPopover;
export { WithPopover };
