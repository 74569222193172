/* global HUI_API_ENV */

import React from 'react';
import BaseWrapper from '../BaseWrapper';
import actions from '../../classes/Actions';
import BccHeader from './BccHeader';
import HeaderStore from '../../stores/HeaderStore';
import ControllerView from '../ControllerView';
import App from 'js/lib/classes/App';

/**
 * Class representing a dashboard.
 */
export default class HeaderWrapper extends BaseWrapper {
	initialProductId;

	/**
	 * Create a header.
	 *
	 * @param {Object} opts Options of the format:
	 *      {
	 *          id: a unique identifier for the dashboard
	 *          product: a string ??
	 *          user: a string containing the current user's username, to pass to dataProviders
	 *          container: an HtmlElement or unique CSS selector for the element's root
	 *          options: an object of behavior customization options
	 *      {
	 */
	constructor(opts) {
		super(opts, BccHeader);


		// save this for authcheckonce use
		this.initialProductId = opts.initialProductId;

		// allow overrides for urls.ini, but only in dev/staging
		// if (HUI_API_ENV !== 'production' && (opts.urls || opts.urlsEnv)) {
		if (opts.urls || opts.urlsEnv) {
			actions.setUrlOverrides(opts.urls, opts.urlsEnv);
		}

		actions.initializeHeader(opts);
	}

	/**
	 * Instantiates the React component.
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		App.checkAuthOnce(this.initialProductId);

		return (
			<ControllerView
				component={BccHeader}
				store={HeaderStore}
			/>
		);
	}
}
