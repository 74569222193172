import React from 'react';
import Card from '../Card';
import Loading from '../../common/Loading';
import ErrorView from '../../common/ErrorView';
import Categories from '../stateless/Categories';
import merge from 'lodash/merge';
import Events from '../../../classes/Events';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

const defaultConfig = {
	title: { text: null },
	chart: {
		// Preferably would be not defined since Highcharts will use parent's height,
		// But due to our nested markup, this is a better way.
		// Panel is 300px height w/ 1px border, so effective height is 298px
		height: 298,
		animation: false,
	},
	credits: { enabled: false },
	legend: { enabled: false },
	plotOptions: {
		map: {
			borderColor: '#DDDDDD',
			borderWidth: 0.4,
			nullColor: '#DDDDDD',
		},
	},
	series: [
		{
			name: 'Countries',
			// mapData, // added later in render (after load)
			color: '#736F6E',
			enableMouseTracking: false,
		},
	],
};

/**
 * Class representing the react class for the dashboard.
 */
export default class MapCard extends Card {
	state = {
		config: {},
		isPureConfig: false,

		// give this a default so React doesn't complain
		// when it is instantiated before we've loaded the real lib
		// and so we have a fallback
		Map: ErrorView,
		mapData: {},
		libsLoaded: false,
	};

	static defaultProps = {
		data: {},
	};

	/**
	 * Called when window has resized.
	 */
	reflowMap() {
		if (this.refs.mapCard) {
			const chart = this.refs.mapCard.getChart();
			chart.reflow();
		}
	}

	/**
	 * React life-cycle.
	 */
	componentWillMount() {
		require.ensure([], (require) => {
			const Highcharts = require('imports-loader?window=js/lib/utils/fakeWindow!Highcharts');
			require('HighchartsMore')(Highcharts);
			require('Highmaps')(Highcharts);
			// if we ever need to do lat/lon plotting on a map, we need proj4
			// require('imports-loader?a;window.proj4=proj4/lib/index!Highmaps')(Highcharts);
			const chartFactory = require('imports-loader?Highcharts=>true!chartFactory');
			const mapData = require('./../../../vendor/world-lowres.geo.json');
			// const theme = require('imports-loader?Highcharts=>{maps:{}}!exports?Highcharts.theme!../../../vendor/world');

			// override highcharts offset method since it doesn't use document.scrollingElement
			require('js/lib/utils/highchartsOffsetPatch')(Highcharts);

			this.setState({
				libsLoaded: true,
				Map: chartFactory('Map', Highcharts),
				mapData,
			});
		});

		this.setState({
			config: this.getChartConfig(this.props.data),
			isPureConfig: false,
		});
	}

	/**
	 * React life-cycle.
	 */
	componentDidMount() {
		Events.addEventListener('pageLayoutReflow', this.reflowMap.bind(this));
	}

	/**
	 * React life-cycle.
	 */
	componentDidUpdate() {
		if (this.refs.mapCard) {
			const chart = this.refs.mapCard.getChart();

			if (chart && this.props.data && this.props.data.series) {
				// Go through all the series from our dataprovider and
				// update the existing ones on our chart or add new ones.
				this.props.data.series.forEach((series, i) => {
					if (chart.series[i]) {
						chart.series[i].setData(series.data, true);
					} else {
						chart.addSeries(series);
					}
				});
			}
		}
	}

	/**
	 * builds a highcharts config
	 * @param  {object} data what to transform, usually comes from props
	 * @return {object}      config object for highcharts
	 */
	getChartConfig(data) {
		let adjustments = {};

		try {
			// adjust height
			if (data.categories) {
				adjustments = merge(adjustments, { chart: { height: 245 } });
			}
		} catch (e) {
			// do nothing
		}

		defaultConfig.series[0].mapData = this.state.mapData;

		// add mapData to each series
		if (data.series) {
			data.series.forEach((s) => {
				s.mapData = this.state.mapData;
			});

			// to merge with the map
			data.series.unshift({});
		}

		return merge({}, defaultConfig, adjustments, data);
	}

	/**
	 * React life-cycle.
	 */
	componentWillUnmount() {
		Events.removeEventListener('pageLayoutReflow', this.reflowMap.bind(this));
	}

	/**
	 * React life-cycle.
	 * @param   {object} nextProps  The component's soon-to-be props
	 */
	componentWillReceiveProps(nextProps) {
		const config = this.getChartConfig(nextProps.data);
		let isPureConfig;

		// special case: if everything is empty, force full redraw of graph
		// (needed for transitions and initial draw but mostly transitions)
		if (isEqual({}, this.props.data) && isEqual({}, nextProps.data)) {
			isPureConfig = false;
		} else {
			isPureConfig = isEqual(omit(this.props.data, 'series'), omit(nextProps.data, 'series'));
		}

		this.setState({ config, isPureConfig });
	}

	/**
	 * Render the core Card React instance.
	 * @return {jsx} the view
	 */
	render() {
		try {
			if (this.props.error) {
				return (
					<div className="hui-card hui-card-mapcard">
						<ErrorView onRetry={this.props.refetchData} />
					</div>
				);
			}

			return (
				<div className={`hui-card hui-card-mapcard ${this.getCssIdClass()}`}>
					<Loading busy={!this.state.libsLoaded || this.props.busy}>
						<this.state.Map isPureConfig={this.state.isPureConfig} config={this.state.config} ref="mapCard" />
						<Categories categories={this.state.config.categories} />
					</Loading>
				</div>
			);
		} catch (e) {
			return (
				<div className="hui-card hui-card-mapcard">
					<ErrorView onRetry={this.props.refetchData} />
				</div>
			);
		}
	}
}
