import PropTypes from 'prop-types';
import React from 'react';
import Log from 'js/lib/classes/Log';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import Actions from 'js/lib/classes/Actions';

const DELAY_BEFORE_GET_DATA_LOOKS_BUSY = 500;

/**
 * This class helps wire a dumb component to a store,
 * facilitating component refresh by passing down store data
 * as props.
 */
class CardControllerView extends React.Component {
	static propTypes = {
		dataProvider: PropTypes.object.isRequired,
		autoRefresh: PropTypes.bool,
		autoRefreshInterval: PropTypes.number,
		filterRange: PropTypes.string,
		selectedDeviceIds: PropTypes.object,
		children: PropTypes.node,
		cardId: PropTypes.string,
		useStoreForPanelGroupId: PropTypes.string,
		panelGroupData: PropTypes.object,
		panelGroupBusy: PropTypes.bool,
	};

	static defaultProps = {
		// transform: data => data,
	};

	state = {
		hasLoaded: false,
		cardProps: {
			data: {},
			busy: true,
		},
	};

	/**
	 * React lifecycle hook
	 */
	componentDidMount() {
		this.mounted = true;
		this.getDataNowAndOnInterval(this.props);
	}

	/**
	 * React lifecycle hook
	 * @param {Object} nextProps The component's soon-to-be props
	 */
	componentWillReceiveProps(nextProps) {
		if (
			nextProps &&
			(nextProps.cardId !== this.props.cardId ||
				nextProps.filterRange !== this.props.filterRange ||
				!isEqual(nextProps.selectedDeviceIds, this.props.selectedDeviceIds) ||
				!isEqual(nextProps.panelGroupData, this.props.panelGroupData) ||
				!isEqual(nextProps.panelGroupBusy, this.props.panelGroupBusy))
		) {
			this.getDataNowAndOnInterval(nextProps);
		}
	}

	/**
	 * React lifecycle hook
	 */
	componentWillUnmount() {
		this.mounted = false;
		this.acceptPushedUpdates = false;
		clearInterval(this.refreshInterval);
	}

	/**
	 * Requests fresh data from the dataProvider and opt triggers refresh
	 * @param   {object} props  The CardControllerView's props or nextProps
	 */
	getDataNowAndOnInterval(props) {
		this.acceptPushedUpdates = true;
		// fetch on leading edge
		this.getDataFromProvider(props);

		// set up periodic fetcher
		if (props.autoRefresh && !props.dataProvider.push) {
			this.refreshInterval = setInterval(() => {
				this.getDataFromProvider(props);
			}, props.autoRefreshInterval);
		} else {
			// clear if no longer autorefreshing
			clearInterval(this.refreshInterval);
		}
	}

	/**
	 * Sets cardProps data and causes a rerender
	 * FIXME: We should come up with a more straightforward data path for sending data
	 * to the card without waiting for DataProvider resolution, but this will have to do for now.
	 * @param {object} data Info that will be passed to the card as props
	 */
	setCardPropsNotFromProvider(data) {
		if (this.mounted) {
			this.setStore({
				cardProps: { data },
			});
		}
	}

	setStore(data = {}) {
		this.setState(data, () => {
			if (this.props.useStoreForPanelGroupId) {
				Actions.setPanelGroupData(this.props.useStoreForPanelGroupId, data);
			}
		});
	}

	getStore() {
		if (this.props.useStoreForPanelGroupId) {
			return this.props.panelGroupData || this.state;
		}

		return this.state;
	}

	/**
	 * Gets the Card's data from its dataProvider
	 * @param {object} props      The CardControllerView's props or nextProps
	 * @param {bool} bypassCache  Whether or not to bypass the dataProvider's cache
	 */
	getDataFromProvider(props, bypassCache) {
		const busyTimeout = setTimeout(
			() => {
				const busyCardProps = { busy: true };

				busyCardProps.data =
					this.getStore().cardProps && this.getStore().cardProps.data ? this.getStore().cardProps.data : {};

				if (this.mounted) {
					this.setStore({
						cardProps: busyCardProps,
					});
				}
			},
			this.getStore().hasLoaded ? 0 : DELAY_BEFORE_GET_DATA_LOOKS_BUSY,
		);

		try {
			const resolve = (data) => {
				// check if the selection is still the same as it was at call time
				if (!isEqual(props.selectedDeviceIds, this.props.selectedDeviceIds)) {
					Log.info('Data received for expired selection; discarding.');
				} else {
					// check if this card is mounted
					if (this.mounted) {
						this.setStore({
							hasLoaded: true,
							cardProps: {
								data,
								busy: this.props.panelGroupBusy,
							},
						});
						clearTimeout(busyTimeout);
					}
				}
			};
			const reject = (e) => {
				if (this.mounted) {
					this.setStore({
						cardProps: {
							error: true,
							errorMessage: e.message,
							data: {},
							busy: this.props.panelGroupBusy,
							refetchData: this.getRetryFn(props),
						},
					});
					clearTimeout(busyTimeout);
				}
			};
			const target = merge(this, { id: props.cardId });

			props.dataProvider.getData(resolve, reject, target, props, bypassCache);
		} catch (e) {
			Log.error(e, 'DataProvider was missing or threw an error');
			if (this.mounted) {
				this.setStore({
					cardProps: { error: true, errorMessage: e.message, data: {}, refetchData: this.getRetryFn(props) },
				});
				clearTimeout(busyTimeout);
			}
		}
	}

	/**
	 * Creates a function that retries a getDataFromProvider call with bound
	 * scope and curried props.
	 * @param   {object} props  The props for the getData call
	 * @returns {function}      The retry function
	 */
	getRetryFn(props) {
		return this.getDataFromProvider.bind(this, props, true);
	}

	acceptPushedUpdates = false;

	refreshInterval = null;

	/**
	 * @return {jsx} The react component to render
	 */
	render() {
		const newChildren = React.Children.map(this.props.children, (child) => {
			return React.cloneElement(child, this.getStore().cardProps);
		});

		return <div className="hui-card-controller">{newChildren}</div>;
	}
}

export default CardControllerView;
