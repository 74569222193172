import Reflux from 'reflux';
import Actions from '../classes/Actions';
import Log from '../classes/Log';
import PanelStore from './PanelStore';
import SidebarStore from './SidebarStore';

// Brings in getState() and _data
import StoreMixins from '../stores/StoreMixins';

const DashboardStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	init() {
		this.listenTo(PanelStore, this.onListenedStoreChange);
		this.listenTo(SidebarStore, this.onListenedStoreChange);
	},
	getData() {
		const dashboards = this.getState();
		const sidebar = SidebarStore.getData();

		Object.values(dashboards).forEach(dashboard => {
			dashboard.panels = dashboard.panels.map(panel => {
				return PanelStore.getPanel(panel);
			});

			if (sidebar) {
				dashboard.products = sidebar.products;
				dashboard.treeNodesByProvider = sidebar.treeNodesByProvider;
				dashboard.treeNodeGroupExpansionMap = sidebar.groupExpansionMap;
				dashboard.selections = sidebar.selections;
				dashboard.isSearching = sidebar.isSearching;
				dashboard.searchBoxValue = sidebar.searchBoxValue;
			}
		});

		return dashboards;
	},
	onListenedStoreChange() {
		this.triggerWithFullData();
	},
	createDashboard(opts) {
		if (!opts) throw Error('Dashboard options required');
		if (!opts.id) throw Error('Dashboard `id` is required');
		if (opts.panels && !Array.isArray(opts.panels)) {
			throw Error('Dashboard `panels` must be an array');
		}

		if (!opts.panels) {
			opts.panels = [];
		}

		const newState = this.getState();
		newState[opts.id] = opts;

		this._data = newState;
		this.triggerWithFullData();
	},

	addPanelsToDashboard(panelIds, dashboardId) {
		if (panelIds && !Array.isArray(panelIds)) throw Error('`panelIds` must be an array');
		if (panelIds && !panelIds.length) {
			Log.warn(`addPanelsToDashboard called on ${dashboardId} with no panelIds`);
		}
		const newState = this.getState();
		const dashboardState = newState[dashboardId];
		dashboardState.panels = dashboardState.panels.concat(panelIds);

		this._data = newState;
		this.triggerWithFullData();
	},
	setDashboardFilterRange(id:string, filterRange:string) {
		const newState = this.getState();

		newState[id].filterRange = filterRange;

		this._data = newState;
		this.triggerWithFullData();
	},
	setDashboardAutoRefresh(id:string, autoRefresh:boolean) {
		const newState = this.getState();

		newState[id].autoRefresh = autoRefresh;

		this._data = newState;
		this.triggerWithFullData();
	},
	setDashboardScaling(id:string, scaling:string) {
		const newState = this.getState();

		newState[id].scaling = scaling;

		this._data = newState;
		this.triggerWithFullData();
	},
	setDashboardAutoRefreshInterval(id:string, autoRefreshInterval:number) {
		const newState = this.getState();

		newState[id].autoRefreshInterval = autoRefreshInterval;

		this._data = newState;
		this.triggerWithFullData();
	},
});

export default DashboardStore;
