import PropTypes from 'prop-types';
import React from 'react';
import { Panel as constants } from '../../constants';
import 'scss/components/paginationMarkers';

const PaginationMarkers = (props) => {
	const pageMarkers = [];

	if (props.cards.length > 1) {

		if (props.markerStyle === constants.MARKER_TYPE_NONE) {
			// do nothing
		} else if (props.markerStyle === constants.MARKER_TYPE_TEXT) {
			props.cards.forEach((child, index) => {
				if (index !== 0) {
					pageMarkers.push(
						<span className="hui-pagination-marker-divider">|</span>
					);
				}

				if (index === props.activeCardIndex) {
					pageMarkers.push(
						<a
							key={index}
							className="hui-pagination-marker hui-pagination-marker-type-text hui-state-active"
						>{child.title}</a>
					);
				} else {
					pageMarkers.push(
						<a
							key={index}
							className="hui-pagination-marker hui-pagination-marker-type-text"
							onClick={props.onClick.bind(this, index)}
						>{child.title}</a>
					);
				}
			});
		} else { // constants.MARKER_TYPE_DOT
			props.cards.forEach((child, index) => {
				if (index === props.activeCardIndex) {
					pageMarkers.push(
						<div key={index} className="hui-pagination-marker hui-pagination-marker-type-dot hui-state-active" />
					);
				} else {
					pageMarkers.push(
						<div
							key={index}
							className="hui-pagination-marker hui-pagination-marker-type-dot"
							onClick={props.onClick.bind(this, index)}
						/>
					);
				}
			});
		}
	}

	return (
		<div className={`hui-pagination-markers hui-pagination-style-${props.markerStyle}`}>
			{pageMarkers}
		</div>
	);
};

PaginationMarkers.propTypes = {
	cards: PropTypes.array,
	markerStyle: PropTypes.oneOf(constants.MARKER_TYPES),
	activeCardIndex: PropTypes.number,
	onClick: PropTypes.func,
};

PaginationMarkers.defaultProps = {
	markerStyle: constants.MARKER_TYPE_DOT,
};

export default PaginationMarkers;
