import React from 'react';
import BaseWrapper from '../BaseWrapper';
import Panel from './Panel';
import PanelStore from '../../stores/PanelStore';
import PanelGroupDataStore from '../../stores/PanelGroupDataStore';
import actions from '../../classes/Actions';
import ControllerView from '../ControllerView';

/**
 * Class representing a dashboard panel.
 */
export default class PanelWrapper extends BaseWrapper {


	cards = [];

	/**
	 * Create a dashboard panel.
	 *
	 * @param {object} opts Options of the format
	 *      {
	 *          id: a unique identifier for the panel
	 *          title: a string containing the title of the panel
	 *          name: a string containing the panel's unique id ??
	 *          container: (optional) an HtmlElement or unique CSS selector for the element's root
	 *              use only to render directly
	 *          options: an object of behavior customization options
	 *          cards: a list of Card objects
	 *          width: an int of the minimum px width of the panel
	 *          gutter: an int of the px gutter between panels
	 *            should be inherited from parent dashboard
	 *          size: a string from Constants representing vertical size
	 *          smartLayout: a boolean used to disable advanced automatic layout features
	 *            should be inherited from parent dashboard,
	 *          stack: Makes cards stack vertically instead of paginating
	 *      }
	 * @constructor
	 */
	constructor(opts) {
		const myCards = opts.cards || [];
		super(opts, Panel);

		this._validateOptions(opts);

		// Set a parent reference on each CardWrapper
		myCards.forEach(card => {
			card.parent = this;
		});

		// then replace the CardWrapper array with a card id array
		this.reactProps.cards = myCards.map(card => card.id);

		actions.createPanel(this.reactProps);
	}

	/**
	 * Handle any component-specific warnings or exceptions here
	 * @param {object} opts Constructor options
	 * @private
	 */
	_validateOptions(opts) {
		if (!opts.title) {
			throw new Error('Title property is required to instantiate panel.');
		}
	}

	/**
	 * Adds a card to this panel
	 * @param {CardWrapper} cardWrapper  The card to add
	 */
	addCard(cardWrapper) {
		this.addCards([cardWrapper]);
	}

	/**
	 * Adds cards to this panel
	 * @param {CardWrapper[]} cardWrappers  An array of cards to add
	 */
	addCards(cardWrappers) {
		if (!Array.isArray(cardWrappers) /* TODO: or it's not full of cards */) {
			// TODO: error
			return;
		}

		this.cards = this.cards.concat(cardWrappers);
		cardWrappers.forEach(card => {
			card.parent = this;
		});
		actions.addCardsToPanel(cardWrappers.map(card => card.id), this.id);
	}

	/**
	 * Instantiates the React component for direct ReactDOM rendering (ie no parent).
	 * @return {jsx} the jsx view
	 */
	instantiateReactComponent() {
		const panelId = this.id;

		/**
		 * Finds the component's data from the full stores
		 * @param   {Object} stores the stores
		 * @return {Object}  The panel component's data
		 */
		function getComponentDataFromStoreDatas(stores) {
			const panelData = stores.PanelStore[panelId];

			return panelData;
		}

		return (
			<ControllerView
				transform={getComponentDataFromStoreDatas}
				component={Panel}
				stores={{ PanelStore, PanelGroupDataStore }}
			/>
		);
	}
}
