import PropTypes from 'prop-types';
import React from 'react';

const CardOverlay = (props) => {
	return props.content ? (
		<div className="hui-card-overlay">
			{props.content}
		</div>
	) : (
		<div />
	);
};

CardOverlay.propTypes = {
	content: PropTypes.oneOf([
		PropTypes.string,
		PropTypes.node,
	]),
};

export default CardOverlay;
