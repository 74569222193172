import PropTypes from 'prop-types';
import React from 'react';
import HuiPath from '../../classes/HuiPath';
import NavbarRow from './NavbarRow';
import NavbarWarning from './NavbarWarning';
import Log from '../../classes/Log';
import find from 'lodash/find';
import 'scss/components/navbar';

/**
 * A Navbar component
 */
class Navbar extends React.Component {
	static propTypes = {
		tabs: PropTypes.arrayOf(PropTypes.object),
		selectedPath: PropTypes.instanceOf(HuiPath),
		contentArea: PropTypes.string,
		leftControls: PropTypes.array,
		rightControls: PropTypes.array,
		wrapTabs: PropTypes.bool,
		warningMessage: PropTypes.string,
	};

	static defaultProps = {
		selectedPath: new HuiPath([]),
	};

	/**
	 * React lifecycle hook
	 */
	componentWillMount() {
		this.setState({ navbarRowsData: _getNavbarRowsData(this.props) });
	}

	/**
	 * React lifecycle hook
	 * @param {object} nextProps  The component's new props
	 */
	componentWillReceiveProps(nextProps) {
		this.setState({ navbarRowsData: _getNavbarRowsData(nextProps) });
	}

	/**
	 * Render method
	 *
	 * @return {jsx} the view
	 */
	render() {
		const navbarRowsData = this.state.navbarRowsData.slice();
		// The first row gets the extra controls, so pull it from the array
		const firstRow = navbarRowsData.shift();

		return (
			<div className="hui-navbar">
				<NavbarWarning message={this.props.warningMessage} />
				{this.props.tabs.length ? (
					<div>
						<NavbarRow
							tabs={firstRow}
							key={-1}
							depth={-1}
							leftControls={this.props.leftControls}
							rightControls={this.props.rightControls}
							selectedPath={this.props.selectedPath}
						/>
						{navbarRowsData.map((tabset, idx) => (
							<NavbarRow
								tabs={tabset}
								key={idx}
								depth={idx}
								selectedPath={this.props.selectedPath}
								wrapTabs={this.props.wrapTabs}
							/>
						))}
					</div>
				) : (
					[]
				)}
			</div>
		);
	}
}

export default Navbar;

/**
 * Gets tab arrays for nav bar rows based on selection
 * @param   {object} props       Props or nextProps
 * @param   {array} currentTabs  Optional, An array of pathified tab data
 *                               Uses props.tabs if not provided
 * @returns {array}              A 2D array of pathified tab data, including on the
 *                               selected path and the selected tab's children
 */
export function _getNavbarRowsData(props, currentTabs) {
	let tabs = currentTabs;
	if (!tabs) {
		tabs = props.tabs.slice();
		addPathToTabs(tabs);
	}

	let selectedTab;
	try {
		selectedTab = find(tabs, (tab) => {
			let selected = false;

			if (props.selectedPath) {
				selected = props.selectedPath.startsWith(tab.path);
			} else {
				selected = tab.isSelected;
			}

			return selected;
		});
	} catch (e) {
		// because props.selectedPath doesn't exist
	}

	if (!selectedTab || !selectedTab.childItems || !selectedTab.childItems.length || selectedTab.isHidden) {
		return [tabs];
	}

	return [tabs].concat(_getNavbarRowsData(props, selectedTab.childItems));
}

/**
 * Adds the appropriate HuiPath to each tab
 * @param {array} tabs      The tabs to add paths to
 * @param {HuiPath} prefix  The path to create new paths from
 */
export function addPathToTabs(tabs, prefix = new HuiPath([])) {
	tabs.forEach((tab) => {
		if (typeof tab.label !== 'string') {
			Log.error("HUI: A tab structure's label property must be a string.");
			// so further code doesn't break
			tab.label = '';
		}

		tab.path = prefix.makeChildPath(tab.label);
		if (Array.isArray(tab.childItems)) {
			addPathToTabs(tab.childItems, tab.path);
		}
	});
}

/**
 * Takes an array of tabs and flattens it
 * @param {array} tabs An array of tab data objects
 * @param {array} [accumulator] The accumulator object which gets returned
 * @returns {Array} An array of all tabs
 */
export function flattenTabs(tabs: Array, accumulator: ?Array = []) {
	let newTabs = accumulator.concat(tabs);

	tabs.forEach((tab) => {
		if (tab.childItems) {
			newTabs = flattenTabs(tab.childItems, newTabs);
		}
	});

	return newTabs;
}
