import PropTypes from 'prop-types';
import React from 'react';

/**
 * Sidebar React component for a node in the sidebar's display tree.
 */
class SidebarTreeNode extends React.Component {

	static propTypes = {
		path: PropTypes.object,
		itemType: PropTypes.string.isRequired,
		selections: PropTypes.shape({
			nodeDatas: PropTypes.array,
			showSelectionOnly: PropTypes.bool,
			isSearching: PropTypes.bool,
		}),
	};

	/**
	 * Checks against selection props to see if the node is selected
	 * @return {Boolean} Whether the node is selected
	 */
	isSelected() {
		const matchingSelections = this.props.selections.nodeDatas.filter(
			nodeData =>
				nodeData.path.equals(this.props.path) &&
				nodeData.itemType === this.props.itemType
		);
		return matchingSelections.length >= 1;
	}

	/**
	 * Checks against selection props to see if any children are selected
	 * @return {Boolean} Whether the node has selected children
	 */
	containsSelectedNode() {
		const selectedChildren = this.props.selections.nodeDatas.filter(
			nodeData =>
				nodeData.path.isDescendantOf(this.props.path)
		);

		return selectedChildren.length >= 1;
	}

	/**
	 * Checks against selection props to see if any parents are selected
	 * @return {Boolean} Whether the node has selected children
	 */
	isContainedBySelectedNode() {
		const selectedChildren = this.props.selections.nodeDatas.filter(
			nodeData =>
				this.props.path.isDescendantOf(nodeData.path)
		);

		return selectedChildren.length >= 1;
	}

	/**
	 * Checks against selection props to see if it the node should be hidden
	 * @return {Boolean} Whether the node should be hidden
	 */
	isHidden() {
		return this.props.selections.isSearching && this.props.selections.showSelectionOnly &&
			!this.isSelected() && !this.containsSelectedNode() && !this.isContainedBySelectedNode();
	}
}

export default SidebarTreeNode;
