import AppStore from '../../lib/stores/AppStore';
import Log from 'js/lib/classes/Log';
import Tools from './Tools';

/**
 * DataProvider class.
 */
export default class DataProvider {
	/**
	 * [constructor description]
	 * @param  {Function} callback [description]
	 */
	constructor(callback: ?Function) {
		if (!callback) {
			throw new Error('DataProviders require a callback.');
		}

		// pull these in at construction so that tests can mock them beforeAll
		// we merge so that each DataProvider gets its own copy of tools
		// functions should be copied by reference, but target will be unique per DP
		this.tools = Object.assign({ target: null }, Tools, this.tools);

		// call these functions to preserve old-style DataProvider functionality
		this.tools.auth = Tools.auth();
		this.tools.entitlements = Tools.entitlements();
		this.tools.moment = Tools.moment();
		this.tools.cookies = Tools.cookies();

		// assign parameters
		Object.assign(this, { callback });
	}

	static push = false;

	// this is here so tests can override
	static tools = {};

	/**
	 * Called when data is requested.
	 *
	 * @param {Function} resolve called when request returns.
	 * @param {Function} reject called when request errors.
	 * @param {Object} target reference to the calling target
	 * @param {Object?} targetProps the calling target's props or nextProps
	 * @param {bool} bypassCache  Whether or not to bypass the dataProvider's cache
	 * @return {mixed} a Promise, or false
	 */
	getData(resolve, reject, target, targetProps, bypassCache) {
		this.getDataResolveCallback = resolve;
		this.getDataRejectCallback = reject;

		// merge this again so that target does not overlap between getData calls
		const tools = Object.assign({}, this.tools);

		// assign this separately so it's still the same reference, though
		tools.target = target;
		// tools.auth = DataProvider.tools.auth;

		// Enables overriding of the cache by providing a new "copy" of the ajax
		// function with a bypass property on it. This is necessary because ajax()
		// gets called directly within the implemented DataProvider and we need
		// to get the bypass prop from here to inside the actual ajax call.
		tools.ajax = tools.ajax.bind(tools);
		tools.ajax.bypassCache = bypassCache;

		// If targetProps are passed, they might be nextProps instead of props,
		// (if getData was called in a componentWillReceiveProps), so we use them instead
		const mostCurrentProps = targetProps || (target && target.props);

		if (mostCurrentProps) {
			tools.filterRange = mostCurrentProps.filterRange;
			tools.selectedDeviceIds = mostCurrentProps.selectedDeviceIds;
			tools.searchBoxValue = mostCurrentProps.searchBoxValue;
			tools.panelGroupData = mostCurrentProps.panelGroupData;
		}

		tools.urls = AppStore.getData().urls;

		try {
			let providerPromise;

			// HACK HACK HACK
			// resolve moment promise (loading the moment.js chunk)
			// only if the string 'moment' is found within the plugin's code
			// and tools.moment is still a promise
			// TODO: make plugins use real imports/dependencies
			if (this.callback.toString().indexOf('moment') !== -1 && !!tools.moment.then) {
				providerPromise = tools.moment.then(module => {
					return this.callback({ ...tools, moment: module.default }, resolve, reject);
				});
			} else {
				providerPromise = this.callback(tools, resolve, reject);
			}

			if (providerPromise) {
				return providerPromise.catch(e => {
					if (e.type === 'NotSignedInError') {
						// don't log anything, we already know
						// Log.warn('Not signed in.');
					} else {
						Log.error(e, 'Got error from DataProvider');
					}

					reject(e);
				});
			}
		} catch (e) {
			reject(e);
		}

		return false;
	}
}
