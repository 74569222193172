import PropTypes from 'prop-types';
import React from 'react';
import 'scss/components/loading';

/**
 * Loading indicator
 *
 * NOTE: this currently assumes block-level elements.
 * @return {jsx} the contained children, or a loading indicator
 */
class Loading extends React.Component {

	static propTypes = {
		busy: PropTypes.bool,
		children: PropTypes.node,
	};

	static defaultProps = {
		busy: false,
		children: [],
	};

	/**
	 * Renders a loading panel or the passed children
	 * @return {jsx}  The rendered jsx
	 */
	render() {
		return (
			<div className="hui-loading-context">
				{this.props.busy ? <div className="hui-loading-view">Loading...</div> : this.props.children}
			</div>
		);
	}
}

export default Loading;
