import React from 'react';
import SidebarGroupNode from '../components/sidebar/treenodes/SidebarGroupNode';
import SidebarButtonNode from '../components/sidebar/treenodes/SidebarButtonNode';
import SidebarDeviceNode from '../components/sidebar/treenodes/SidebarDeviceNode';
import SidebarUnknownNode from '../components/sidebar/treenodes/SidebarUnknownNode';
import constants from '../constants';

const Constants = constants.Sidebar;

/**
 * Renders sidebar tree child nodes, selecting the appropriate components for each child
 * @param {Object[]} children      An array of child props objects
 * @param {int}      currentDepth  The current depth of the tree
 * @return {jsx} the jsx component representation of the given children
 */
function renderChildNodes(children, currentDepth = 0) {
	if (!children || !children.length) {
		return null;
	}

	const nextDepth = currentDepth + 1;

	const childItemRendering = children.map((child, idx) => {

		switch (child.itemType) {
			case Constants.productChildTypes.GROUP:
				return <SidebarGroupNode {...child} key={idx} depth={nextDepth} />;
			case Constants.productChildTypes.BUTTON:
				return <SidebarButtonNode {...child} key={idx} depth={nextDepth} />;
			case Constants.productChildTypes.DEVICE:
				return <SidebarDeviceNode {...child} key={idx} depth={nextDepth} />;
			default:
				return <SidebarUnknownNode {...child} key={idx} depth={nextDepth} />;
		}
	});

	return <div className="hui-sidebar-treenode">{childItemRendering}</div>;
}

export default {
	renderChildNodes,
};
