/**
 This is a set of high-level helper functions intended to help with anything related to navigation
 **/
import Actions from './Actions';

class NavigationHelper {

	/**
	 * Marks a tab as hidden
	 * @param {string|number} tabId The id that was passed into the tab on instantiation
	 */
	static hideTab(tabId) {
		Actions.alterNavbarTab(tabId, {
			isHidden: true,
		});
	}

	/**
	 * Marks a set of tabs as hidden
	 * @param {Array} tabIds A set of tab ids
	 */
	static hideTabs(tabIds: Array) {
		tabIds.forEach((tabId) => {
			NavigationHelper.hideTab(tabId);
		});
	}

	/**
	 * Marks a tab as visible
	 * @param {string|number} tabId The id that was passed into the tab on instantiation
	 */
	static showTab(tabId) {
		Actions.alterNavbarTab(tabId, {
			isHidden: false,
		});
	}

	/**
	 * Marks a set of tabs as visible
	 * @param {Array} tabIds A set of tab ids
	 */
	static showTabs(tabIds: Array) {
		tabIds.forEach((tabId) => {
			NavigationHelper.showTab(tabId);
		});
	}
}

export default NavigationHelper;
