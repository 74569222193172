import CardWrapper from '../CardWrapper';
import BadgeCard from './BadgeCard';

/**
 * Class representing a dashboard card.
 */
export default class BadgeCardWrapper extends CardWrapper {

	/**
	 * Creates a BadgeCard
	 * @param {Object} opts  see CardWrapper opts
	 */
	constructor(opts) {
		super(opts, BadgeCard);
	}
}
