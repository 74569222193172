
/**
 * Returns the first provided value that isn't undefined.
 * @param   {*} args  Any number of values of any kind
 * @returns {*}       The first argument that isn't undefined
 */
export default function getFirstDefined(...args) {
	for (let i = 0; i < args.length; i++) {
		if (args[i] !== undefined) {
			return args[i];
		}
	}

	return undefined;
}
