import PropTypes from 'prop-types';
import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import 'scss/components/paginationDropdown';

/**
 * Class representing the react class for card name-based pagination.
 */
export default class PaginationDropdown extends React.Component {
	/**
	 * Set up validation rules.
	 */
	static propTypes = {
		id: PropTypes.string.isRequired,
		prefix: PropTypes.string,
		optionValues: PropTypes.array,
		activeIndex: PropTypes.number,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		prefix: '',
		optionValues: [],
		activeIndex: 0,
		onChange: () => {},
	};

	/**
	 * Change event handler for rendered <select>
	 * @param {any} evtKey  The index of the selected MenuItem
	 */
	handleDropdownChange(evtKey) {
		this.props.onChange(+evtKey);
	}

	/**
	 * Render the core PaginationDropdown React instance.
	 * @return {jsx} The PaginationDropdown React instance
	 */
	render() {
		if (this.props.optionValues.length <= 1) {
			return null;
		}

		const options = this.props.optionValues.map((optionValue, idx) => {
			return <MenuItem key={idx} eventKey={idx}>{optionValue}</MenuItem>;
		});

		let buttonTitle = this.props.optionValues[this.props.activeIndex];
		if (this.props.prefix) {
			buttonTitle = this.props.prefix + ': ' + buttonTitle;
		}

		return (
			<div className="hui-pagination-dropdown hui-bootstrap-container" title={buttonTitle}>
				<DropdownButton
					bsStyle="link"
					id={this.props.id}
					title={buttonTitle}
					onSelect={this.handleDropdownChange.bind(this)}
					block
				>
					{options}
				</DropdownButton>
			</div>
		);
	}

}
