import PropTypes from 'prop-types';
import React from 'react';
import sidebarTreeUtils from '../../utils/sidebarTreeUtils';
import 'scss/components/sidebar';

const noop = () => {};

/**
 * Sidebar React component for a product.
 */
class SidebarProduct extends React.Component {

	static propTypes = {
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		icon: PropTypes.string,
		href: PropTypes.string,
		target: PropTypes.string,
		navCallout: PropTypes.string,
		childItems: PropTypes.arrayOf(PropTypes.object),
		isDisabled: PropTypes.bool,
		selections: PropTypes.shape({
			productId: PropTypes.string,
			nodeDatas: PropTypes.array,
			selectProduct: PropTypes.func,
		}),
		isBusy: PropTypes.bool,
		isErrored: PropTypes.bool,
		forceExpand: PropTypes.bool,
		count: PropTypes.number,
	};

	static defaultProps = {
		childItems: [],
		selectProduct: noop,
	};

	/**
	 * The click event handler for clicking on a product
	 * @param {object} evt The event that triggered the click
	 */
	onProductClick(evt) {
		this.props.selections.selectProduct(this.props.id, evt);
	}

	/**
	 * Returns whether this product is selected
	 * @returns {Boolean}  Whether this product is selected
	 */
	isSelected() {
		return this.props.id === this.props.selections.productId;
	}

	/**
	 * Generates all the modifier classes for the root element
	 * @returns {string} The modifier classes for the root element
	 */
	getRootModifierClasses() {
		let selectedClass = '';
		if (this.isSelected()) {
			if (this.props.selections.nodeDatas.length) {
				selectedClass = 'hui-state-children-selected';
			} else {
				selectedClass = 'hui-state-active';
			}
		}

		const disabledClass = this.props.isDisabled ? 'hui-state-disabled' : '';
		const busyClass = this.props.isBusy ? 'hui-state-busy' : '';
		const errorClass = this.props.isErrored ? 'hui-state-error' : '';

		return [disabledClass, busyClass, errorClass, selectedClass].join(' ');
	}

	/**
	 * Renders the core React component.
	 * @return {jsx} the jsx view
	 */
	render() {

		const childRendering = this.isSelected() || this.props.forceExpand ? (
			<div className="hui-sidebar-product-content">
				{ sidebarTreeUtils.renderChildNodes(this.props.childItems) }
			</div>
		) : null;

		const iconClass = this.props.icon ? ('hui-sidebar-product-icon-' + this.props.icon) : '';
		const href = this.props.href ? { href: this.props.href } : null;

		return (
			<div className="hui-sidebar-product-wrapper">
				<a className={`hui-sidebar-product ${this.getRootModifierClasses()}`}
					onClick={this.onProductClick.bind(this)}
					{
						// Handle products that should open their links in a new window
						...(this.props.target ? { target: this.props.target, onClick: noop } : {})
					}
					{...href}
				>
					<span className={iconClass} />
					<span className="hui-sidebar-product-title">{this.props.title}</span>
					<span className="hui-sidebar-product-select-icon" />
					{this.props.count ?
						<span className="hui-sidebar-product-count">{this.props.count}</span>
					: []}
					{this.props.navCallout ? <span className="hui-sidebar-product-callout">{this.props.navCallout}</span> : []}
				</a>
				{childRendering}
			</div>
		);
	}
}

export default SidebarProduct;
