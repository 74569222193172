import authPromise from 'js/lib/classes/tools/authPromise';
import entitlementsPromise from 'js/lib/classes/tools/entitlementsPromise';
import { makeCachedAjaxCall } from 'js/lib/utils/makeAjaxCall';
import humanizeDuration from 'js/lib/classes/tools/humanizeDuration';
import Log from 'js/lib/classes/Log';
import { sizeFromBytes, toTitleCase } from './Format';
import { unsafe } from 'reactable/lib/reactable/unsafe';
import parseXML, { parseXMLViaDOMParser } from 'js/lib/classes/tools/parseXML';
import iconHtml from 'js/lib/classes/tools/iconHtml';
import cookies from 'js/lib/classes/tools/cookies';

// function that returns the moment Promise
const momentPromise = () => import('js/lib/classes/tools/moment');

export default {
	auth: authPromise,
	entitlements: entitlementsPromise,
	ajax: makeCachedAjaxCall,
	log: Log,
	tablePreserveHtml: unsafe,
	moment: momentPromise,
	humanizeDuration,
	toTitleCase,
	sizeFromBytes,
	parseXML,
	parseXMLViaDOMParser,
	iconHtml,
	cookies,
};

export {
	authPromise as auth,
	entitlementsPromise as entitlements,
	makeCachedAjaxCall as ajax,
	Log as log,
	unsafe as tablePreserveHtml,
	momentPromise as moment,
	humanizeDuration,
	toTitleCase,
	sizeFromBytes,
	parseXML,
	parseXMLViaDOMParser,
	iconHtml,
	cookies,
};
