import HuiPath from '../classes/HuiPath';

/**
 * Defines TabData as an object
 * @param {object} args The arguments to pass to this object
 * @param {boolean} [args.isSelected=false] Whether the tab is currently selected
 * @param {string} [args.id=''] The tab ID
 * @param {string} [args.label=''] The tab label
 * @param {string} [args.href=''] The tab href
 * @param {string} [args.icon=''] The tab icon
 * @param {HuiPath} [args.path] The tab path object
 * @constructor
 */
export default function TabData(args:Object) {
	// This is not used within HUI but can be passed into
	// the hierarchical tab data and will be processed in NavbarStore on instantiation
	// this.isSelected = args.isSelected || false;
	this.id = args.id || '';
	this.label = args.label || '';
	this.href = args.href || '';
	this.icon = args.icon || '';
	this.path = args.path || new HuiPath([]);
}
