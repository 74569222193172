import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

export default class BaseWrapper {

	/**
	 * Instantiate a wrapper
	 * @param {object} opts Options
	 * @param {jsx} ReactClass A react class
	 */
	constructor(opts, ReactClass) {

		if (!ReactClass) {
			throw new Error(`Tried to instantiate wrapper ${this.constructor.name}
				with no React Class. All classes extending BaseWrapper must provide a React Class.`);
		}

		if (!opts) {
			throw new Error(`Tried to instantiate wrapper ${this.constructor.name}
				with no options. All classes extending BaseWrapper must provide an options object.`);
		}

		if (!opts.id) {
			throw new Error(`Tried to instantiate wrapper ${this.constructor.name}
				without required option 'id'.`);
		}

		let propKey;
		const finalProps = {};

		// Each react component is expected to describe itself using its PropTypes.
		// Some or all may exist at this point.
		// The propKey will be the name of the property,
		// and the value will be a validation function
		for (propKey in ReactClass.propTypes) {
			if (ReactClass.propTypes.hasOwnProperty(propKey)) {
				finalProps[propKey] = this._defaultProps(opts, ReactClass, propKey);
			}
		}

		this.container = opts.container;
		this.id = opts.id;

		// Set the props to their expected location.
		// These are the props that should be passed through to React
		this.reactProps = finalProps;
	}

	/**
	 * Returns the value for the propKey or the default from the ReactClass if none was provided.
	 * @param   {Object} opts        Options for the wrapped component
	 * @param   {Object} ReactClass  The React class that is being wrapped
	 * @param   {string} propKey     The property key for the property to be validated
	 * @returns {*}                  The property value for the provided propKey, or the default value
	 */
	_defaultProps(opts, ReactClass, propKey) {
		// If nothing was set for this property
		if (opts[propKey] === undefined && ReactClass.defaultProps && ReactClass.defaultProps[propKey]) {
			// There is a default value for this property and we didn't provide one,
			// so add the default to the passed in props!
			return ReactClass.defaultProps[propKey];
		}
		return opts[propKey];
	}

	/**
	 * Renders either the objects parent's jsx or the object's jsx into the DOM.
	 * @return {jsx} the jsx view
	 */
	render() {
		if (this.parent) {
			this.parent.render();
		} else if (this.container) {
			const container = (typeof this.container !== 'string') ? this.container :
				global.document.querySelectorAll(this.container);

			let containerEl = null;
			if (typeof container.length === 'number') {
				if (container.length !== 1) {
					throw new Error(this.constructor.name + ' cannot render without a unique container element');
				}
				containerEl = container[0];
			} else {
				containerEl = container;
			}
			ReactDOM.render(this.instantiateReactComponent(), containerEl);
		} else if (this.container === false) {
			return ReactDOMServer.renderToString(this.instantiateReactComponent());
		} else {
			throw new Error(this.constructor.name + ' cannot render with no container');
		}
	}

	standalone() {
		return this.instantiateReactComponent();
	}
}
