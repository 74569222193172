import Reflux from 'reflux';
import Actions from '../classes/Actions';
import Log from '../classes/Log';

// Brings in getState() and this._data
import StoreMixins from '../stores/StoreMixins';

const PanelGroupDataStore = Reflux.createStore({
	listenables: Actions,
	mixins: [StoreMixins],
	init() {
		// this.listenTo(CardStore, this.onCardStoreChange);
	},
	getData() {
		const panelGroupDatas = this.getState();

		return panelGroupDatas;
	},
	getPanelGroupData(panelGroupId) {
		const panelGroupData = this.getState()[panelGroupId];

		return panelGroupData;
	},
	setPanelGroupData(panelGroupId, panelGroupData) {
		const newState = this.getState();

		Log.info('Setting data for panelGroupId ' + panelGroupId, panelGroupData);

		newState[panelGroupId] = panelGroupData;

		this._data = newState;
		this.triggerWithFullData();
	},
});

export default PanelGroupDataStore;
