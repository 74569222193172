import DataProvider from './DataProvider';
import Log from './Log';

/* eslint-disable */
const SOCKET_CONNECTING = 0; // The connection is not yet open.
const SOCKET_OPEN = 1; // The connection is open and ready to communicate.
const SOCKET_CLOSING = 2; // The connection is in the process of closing.
const SOCKET_CLOSED = 3; // The connection is closed or couldn't be opened.
/* eslint-enable */

/**
 * SocketProvider recieves pushed data from a socket and calls the resolve for every chunk
 */
export default class SocketProvider extends DataProvider {
	socket;

	getDataResolveCallback = () => {};
	getDataRejectCallback = () => {};

	/* This governs whether getData is called multiple times or just initially. */
	static push = true;

	/**
	 * Creates a new SocketProvider.
	 *
	 * @param {string} url of the websocket.
	 * @param {Function} callback called for each chunk of data.
	 * @constructor
	 */
	constructor(url: ?string, callback: ?Function) {
		// check required params before calling super
		if (!url) {
			throw new Error('SocketProviders require an url string.');
		}

		super(callback);

		// assign parameters (callback is handled by superclass)
		Object.assign(this, { url });

		this.reconnectInterval = setInterval(this.reconnectIfDisconnected.bind(this), 5000);
	}

	/**
	 * Connects to the socket.
	 */
	setUpSocket() {
		this.socket = new global.WebSocket(this.url);

		this.socket.addEventListener('open', () => {
			Log.info('Connection established at:', this.url);

			this.socket.addEventListener('message', response => {
				// Log.info('Received: %s', response.data);

				// we check acceptPushedUpdates because we
				// might get socket data before getData was called
				if (this.tools.target.acceptPushedUpdates) {
					this.callback.call(this, response.data, this.tools, this.getDataResolveCallback);
				}
			});

			this.socket.addEventListener('error', (/* response */) => {
				// Log.info('Received: %s', response.data);

				// we check acceptPushedUpdates because we
				// might get socket data before getData was called
				if (this.tools.target.acceptPushedUpdates) {
					this.getDataRejectCallback();
				}
			});

			this.socket.addEventListener('close', () => {
				Log.info('Connection closed.');
			});
		});
	}

	/**
	 * Reconnects if the socket is not connected. Runs every 5 secs by default.
	 */
	reconnectIfDisconnected() {
		if (this.socket && this.socket.readyState === SOCKET_CLOSED) {
			Log.info('Reconnecting...');
			this.setUpSocket();
		}
	}

	/**
	 * Called when data is requested.
	 *
	 * This function is only called once in a SocketProvider to set the resolve. callback
	 * Further updates are the socket's responsibility to push.
	 *
	 * @param {Function} resolve called when there is new data.
	 * @param {Function} reject called when there is an error.
	 * @param {Object} target instance for which data is being fetched.
	 */
	getData(resolve, reject, target) {
		this.tools.target = target;
		this.getDataResolveCallback = resolve;
		this.getDataRejectCallback = reject;

		if (!this.socket) {
			this.setUpSocket();
		}
	}
}
