// main elements
import Header from 'js/lib/components/header/HeaderWrapper';
import Sidebar from 'js/lib/components/sidebar/SidebarWrapper';
import Footer from 'js/lib/components/footer/FooterWrapper';
import Navbar from 'js/lib/components/navbar/NavbarWrapper';
import Wrapper from 'js/lib/components/wrapper/WrapperWrapper';

// Dashboard elements
import Dashboard from 'js/lib/components/dashboard/DashboardWrapper';
import PanelGroup from 'js/lib/components/dashboard/PanelGroupWrapper';
import Panel from 'js/lib/components/dashboard/PanelWrapper';
import Card from 'js/lib/components/dashboard/CardWrapper';

import HtmlCard from 'js/lib/components/dashboard/cards/HtmlCardWrapper';
import ReactCard from 'js/lib/components/dashboard/cards/ReactCardWrapper';
import GraphCard from 'js/lib/components/dashboard/cards/GraphCardWrapper';
import BadgeCard from 'js/lib/components/dashboard/cards/BadgeCardWrapper';
import TableCard from 'js/lib/components/dashboard/cards/TableCardWrapper';
import MapCard from 'js/lib/components/dashboard/cards/MapCardWrapper';

import DataProvider from 'js/lib/classes/DataProvider';
import StaticProvider from 'js/lib/classes/StaticProvider';
import AjaxProvider from 'js/lib/classes/AjaxProvider';
import SocketProvider from 'js/lib/classes/SocketProvider';
import MergedProvider from 'js/lib/classes/MergedProvider';
import NavigationHelper from 'js/lib/classes/NavigationHelper';

import Dialog from 'js/lib/components/dialog/DialogWrapper';

import Icon from 'js/lib/components/common/Icon';
import Tooltip from 'js/lib/components/common/Tooltip';
import TooltipTrigger from 'js/lib/components/common/TooltipTrigger';

import Constants from 'js/lib/constants';
import Log from 'js/lib/classes/Log';
import HuiApp from 'js/lib/classes/App';
import { PublicActions } from 'js/lib/classes/Actions';
import Events from 'js/lib/classes/Events';

import * as Messages from 'js/lib/lids';
import i18n, { translateLid } from 'js/lib/i18n';

import Tools from 'js/lib/classes/Tools';

// React components to expose
import * as ReactComponents from 'js/all-react';

// other libraries to expose
import React from 'react';
import ReactDOM from 'react-dom';
import Reflux from 'reflux';
import axios from 'axios';

// set default log level if it's not already set
global.HUI_LOG_LEVEL = (global.HUI_LOG_LEVEL !== undefined) ? global.HUI_LOG_LEVEL : Log.LOG_LEVEL_INFO;

const Components = {
	Header,
	Sidebar,
	Footer,
	Navbar,
	Wrapper,

	Dashboard,
	PanelGroup,
	Panel,
	Card,
	Cards: {
		HtmlCard,
		ReactCard,
		GraphCard,
		BadgeCard,
		TableCard,
		MapCard,
	},

	Dialog,

	Icon,
	Tooltip,
	TooltipTrigger,
};

const Providers = {
	DataProvider,
	StaticProvider,
	AjaxProvider,
	SocketProvider,
	MergedProvider,
};

global.HUI = global.HUI || {};
global.HUI.Components = Components;
global.HUI.Providers = Providers;
global.HUI.Constants = Constants;
global.HUI.Actions = PublicActions;
global.HUI.Events = Events;
global.HUI.Messages = Messages;
global.HUI.translateLid = translateLid;
global.HUI.Nav = NavigationHelper;
global.HUI.React = ReactComponents;
global.HUI.Tools = Tools;

// expose shared libraries
global.HUI.Exports = { Log, React, ReactDOM, Reflux, axios, i18n };

// global.HUI needs to be available by now
HuiApp.init();

export default global.HUI;
